import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ComparisonIconWrapper,
  ItemContainer,
  KpiItemContainer,
  KpiWidgeContainer,
  PageWrapper,
  WidgeContainer,
  WidgetWrapper,
} from "./styles";
import {
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getPageSettings,
} from "../../../../store/selectors/projects";
import { requestUpdateWidget } from "../../../../store/slices/widgets";
import { useDebounceCallback } from "usehooks-ts";
import {
  requestPageKpis,
  requestPageWidgets,
} from "../../../../store/slices/projectPages";
import { UpdateSingleWidgetDTO, WidgetItem } from "../../../../models/Widgets";
import SectionItem from "../ChartContainer/components/SectionItem/SectionItem";
import { requestComparisonWidgets, requestPublicComparisonWidgets } from "../../../../store/slices/comparison";
import {
  getComparisonKpis,
  getComparisonLoading,
  getComparisonWidgets,
  getSelectedMeasure,
} from "../../../../store/selectors/comparison";
import { ReactComponent as ComparisonIcon } from "../../../../assets/comparison.svg";
import { Loader } from "../../../Loader";
import { MapControlsProvider } from "../ChartContainer/components/MapControls/MapControls";
import { KPISettingsWrapperStory } from "../../../KPISettingsWrapperStory";
import Comments from "../../../Comments/Comments";
import { setSelectWidgetId, toggleCommentsCursor } from "../../../../store/slices/commnets";
import { getCustomeCommentsCursor } from "../../../../store/selectors/comments";
import { getIsPublicMode } from "../../../../store/selectors/main";

export const ComparisonContent: FC = () => {
  const dispatch = useDispatch();
  const pageWidgets = useSelector(getCurrentPageWidgets);
  const currentPageSettings = useSelector(getPageSettings);
  const selectedMeasure = useSelector(getSelectedMeasure);
  const comparisonWidgets = useSelector(getComparisonWidgets);
  const comparisonKpis = useSelector(getComparisonKpis);
  const aiKpi = useSelector(getCurrentPageKpi);
  const isLoading = useSelector(getComparisonLoading);
  const isCustomeCursor = useSelector(getCustomeCommentsCursor);
  const isPublicMode = useSelector(getIsPublicMode);
  const [mergedItems, setMergedItems] = useState<
    [WidgetItem, WidgetItem | null][]
  >([]);

  useEffect(() => {
    if (currentPageSettings.id) {
      dispatch(
        requestPageKpis({
          pageId: currentPageSettings.id,
          includeData: true,
        })
      );
    }
  }, [currentPageSettings.id, dispatch]);

  useEffect(() => {
    if (
      currentPageSettings.id &&
      (selectedMeasure || currentPageSettings.comparisonMeasure)
    ) {
      const queryParams = new URLSearchParams(window.location.search);
      const query = queryParams.get("query");
      dispatch(
        (isPublicMode ? requestPublicComparisonWidgets : requestComparisonWidgets)({
          pageId: currentPageSettings.id,
          measure:
            selectedMeasure ||
            currentPageSettings.comparisonMeasure?.split("=")[0],
          ...(query && { query }),
        })
      );
    }
  }, [currentPageSettings.comparisonMeasure, selectedMeasure, currentPageSettings.id, dispatch, isPublicMode]);

  const handleSave = (widget: UpdateSingleWidgetDTO) => {
    dispatch(
      requestUpdateWidget({
        ...widget,
        blockId: String(widget?.blockId),
        callbacks: {
          onSuccess: () => {
            if (currentPageSettings.id) {
              dispatch(
                requestPageWidgets({
                  pageId: currentPageSettings.id,
                  includeData: true,
                })
              );
            }
          },
        },
      })
    );
  };

  const debouncedUpdateWidget = useDebounceCallback(handleSave, 500);

  useEffect(() => {
    if (pageWidgets.items && comparisonWidgets.items) {
      const processWidgets = (
        widgets: WidgetItem[],
        uniqueId: string
      ): WidgetItem[] =>
        widgets
          .map((el) => ({
            ...el,
            blockId: el.blockId || "1",
            sectionType: el.chartType === "mapChart" ? "map" : "widget",
            id: el.id,
            uniqueId,
          }))
          .sort((a, b) => Number(a.blockId) - Number(b.blockId));

      const originalWidgets = processWidgets(pageWidgets.items, "pageWidgets");
      const comparisonWidgetsProcessed = processWidgets(
        comparisonWidgets.items,
        "comparisonWidgets"
      );

      const merged: [WidgetItem, WidgetItem | null][] = originalWidgets.map(
        (originalWidget) => [
          originalWidget,
          comparisonWidgetsProcessed.find(
            (widget) => widget.id === originalWidget.id
          ) || null,
        ]
      );

      setMergedItems(merged);
    }
  }, [pageWidgets.items, comparisonWidgets.items, isLoading]);

  const handleSlelectWidget = (id: string) => {
    if (isCustomeCursor) {
      dispatch(setSelectWidgetId(id));
      dispatch(toggleCommentsCursor(false));
    }
  };

  return (
    <PageWrapper id="content">
      <MapControlsProvider>
        {!!aiKpi.items?.length && (
          <KpiItemContainer onClick={() => handleSlelectWidget(aiKpi.items?.[0]?.id)}>
            {(isLoading || !comparisonKpis.items?.length) && <Loader />}
            <KpiWidgeContainer>
              <KPISettingsWrapperStory
                kpis={aiKpi.items}
                isComparison
                recommended={false}
                kpiId="left"
              />
            </KpiWidgeContainer>
            <ComparisonIconWrapper>
              <ComparisonIcon />
            </ComparisonIconWrapper>
            <KpiWidgeContainer>
              {!!comparisonKpis.items?.length && (
                <KPISettingsWrapperStory
                  kpis={comparisonKpis.items}
                  isComparison
                  recommended
                />
              )}
            </KpiWidgeContainer>
            <Comments isStorytelling id={aiKpi.items?.[0]?.id} />
          </KpiItemContainer>
        )}
        {mergedItems.map(([originalWidget, comparisonWidget]) => originalWidget.data && comparisonWidget?.data && (
          <ItemContainer onClick={() => handleSlelectWidget(originalWidget.id)} key={originalWidget.id}>
            {isLoading && <Loader />}
            <WidgetWrapper>
              <WidgeContainer>
                <SectionItem
                  key={originalWidget.id}
                  setContentItem={debouncedUpdateWidget}
                  item={originalWidget}
                  showLegend={false}
                />
              </WidgeContainer>
              <ComparisonIconWrapper>
                <ComparisonIcon />
              </ComparisonIconWrapper>
              <WidgeContainer>
                {comparisonWidget && (
                  <SectionItem
                    key={comparisonWidget.id}
                    setContentItem={debouncedUpdateWidget}
                    item={comparisonWidget}
                    originalItem={originalWidget}
                    showLegend
                    isRight
                  />
                )}
              </WidgeContainer>
            </WidgetWrapper>
            <Comments isComparison id={originalWidget?.id} />
          </ItemContainer>
        ))}
      </MapControlsProvider>
    </PageWrapper>
  );
};
