export const AVAILABLE_WIDGETS: { [key: string]: string[] } = {
  lollipopChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
    "areaChart",
  ],
  lineChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "areaChart",
    "radar",
    "scatterplotChart",
  ],
  sparkLineChart: ["sparkAreaChart"],
  sparkAreaChart: ["sparkLineChart"],
  areaChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
  ],
  barChart: [
    "barChart_vertical",
    "barChart_horizontal",
    "lollipopChart_vertical",
    "lollipopChart_horizontal",
    "lineChart",
    "areaChart",
    "polarAreaChart",
    "radar",
  ],
  sankey: ["matrix"],
  matrix: ["sankey", "punchcardChart", "scatterplotChart"],
  mapChart: ["map", "sankey_map", "matrix_map"],
  bubbleChart: ["scatterplotChart"],
  scatterplot: ["bubbleChart", "lineChart", "matrix"],
  punchcardChart: ["matrix"],
  polarAreaChart: ["barChart_vertical", "barChart_horizontal", "radar"],
  radar: [
    "polarAreaChart",
    "lineChart",
    "barChart_vertical",
    "barChart_horizontal",
  ],
  radialBarChart: [],
};
