import { SortedColumn } from "../../../pages/UserManagment/pages/Users/data/mock";

interface IColumnClick {
  event: React.MouseEvent;
  key: SortedColumn;
}

export const handleColumnClick = ({ event, key } : IColumnClick) => {
  const rect = event.currentTarget.getBoundingClientRect();
  const top = rect.bottom;
  const left = rect.left;
  return {top, left, key};
};
