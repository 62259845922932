import React, { useState } from "react";

import { InputField, SearchBarContainer, SearchIcon } from "./styles";

interface SearchInterface {
  width?: "small" | "medium" | "large" | "xs";
  onChange?: (value: string) => void;
  placeholder?: string;
  border?: boolean;
}

export const Search = ({
  width,
  onChange,
  placeholder,
  border,
}: SearchInterface) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <SearchBarContainer
      $border={border}
      $width={width}
      $isValue={searchValue?.length > 0}
    >
      <SearchIcon />
      <InputField
        autoComplete="off"
        type="search"
        name="search"
        value={searchValue}
        placeholder={placeholder ? placeholder : "Search..."}
        onChange={handleChange}
      />
    </SearchBarContainer>
  );
};
