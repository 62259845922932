import { XLg } from "react-bootstrap-icons";
import { styled } from "styled-components";

export const SearchInput = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  overflow: hidden;
  color: ${({theme}) => theme.neutralText};
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  &:disabled {
    background-color: transparent;
    color: ${({theme}) => theme.neutralTextWeak};
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px; 
`

export const Separator = styled.div`
  width: 1px;
  height: 70%;
  background-color: ${({theme}) => theme.neutralBorder};
`

export const Close = styled(XLg)``

export const FilterWrapper = styled.div<{$active?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: ${({theme}) => theme.neutralTextWeak};

  &:hover {
    color: ${({theme}) => theme.neutralText};
  }

  ${({$active, theme}) => $active && `
  color: ${theme.neutralText};
  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    width: 6px;
    height: 6px;
    background-color: ${theme.primaryBackgroundStrong};
    border-radius: 6px;
  }
  `}
  
`

export const Container = styled.div<{$disabled?: boolean}>`
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  position: relative;
  &:hover {
    ${Separator} {
      height: 100%;
    }
  }


  ${({$disabled, theme}) => $disabled && `
    background: ${theme.neutralBackground}
  `}
`

export const DropDownMenu = styled.div`
  position: absolute;
  background-color: white;
  top: 40px;
  right: 0;
  width: max-content;
  height: max-content;
  z-index: 1;
  border: 1px solid ${({theme}) => theme.neutralBorder};
  padding: 8px;
`

export const SearchWrapper = styled.div`
  padding: 4px 0 10.5px;
  border-bottom: 1px solid ${({theme}) => theme.neutralBorder};
  margin-bottom: 6.5px;
`

export const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: thin;
  max-height: 400px;
  gap: 1px;
`

export const ValueItem = styled.div<{$selected?: boolean}>`
  padding: 8px 4px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  border-radius: 4px;
  gap: 8px;
  color: ${({theme}) => theme.neutralText};
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  ${({$selected, theme}) => $selected && `
    font-weight: 500;
    background-color: ${theme.neutralBackground};
  `}
  &:first-child{
    margin-bottom: 4px;
  }
`