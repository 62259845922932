import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Formik } from "formik";

import {
  AuthHeading,
  AuthMethod,
  AuthPageWrapper,
  AuthWrapper,
  Background,
  Container,
  Credentials,
  LogoWrapper,
  RegisterDescription,
  SignInDescription,
  SecondaryButtonWrapper,
  SignUpButtonWrapper,
  TermsOfService,
  TermsOfServiceLink,
  TermsErrorMsg,
  Logo,
  TermsText,
  TermsWrapper,
} from "../styles";

import AddMembers from "./AddMembers";
import { FormGroup } from "../FormGroup";
// import { Toggle } from "../../../components/Toggle";
import { Button } from "../../../components/Button";
// import { SignUpValidationSchema } from "../../../constants";
import { requestRegistration } from "../../../store/slices/auth";
import { CheckLg } from "react-bootstrap-icons";
import { CheckBox } from "../../../components/Inputs/CustomCheckbox/CheckBox";
import { ShareModalCopyLinkButton, Slash, Arrow } from "../../../components/Modals/Share/styles";

const formInitialValues = {
  teamName: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  terms: false,
};

export const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [selectedOption, setSelectedOption] = useState("regular");
  const [signedUp] = useState(false);

  // const handleOptionChange = (option: string) => {
  //   setSelectedOption(option);
  // };

  return (
    <Container>
      <Background />
      <AuthPageWrapper>
        <LogoWrapper>
          <Logo onClick={() => navigate("/")} />
        </LogoWrapper>
        <AuthWrapper>
          {signedUp ? (
            <AddMembers />
          ) : (
            <>
              <AuthMethod>
                <AuthHeading>Sign Up</AuthHeading>
                {/* <Toggle
                  selectedOption={selectedOption}
                  onChange={handleOptionChange}
                /> */}
              </AuthMethod>
              <SignInDescription>
                Complete the form below to start
              </SignInDescription>
              <Formik
                initialValues={formInitialValues}
                // validationSchema={SignUpValidationSchema(selectedOption)}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, { setSubmitting }) => {
                  const { confirmPassword, terms, ...requestData } = values;
                  dispatch(requestRegistration({ navigate, ...requestData }));
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => {
                  return (
                    <Form style={{ display: 'flex', height: '100%', width: '100%', flexDirection: 'column' }} onSubmit={handleSubmit}>
                      <Credentials>
                        {/* {selectedOption === "team" && (
                          <FormGroup
                            id="teamName"
                            type="text"
                            name="teamName"
                            errors={errors.teamName}
                            placeholder="Team name"
                            values={values.teamName}
                            handleChange={handleChange}
                            touched={touched.teamName}
                            handleBlur={handleBlur}
                          />
                        )} */}
                        <FormGroup
                          id="firstName"
                          type="text"
                          name="firstName"
                          errors={errors.firstName}
                          placeholder="First name"
                          values={values.firstName}
                          handleChange={handleChange}
                          touched={touched.firstName}
                          handleBlur={handleBlur}
                        />
                        <FormGroup
                          id="lastName"
                          type="text"
                          name="lastName"
                          errors={errors.lastName}
                          placeholder="Last name"
                          values={values.lastName}
                          handleChange={handleChange}
                          touched={touched.lastName}
                          handleBlur={handleBlur}
                        />
                        <FormGroup
                          id="email"
                          type="email"
                          name="email"
                          errors={errors.email}
                          placeholder="Email address"
                          values={values.email}
                          handleChange={handleChange}
                          touched={touched.email}
                          handleBlur={handleBlur}
                        />
                        <FormGroup
                          id="password"
                          type="password"
                          name="password"
                          errors={errors.password}
                          placeholder="Password"
                          values={values.password}
                          handleChange={handleChange}
                          touched={touched.password}
                          handleBlur={handleBlur}
                        />
                        <FormGroup
                          id="confirmPassword"
                          type="password"
                          name="confirmPassword"
                          errors={errors.confirmPassword}
                          placeholder="Confirm password"
                          values={values.confirmPassword}
                          handleChange={handleChange}
                          touched={touched.confirmPassword}
                          handleBlur={handleBlur}
                        />
                      </Credentials>
                      <SignUpButtonWrapper>
                        <TermsOfService>
                          <CheckBox
                            checked={values.terms}
                            touched={touched.terms}
                            errors={errors.terms}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="terms"
                          />
                          <TermsWrapper>
                            <TermsText $checked={values.terms}>
                              I agree with&nbsp;
                              <TermsOfServiceLink to="#">
                                Terms of Service, Privacy Policy,
                              </TermsOfServiceLink>{" "}
                              and
                            </TermsText>
                            {errors.terms && touched.terms && (
                              <TermsErrorMsg>{errors.terms}</TermsErrorMsg>
                            )}
                          </TermsWrapper>
                        </TermsOfService>
                        <Button
                          onClick={handleSubmit}
                          type="submit"
                          variant="secondary"
                          size="medium"
                          name="Sign up"
                          icon={<CheckLg />}
                          disabled={isSubmitting}
                        />
                      </SignUpButtonWrapper>
                      <SecondaryButtonWrapper>
                        <RegisterDescription>
                          Already a member?
                        </RegisterDescription>
                        <ShareModalCopyLinkButton $disabled={false} onClick={() => navigate("/sign-in")}>
                          <Slash />
                          <Arrow />
                          Sign In
                          <Slash />
                          <Arrow />
                        </ShareModalCopyLinkButton>
                      </SecondaryButtonWrapper>
                    </Form>
                  );
                }}
              </Formik>
            </>
          )}
        </AuthWrapper>
      </AuthPageWrapper>
    </Container>
  );
};
