export const Options = [
  {
    option: "Support and Auxiliary",
    value: "SupportAndAuxiliary",
  },
  {
    option: "Quality Assurance",
    value: "QualityAssurance",
  },
  {
    option: "IT and Technical",
    value: "ITAndTechnical",
  },
  {
    option: "Research And Analytics",
    value: "ResearchAndAnalytics",
  },
  {
    option: "CyberPersona Architects",
    value: "CyberPersonaArchitects",
  },
  {
    option: "VirtuSynch Innovators",
    value: "VirtuSynchInnovators",
  },
]