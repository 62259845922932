export type MeasuresType = {
  id: string;
  measureMobile: string;
  measureDesktop: string;
  measureDescriptionDesktop: string;
  program: string;
  bucket: string;
  count: number;
};

export const getPopulations = (allMeasures?: MeasuresType[]): MeasuresType[] => {
  const populationMap = new Map();

  const measures = allMeasures ?? []

  for (const measure of measures) {
    const { bucket } = measure;
    if (populationMap.has(bucket)) {
      populationMap.get(bucket).count += 1;
    } else {
      populationMap.set(bucket, { ...measure, count: 1 });
    }
  }  
  return Array.from(populationMap.values());
};

export const getMeasure = (
  allMeasures: MeasuresType[] = [],
  populationSelected: string | null
) => {

  return allMeasures.filter((m) => m.bucket === populationSelected)
};
