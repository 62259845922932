import React from "react";

import { CheckBox } from "../../../Inputs/CustomCheckbox/CheckBox";
import * as s from "./styles";

type Values = {
  reportName: string;
  cover: boolean;
  tableOfContent: boolean;
};

type FormContentProps = {
  values: Values;
  handleChange: (e: React.ChangeEvent<any>) => void;
};

export const FormContent: React.FC<FormContentProps> = ({
  values,
  handleChange,
}) => {
  return (
    <s.FormWrapper>
      <s.FormGroup>
        <s.Label htmlFor="reportName">Name the report</s.Label>
        <s.InputField
          id="reportName"
          name="reportName"
          placeholder="Report 1"
          value={values.reportName}
          onChange={handleChange}
          $required
        />
      </s.FormGroup>

      <s.CheckBoxWrapper>
        <CheckBox
          name="cover"
          checked={values.cover}
          onChange={handleChange}
          label="Front and Back Cover"
        />
      </s.CheckBoxWrapper>
      <s.CheckBoxWrapper>
        <CheckBox
          name="tableOfContent"
          checked={values.tableOfContent}
          onChange={handleChange}
          label="Table of Contents"
        />
      </s.CheckBoxWrapper>
    </s.FormWrapper>
  );
};
