import React, { useState } from "react";
import { DropDownMenuItem } from "../../style";
import { ISelect } from "../../types";
import { ChevronDown } from "react-bootstrap-icons";
import { Container, Title } from "./styles";

type Props = {
  selectedValue?: string;
  item: {
    title: string;
    projects: {
      value: string;
      option: string;
      label?: string;
    }[];
  };
  handleSelectItem: (item: ISelect) => void;
};

const Category = ({ item, selectedValue, handleSelectItem }: Props) => {
  const [open, setOpen] = useState<boolean>(true);
  return (
    <Container>
      <Title onClick={() => setOpen((prev) => !prev)}>
        {item.title} <ChevronDown />
      </Title>
      {open && (
        <>
          {item.projects.map((item) => {
            const isSelected = selectedValue === item.value;
            return (
              <DropDownMenuItem
                key={item.value}
                onClick={() => handleSelectItem(item)}
                $selected={isSelected}
              >
                <span>{item.option}</span>
              </DropDownMenuItem>
            );
          })}
        </>
      )}
    </Container>
  );
};

export default Category;
