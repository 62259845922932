import { useState } from "react";
import { CheckCircle } from "react-bootstrap-icons";
import { toast } from "react-toastify";

import {
  Actions,
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { Button } from "../../Button";
import { MultiSelect } from "../../Inputs/CustomMultiSelect/MultiSelect";
import { ContentWrapper, SelectBlock, SelectBlockLabel, SelectBlockTitle, InfoCircleIcon } from "./style";
import { Select } from "../../Inputs/CustomSelect/Select";
import { teams } from "../../../pages/UserManagment/pages/Teams";
import { mockDataUsersList } from "../../../pages/UserManagment/pages/Users/data/mock";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getSelectedUsers } from "../../../store/selectors/userManagement";
import { selectNewUser } from "../../../store/slices/userManagement";
import { ISelect } from "../../Inputs/CustomMultiSelect/types";

type ModalProps = {
  onClose: () => void;
};
interface Form {
  role: string,
  users: string[]
  teams: string[]
}



export const AddToTeam = ({ onClose }: ModalProps) => {
  const dispatch = useDispatch()
  const selectedUsers = useSelector(getSelectedUsers);
  const defaultForm: Form = {
    role: 'Manager',
    users: mockDataUsersList.filter(user => selectedUsers.find((us) => us === user.uuid)).map((user) => user.email),
    teams: []
  }

  const [isClosing, setIsClosing] = useState(false);
  const [form, setForm] = useState(defaultForm);

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 400);
  };

  const handleSave = () => {
    handleOnClose();
    toast.success("Successfuly! P.S. No endpoint for add to team")
  }

  const handleOnSelect = (item: ISelect[]) => {
    const currentUsers = form.users;
    const addedUsers = item.filter((el) => !currentUsers.includes(typeof el === "string" ? el : el.value));
    const removedUsers = currentUsers.filter((el) => !item.map((el) => (typeof el === "string" ? el : el.value)).includes(el));
    addedUsers.forEach((el) => {
      if (typeof el !== "string") {
        const isOptionValid = mockDataUsersList.some((option) => option.email === el.value);
        const isUserSelected = selectedUsers.some((user) => user === el.value);
        if (isOptionValid && !isUserSelected) {
          const user = mockDataUsersList.find((user) => user.email === el.value)
          if(user) dispatch(selectNewUser(user?.uuid));
        }
      }
    });
    removedUsers.forEach((user) => {
      dispatch(selectNewUser(user));
    });
    setForm({
      ...form,
      users: item.map((el) => (typeof el === "string" ? el : el.value)),
    });
  }

  const rolesOptions = [
    {
      option: 'Manager',
      value: 'Manager',
      label: `Users - Invite / Remove, Edit and Block users invited by himself \nProjects - Create / Edit / Remove / Comments / Publish / Share`
    },
    {
      option: 'Editor',
      value: 'Editor',
      label: `Projects - Create / Edit / Remove / Comments / Publish / Share`
    },
    {
      option: 'Viewer',
      value: 'Viewer',
      label: `Projects - View / Comments / Share`
    }
  ]

  return (
    <ModalOverlay $centred $isClosing={isClosing} onClick={handleOnClose}>
      <ModalWrapper
        $isClosing={isClosing}
        $centred
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeadingWrapper $disableSeparator={false}>
          <ModalContainer>
            <ModalHeading>Add to Teams</ModalHeading>
            <CloseIconWrap>
              <CloseIcon onClick={handleOnClose}></CloseIcon>
            </CloseIconWrap>
          </ModalContainer>
        </ModalHeadingWrapper>
        <OverviewContent>
          <ModalContent>
            <ContentWrapper>
              <SelectBlock>
                <MultiSelect
                  placeholder="Add people"
                  disableRightIcons
                  value={form.users}
                  options={mockDataUsersList.map((el) => ({ value: el.email, option: el.name, label: el.email, avatar: true }))}
                  limit={50}
                  errors={['@', '.']}
                  limitMessage="You can add maximum 50 members to team"
                  onSelect={handleOnSelect}
                />
                <SelectBlockLabel>
                  <InfoCircleIcon />A team can have up to 50 members.
                </SelectBlockLabel>
              </SelectBlock>
              <SelectBlock>
                <SelectBlockTitle>
                  Teams
                </SelectBlockTitle>
                <MultiSelect
                  placeholder="Select teams"
                  value={form.teams}
                  disableAddByEnter
                  onSelect={(item) => {
                    setForm({ ...form, teams: item.map((el) => el.value) })
                  }}
                  options={teams.map((el) => ({ value: el.id, option: el.name }))}
                />
              </SelectBlock>
              <SelectBlock>
                <SelectBlockTitle>
                  Role and permissions
                </SelectBlockTitle>
                <Select
                  onSelect={(item) => {
                    setForm({ ...form, role: item?.value })
                  }}
                  value={form.role}
                  options={rolesOptions}
                />
                <SelectBlockLabel>
                  <InfoCircleIcon /> {rolesOptions.find((opt) => opt.value === form.role)?.label}
                </SelectBlockLabel>
              </SelectBlock>
            </ContentWrapper>
          </ModalContent>
        </OverviewContent>
        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            <Button
              name="Add"
              onClick={handleSave}
              variant={true ? "secondary" : "disabled"}
              disabled={false}
              size="medium"
              icon={<CheckCircle />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
