import { useMemo, useState } from "react";
import { Group } from "@visx/group";
import { scaleLinear, scaleBand } from "@visx/scale";
import { LinePath } from "@visx/shape";
import { AxisLeft, AxisBottom } from "@visx/axis";
import useMeasure from "react-use-measure";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  HeadingNameAndButton,
  SettingsButtonWrapper,
  Title,
} from "../../VerticalBarchart/styles";
import { ChartLegend, ChartLegendValue } from "../../../ChartLegend";
import { setActiveModal } from "../../../../store/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import {
  getIsEditMode,
  getIsPublicMode,
} from "../../../../store/selectors/main";
import { getPageSettings } from "../../../../store/selectors/projects";
import {
  calculateLabelLength,
  calculateNumTicks,
  getAvailableWidgetTypes,
  getScaleBandTickValues,
} from "../../widgetHelpers";
import { ticksFormatter } from "../../../../helpers/ticksFormatter";
import { Loader } from "../../../Loader";
import { Tooltip, TooltipProps } from "../../Tooltip";
import { AiSuggestionsDto, WidgetItem } from "../../../../models/Widgets";
import { getAiSuggestions } from "../../../../store/selectors/widgets";
import { setCurrentWidget } from "../../../../store/slices/projectPages";
import { SelectBage } from "../../SelectBage";
import { replaceWords } from "../../../../helpers/replaceName";
import { LineChartMarker } from "../../utils/getMarker";
import { getGroupedData, getLineChartData } from "../utils/getLineChartMarkers";
import {
  getCurrentColorV2,
  getCurrentMarker,
} from "../../utils/getCurrentMarker";
import { createPortal } from "react-dom";
import { TickLabel } from "../../components/LabelTooltip";

interface LineChartProps {
  storytelling?: boolean;
  recommended?: boolean;
  selected?: boolean;
  showLegend?: boolean;
  currentWidget: WidgetItem;
  hideName?: boolean;
  isRight?: boolean;
}
export const GroupedLineChart = ({
  storytelling,
  recommended,
  currentWidget,
  showLegend = true,
  selected = false,
  hideName = false,
  isRight,
}: LineChartProps) => {
  const dispatch = useDispatch();

  const [ref, bounds] = useMeasure();
  const [refWidget, boundsWidget] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);

  const isPublicRoute = useSelector(getIsPublicMode);
  const { styleId } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);

  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [hoveredElement, setHoveredElement] = useState<null | string>(null);

  const margin = { top: 15, right: 5, bottom: 20, left: 40 };
  const width = bounds.width || 1084;
  const height = bounds.height || 163;

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  const lineChartSuggestion = aiSuggestions?.find(
    (chart: AiSuggestionsDto) => chart.chartType === "lineChart"
  );

  const xAxe = currentWidget?.xAxe?.[0] ?? lineChartSuggestion?.xAxe?.[0];
  const yAxe = currentWidget?.yAxe?.[0] ?? lineChartSuggestion?.yAxe?.[0];

  const chartData = getLineChartData(currentWidget, aiSuggestions);

  const xAxes: string[] = useMemo(() => {
    return (
      currentWidget?.uniqueValues?.[xAxe] ||
      Array.from(new Set(chartData.map((d: any) => String(d[xAxe])))) ||
      []
    );
  }, [currentWidget?.uniqueValues, xAxe, chartData]);

  const xScale = scaleBand({
    domain: xAxes,
    range: [margin.left, width - margin.right],
    padding: -1,
  });

  const yAxes = chartData?.map((d: any) => d[yAxe]) || [];
  const yScale = scaleLinear({
    domain: [0, Math.max(...yAxes)],
    range: [height - margin.bottom, margin.top],
    nice: true,
  });

  const xTicksPositions = xAxes.map(
    (item) => xScale(item)! + xScale.bandwidth() / 2
  );

  const handleCircleMouseLeave = () => {
    setTooltip(null);
    setHoveredElement(null);
  };

  const groupBy = currentWidget?.groupBy?.at(0);

  const uniqueValuesKeys =
    (currentWidget?.uniqueValues &&
      Object.keys(currentWidget?.uniqueValues!)) ||
    [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

  const groupKey = currentWidget?.groupBy || "";
  const groupedData = getGroupedData(chartData, groupKey);

  const chartGroupKeys =
    uniqueValuesKeys?.length && currentWidget?.uniqueValues
      ? currentWidget?.uniqueValues[groupByKey!]
      : Object.keys(groupedData);

  const legendValues: ChartLegendValue[] = [];

  for (let i = 0; i < chartGroupKeys?.length; i++) {
    const dataKey = chartGroupKeys?.at(i);
    const color = getCurrentColorV2(currentWidget, dataKey, styleId);
    legendValues.push({ label: dataKey!, color });
  }
  const yScaleNumTicks = calculateNumTicks({ height: height });

  const yTicksPositions = yScale
    .ticks(yScaleNumTicks)
    .slice(1)
    .map((value) => yScale(value));

  const xScaleNumTicksCalculated = calculateNumTicks({ width });

  // const isReasonableAmountOfTicks =
  //   xScaleNumTicksCalculated <= xAxes.length &&
  //   xScaleNumTicksCalculated > 0 &&
  //   xAxes.length / xScaleNumTicksCalculated >= 1.5;

  // const xScaleNumTicks = isReasonableAmountOfTicks
  //   ? xScaleNumTicksCalculated
  //   : xAxes.length;

  const xScaleNumTicks = false ? xScaleNumTicksCalculated : xAxes.length;

  const xScaleTickValues = getScaleBandTickValues({
    tickCount: xScaleNumTicks,
    ticks: xAxes as string[],
  });

  const xScaleTickLabelMaxLength = calculateLabelLength({
    width: width - margin.left - margin.right,
    tickValues: xScaleTickValues,
  });

  if (!chartData?.length) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  return (
    <>
      <HeaderWrapper ref={refWidget}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <div />}
            {!isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={false}
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {availableWidgetsCount + 1}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}

        {legendValues?.length > 1 && showLegend && currentWidget?.legend && (
          <ChartLegend
            chartWidth={boundsWidget.width}
            legendType="unit"
            legendValues={legendValues}
            isRight={isRight}
          />
        )}
      </HeaderWrapper>
      <svg width="100%" height={"100%"} ref={ref}>
        <Group>
          {xTicksPositions.map((x, i) => (
            <line
              key={`x-line-${i}`}
              x1={x}
              y1={margin.top}
              x2={x}
              y2={height - margin.bottom}
              stroke="#e0e0e0"
              strokeDasharray="1,2"
            />
          ))}
          {yTicksPositions.map((y, i) => (
            <line
              key={`y-line-${i}`}
              x1={margin.left}
              y1={y}
              x2={width - margin.right}
              y2={y}
              stroke="#e0e0e0"
              strokeDasharray="1,2"
            />
          ))}
          <line
            x1={width - margin.right}
            y1={margin.top}
            x2={width - margin.right}
            y2={height - margin.bottom}
            stroke="#e0e0e0"
            strokeDasharray="1, 2"
          />
        </Group>
        <Group>
          <AxisBottom
            scale={xScale}
            top={height - margin.bottom}
            left={0}
            hideTicks
            tickLabelProps={(_, index, values) => {
              const isFirstTick = index === 0;
              const isLastTick = index === values.length - 1;
              const textAnchor =
                (isFirstTick && "start") || (isLastTick && "end") || "middle";

              return {
                fontSize: 11,
                fill: "#5F6877",
                dy: -2,
                textAnchor: textAnchor,
              };
            }}
            tickValues={xScaleTickValues}
            tickComponent={(props: any) => (
              <TickLabel
                {...props}
                length={xScaleTickLabelMaxLength}
                offsetX={-10}
              />
            )}
            axisLineClassName="barchartAxisLine"
          />
          <AxisLeft
            scale={yScale}
            left={margin.left}
            stroke="#ccc"
            strokeDasharray="1,2"
            tickLineProps={{
              stroke: "#939BA7",
            }}
            numTicks={yScaleNumTicks}
            tickLabelProps={() => ({
              fontSize: 11,
              fill: "#5F6877",
              textAnchor: "start",
              dominantBaseline: "middle",
              dx: -30,
            })}
            tickFormat={(value: any) => {
              return ticksFormatter(value);
            }}
          />
        </Group>
        <Group>
          {Object.values(groupedData).map((group: any, i) => {
            const key = Object.keys(groupedData)?.at(i);
            const color = getCurrentColorV2(currentWidget, key, styleId);

            return (
              <Group key={`line-group-${i}`}>
                <LinePath
                  data={group}
                  x={(d: any) => xScale(d[xAxe])! + xScale.bandwidth() / 2}
                  y={(d: any) => yScale(d[yAxe])}
                  stroke={color}
                  strokeWidth={2}
                  style={{ transition: "0.3s" }}
                  opacity={hoveredElement ? 0.2 : 1}
                />
                {group.map((d: any, j: number) => {
                  if (!color) return <></>;

                  const markerKey = `line-group-${i}-${j}`;

                  const onMouseEnter = (event: any) => {
                    if (
                      currentWidget.tooltip &&
                      !recommended
                    ) {
                      const { pageX, pageY, clientX, clientY } = event;
                      const coords = { pageX, pageY, clientX, clientY };
                      setHoveredElement(markerKey);

                      setTooltip({
                        name: key,
                        data: d,
                        coords,
                      });
                    }
                  };

                  const circleRadius = hoveredElement === markerKey ? 6 : 3;

                  return LineChartMarker({
                    i: "circle-" + i + "-" + j,
                    opacity: hoveredElement
                      ? hoveredElement === markerKey
                        ? 1
                        : 0
                      : 1,
                    hovered: hoveredElement === markerKey,
                    markerType: getCurrentMarker(currentWidget, key),
                    color: String(color),
                    xScale: xScale(d[xAxe])!,
                    xScaleBand: xScale.bandwidth() / 2,
                    yScale: yScale(d[yAxe]),
                    r: circleRadius,
                    onMouseEnterHandler: onMouseEnter,
                    onMouseLeaveHandler: handleCircleMouseLeave,
                  });
                })}
              </Group>
            );
          })}
        </Group>
      </svg>
      {tooltip &&
        createPortal(
          <Tooltip
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
            coords={tooltip.coords}
          />,
          document.body
        )}
    </>
  );
};
