import React from "react";
import { useSearchParams } from "react-router-dom";
import { EmptyPage } from "../../../../components/EmptyPage";
import { PlusLg } from "react-bootstrap-icons";
import { ReactComponent as NoDataImage } from "./assets/noDataImage.svg";
import UsersList from "../../../../components/UserManagementComponents/Users/UsersList";
import { mockDataUsersList } from "./data/mock";

type Props = {};

export const UsersTable = (props: Props) => {
  const [searchParams] = useSearchParams();
  const test = searchParams.get("test");

  return (
    <>
      {Boolean(test) ? (
        <EmptyPage
          Icon={NoDataImage}
          title="No users were discovered"
          subTitle={`Feel free to invite new people into your space by clicking the "Invite People" button below! `}
          button={{
            name: "Invite People",
            onClick: () => {},
            icon: <PlusLg />,
          }}
        />
      ) : (
        <>
          <UsersList mockDataUsersList={mockDataUsersList}></UsersList>
        </>
      )}
    </>
  );
};
