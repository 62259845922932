import UserManagementMenu from "../../components/UserManagementMenu";
import { UserManagementContainer } from "./style";
import { Outlet } from "react-router-dom";

export const UsersManagment: React.FC = () => {
  return (
    <>
      <UserManagementContainer>
        <UserManagementMenu />
      </UserManagementContainer>
      <Outlet />
    </>
  );
};
