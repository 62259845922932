import React, { HTMLInputTypeAttribute } from "react";
import { Heading, InputContainer, InputWrapper, StyledInput } from "./styles";

interface CustomInputInterface {
  value: any;
  heading?: string;
  type: HTMLInputTypeAttribute;
  name: string;
  onChange: (e: any) => void;
  disablePaddings?: boolean;
  placeholder?: string;
  size?: 'default' | 'base';
}

export const CustomInput = ({
  value,
  heading,
  type,
  name,
  onChange,
  disablePaddings = false,
  size = 'default',
  placeholder = 'Type text here...'
}: CustomInputInterface) => {
  return (
    <InputContainer $disablePaddings={disablePaddings}>
      <Heading> {heading && heading}</Heading>
      <InputWrapper $disablePaddings={disablePaddings} $size={size}>
        <StyledInput
          $size={size}
          type={type}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      </InputWrapper>
    </InputContainer>
  );
};
