import React, { useRef } from "react";
import { Container, MenuItem, Separator } from "./styles";
import { useOnClickOutside } from "usehooks-ts";
import { Trash, Pen } from "react-bootstrap-icons";

type Props = {
  setMenu: any;
  cords?: {
    pageX: any;
    pageY: any;
    clientX: any;
    clientY: any;
  };
};

const UserRowMenu = ({ cords, setMenu }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside([ref], () => setMenu(null));
  return (
    <Container
      ref={ref}
      $top={cords?.pageY + 10}
      $left={cords?.pageX - 194}
      onMouseLeave={() => setMenu(null)}
    >
      <MenuItem>
        <Pen />
        Update Profile
      </MenuItem>
      <Separator></Separator>
      <MenuItem $delete>
        <Trash />
        Delete
      </MenuItem>
    </Container>
  );
};

export default UserRowMenu;
