import { ThreeDots } from "react-bootstrap-icons";
import styled from "styled-components";

export const ButtonsWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  gap: 8px;
  z-index: 3;
  opacity: 0;
  transition: 0.3s;
`;

export const Dots = styled(ThreeDots)`
  opacity: 0;
  transition: 0.3s;
  color: ${({ theme }) => theme.neutralTextWeak};
  &:hover {
    color: ${({ theme }) => theme.neutralText};
  }
  &:active {
    color: ${({ theme }) => theme.neutralText};
  }
`;

export const Chip = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #FFFFFF;
  max-width: 160px;
  height: max-content;
  padding: 3px 4px;
  border-radius: 2px;
  background: #2D6E8D;
  text-transform: capitalize;
`;

export const ImageOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50%;
  cursor: pointer;
  border-radius: 8px;
  background: linear-gradient(
    to bottom,
    rgba(20, 28, 44, 0.2) 0%,
    rgba(20, 28, 44, 0) 100%
  );
  transition: opacity 0.4s ease;
  opacity: 0;
`;

export const ImageContainer = styled.div<{
  $blur: boolean;
  $fill: string;
}>`
  ${({ $blur }) => $blur ? `filter: blur(3px);` : ``};
  display: flex;
  flex-flow: column wrap;
  min-height: 164px;
  width: 100%;
  border-radius: 8px;
  align-self: center;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  color: ${({ $fill }) => $fill};
  transition: height 0.4s ease;
  overflow: hidden;
  transition: 0.3s;
`;

export const TeamContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 236px;
  min-width: 281px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 8px;

  &:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.neutralBorder};
    ${ButtonsWrapper} {
      opacity: 1;
    }
    ${Dots} {
      display: flex;
      opacity: 1;
    }
    ${ImageOverlay} {
      opacity: 1;
    }
  }
  @media (min-width: 1728px) {
    min-width: 281px;
    width: 100%;
  }
`;

export const WrapperContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  transition: padding 0.3s ease;
  min-height: 72px;
`;

export const TopWrapperContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;

export const LabelsWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const Title = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: max-content;
  padding: 0 4px;
  border: 1px solid ${({ theme }) => theme.neutralBackground};
  background: ${({ theme }) => theme.neutralBackground};
  color: ${({ theme }) => theme.neutralTextWeak};
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;