import { InfoCircle } from "react-bootstrap-icons";
import styled from "styled-components";


export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  gap: 16px;
`;

export const SelectBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const InfoCircleIcon = styled(InfoCircle)`
  display: flex;
  margin-top: 1px;
`;

export const SelectBlockTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.neutralText};
  padding-bottom: 4px;
`;

export const SelectBlockLabel = styled.div`
  white-space: pre-wrap;
  gap: 4px;
  display: flex;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: ${({ theme }) => theme.neutralTextWeak};
`;