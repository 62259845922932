import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`

export const ContentText = styled.div`
  padding: 16px 64px 64px 64px;
  font-size: 16px;
  font-weight: 600;
  color: ${({theme}) => theme.neutralText};
  text-align: center;
  p {
    padding: 0 30px;
    margin-top: 8px;
    color: ${({theme}) => theme.neutralTextWeak};
    font-size: 14px;
    font-weight: 400;
    b {
      font-weight: 500;
    }
  }
`