import { ManagementUserList, SortedColumn } from "../../../pages/UserManagment/pages/Users/data/mock";
import { IActiveFilters } from "../../UserManagementComponents/Users/UsersList";


interface IApplyFilters {
  skip?: SortedColumn;
  activeFilters: IActiveFilters;
  allUsersList: ManagementUserList[];
}

export const applyFilters = ({skip, activeFilters, allUsersList} : IApplyFilters) => {
  const filters = Object.keys(activeFilters) as SortedColumn[];

  let result = allUsersList;

  filters.forEach((keyName) => {
    const { search, filter } = activeFilters[keyName];

    if (search) {
      result = result.filter((item) => {
        const itemValue = item[keyName].toLocaleString().toLowerCase();

        const searchValue = search.toLocaleString().toLowerCase();

        return itemValue.match(searchValue);
      });
    }

    if (skip === keyName) return;

    if (filter) {
      result = result.filter((item) => {
        const itemValue = item[keyName];

        if (Array.isArray(itemValue)) {
          return itemValue.some((item) => filter.includes(item));
        }
        return filter.includes(String(itemValue));
      });
    }
  });

  return result;
};