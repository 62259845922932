import styled from "styled-components";
import { X, ChevronDown, XLg } from "react-bootstrap-icons";

//SelectContainer

export const DownIcon = styled(ChevronDown) <{ $close?: boolean; }>`
  transition: 0.2s;
  transform: rotateX(${({ $close }) => ($close ? "180deg" : "0deg")});
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 10px;
`;

export const SelectInput = styled.input`
  border: none;
  outline: none;
  font-size: 14px;
  display: flex;
  max-height: 24px;
  max-width: 100%;
  width: auto;
  min-width: 50px;
`;

export const Container = styled.div<{ $width?: string; }>`
  width: ${({ $width }) => $width || "100%"};
  position: relative;
`;

export const VerticalLine = styled.div<{ $opened?: boolean; }>`
  display: flex;
  width: 1px;
  background: ${({ theme }) => theme.neutralBorder};
  transition: .15s all linear;
  height: ${({ $opened }) => $opened ? '38px' : '16px'};
  margin-bottom: ${({ $opened }) => $opened ? '0' : '10px'};
`;
export const ChipVerticalLine = styled.div<{ $opened?: boolean; }>`
  display: flex;
  width: 1px;
  background: ${({ theme }) => theme.neutralBorder};
  transition: .15s all linear;
  height: 16px;
`;
export const SelectContainer = styled.div<{ $opened?: boolean; $disabled: boolean, $isSelected?: boolean }>`
  width: 100%;
  transition: .15s all linear;
  min-height: 40px;
  border: 1px solid ${({ $opened, theme }) => $opened ? theme.primaryBorder : theme.neutralBorder};
  border-radius: 6px;
  user-select: none;
  cursor: ${({ $disabled }) => $disabled ? "not-allowed" : "pointer"};
  display: flex;
  justify-content: space-between;
  background: ${({ $disabled, $opened, $isSelected, theme }) => $disabled ? theme.neutralBackgroundMedium : ($isSelected || $opened) ? theme.neutralBackgroundBase : theme.neutralBackground};
  color: ${({ $disabled, theme }) => $disabled ? theme.neutralTextDisabled : theme.neutralText};
  span {
    color: ${({ $disabled, theme }) => $disabled ? theme.neutralTextDisabled : theme.neutralTextWeak};
  }
  ${DownIcon}{
    color: ${({ $disabled, theme }) => $disabled ? theme.neutralTextDisabled : theme.neutralTextWeak};
  }
  ${SelectInput}{
    background: transparent;
  }
  &:hover{
    ${DownIcon}{
      color: ${({ theme, $opened, $disabled }) => $disabled ? theme.neutralTextDisabled : $opened ? theme.neutralTextWeak : theme.neutralText};
    }
  }
`;

export const SelectedValue = styled.div<{ $width?: number; }>`
  font-size: 14px;
  line-height: 16px;
  padding: 7px;
  gap: 4px;
  display:flex;
  flex-wrap: wrap;
  min-height: 36px;
  max-width: ${({ $width }) => `${($width || 0) - 50}px`};
`;

export const Buttons = styled.div`
  display: flex;
  align-items: end;
`;

export const HiddenSpan = styled.span`
  visibility: hidden;
  white-space: pre-wrap;
  max-width: 100%;
  font-size: 14px;
  font-family: inherit;
  max-width: 100%;
  position: absolute;
`;

export const XButton = styled(XLg)`
  color: ${({ theme }) => theme.neutralTextWeak};
  transition: .15s all linear;

`;

export const SelectChip = styled.div<{ $error?: boolean; }>`
  display: inline-flex;
  align-items: center;
  background: ${({ $error, theme }) => $error ? theme.alertBackground : theme.neutralBackground};
  color: ${({ theme }) => theme.neutralText};
  padding: 4px;
  font-size: 12px;
  line-height: 14px;
  border-radius: 3px;
  gap: 6px;
  transition: .15s all linear;
  min-width: max-content;
  max-height: 24px;
  &:hover {
    background: ${({ $error, theme }) => $error ? theme.alertBackgroundMedium : theme.neutralBackgroundHover};
    ${XButton} {
      color: ${({ theme }) => theme.neutralText};
    }
  }
  &:active {
    background: ${({ $error, theme }) => $error ? theme.alertBackgroundActive : theme.neutralBackgroundActive};
    ${XButton} {
      color: ${({ theme }) => theme.neutralText};
    }
  }
`;

export const CloseIcon = styled(X)`
  padding-right: 8px;
  width: 27px;
  height: 20px;
`;

export const DropDownSelect = styled.div<{ $width?: number; }>`
  position: fixed;
  margin-top: 4px;
  padding: 8px;
  width: ${({ $width }) => `${$width}px` || "100%"};
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 6px;
  background: white;
  z-index: 1003;
  max-height: 240px;
  overflow: auto;
`;

export const DropDownMenuList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const DropDownMenuItem = styled.li<{ $selected?: boolean; }>`
  padding: 8px;
  transition: 0.2s;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  display: flex;
  column-gap:4px;
  row-gap: 2px;
  align-items: center;
  background: ${({ $selected, theme }) => $selected && theme.neutralBackground};
  cursor: pointer;
  &:hover {
    background: ${({ $selected, theme }) => $selected ? theme.neutralBackgroundHover : theme.neutralBackground};
  }
  &:active {
    background: ${({ theme }) => theme.neutralBackgroundActive};
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 38px;
  max-height: 90px;
`;


export const Label = styled.span`
  font-size: 11px;
  color: ${({ theme }) => theme.neutralTextWeak};
  margin-bottom: 1px;
`;

export const AvatarImage = styled.img`
  width: 32px;
  height: 32px;
`;

export const Avatar = styled.span`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  color: ${({ theme }) => theme.neutralText};
  background: ${({ theme }) => theme.neutralBackground};
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`;

export const DropDownText = styled.div`
  display: flex;
  flex-direction: column;
  span:nth-child(1) {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: ${({ theme }) => theme.neutralText};
  }
  span:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: ${({ theme }) => theme.neutralTextWeak};
  }
`;