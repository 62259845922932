import { useCallback, useEffect, useState } from "react";

import {
  SelectTemplateHeading,
  SelectTemplateWrapper,
  TemplateIcon,
  TemplateImg,
  TemplateItem,
  TemplatesWrapper,
} from "../styles";
import { ComponentSettings } from "../../../../models/Pages";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentPageKpi,
  getCurrentPageWidgets,
  getCurrentProjectData,
} from "../../../../store/selectors/projects";
import { LAYOUTS } from "../../../ProjectPageLayouts/config";
import { requestWidgetsSuggestions } from "../../../../store/slices/widgets";
import { Loader } from "../../../Loader";
import { getModalCreateOptions } from "../../../../store/selectors/modals";

export const SelectTemplate = ({
  settings,
  onUpdateSettings,
}: ComponentSettings) => {
  const project = useSelector(getCurrentProjectData);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const widgets = useSelector(getCurrentPageWidgets);
  const createNewProject = useSelector(getModalCreateOptions);
  const aiKpi = useSelector(getCurrentPageKpi);
  const dispatch = useDispatch();

  const setFirstlyTemplates = (numWidgets: number) => {
    try {
      const numComplexWidgets = LAYOUTS.map((r) => r.numComplexWidgets);
      const maxComplexWidget = Math.max(...numComplexWidgets);
      const complexWidgets = widgets?.items?.filter((c) =>
        ["mapChart", "sankey", "sankeyChart"].includes(c.chartType)
      )?.length;

      let complexPlotsCount = createNewProject
        ? project.complexPlotsCount! > maxComplexWidget
          ? maxComplexWidget
          : project.complexPlotsCount
        : 0;

      if (complexWidgets) {
        complexPlotsCount =
          maxComplexWidget > complexWidgets ? complexWidgets : maxComplexWidget;
      }
      let currentNumWidgets = numWidgets;

      if (createNewProject) {
        currentNumWidgets = currentNumWidgets === 1 ? 1 : currentNumWidgets;
      }
      if (aiKpi?.count > 0) {
        currentNumWidgets = currentNumWidgets + 1;
      }

      if (currentNumWidgets > 8 && createNewProject) {
        currentNumWidgets = 8;
      }

      let prevNumWidgets = currentNumWidgets;
      if (createNewProject) {
        prevNumWidgets = currentNumWidgets === 1 ? 1 : currentNumWidgets - 1;
      }

      let templates = LAYOUTS.filter(
        (r) =>
          r.numComplexWidgets === complexPlotsCount &&
          (r.numWidgets === currentNumWidgets ||
            r.numWidgets === prevNumWidgets)
      );
      if (!templates?.length) {
        templates = LAYOUTS.filter(
          (r) =>
            r.numComplexWidgets === complexPlotsCount &&
            r.numWidgets === 8 &&
            r.variant === "c"
        );
      }
      // const existsMap = !!widgets?.items?.filter((t) => t.chartType === "mapChart").length;

      // if (!existsMap) {
      //   templates = templates.filter((t) => !checkFullProperty(t?.arranging));
      // }

      templates = [...templates.reverse()];
      setTemplates((templates as any) || []);

      if (
        (settings.templateId === "default" || settings.templateId === "") &&
        templates?.length
      ) {
        onUpdateSettings({ ...settings, templateId: templates[0]?.id });
      }
    } finally {
      setLoading(false);
    }
  };

  const getTemplates = useCallback(() => {
    setLoading(true);
    if (widgets?.count && !createNewProject) {
      setFirstlyTemplates(widgets?.count);
    } else {
      dispatch(
        requestWidgetsSuggestions({
          projectId: project.id!,
          includeData: false,
          callbacks: {
            onSuccess: (data) => {
              setFirstlyTemplates(data?.suggested_charts?.length);
            },
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createNewProject, project.id, widgets?.count]);

  useEffect(() => {
    if (settings.dashType === "dashboard") {
      getTemplates();
    }
  }, [getTemplates, settings.dashType]);

  return (
    <SelectTemplateWrapper>
      <SelectTemplateHeading>Select template</SelectTemplateHeading>
      <TemplatesWrapper $loading={loading}>
        {loading ? <Loader blur={false} /> : null}
        {templates?.map((t: any) => {
          return (
            <TemplateItem
              key={t.id}
              $selectedItem={settings.templateId === t.id}
              onClick={() => {
                onUpdateSettings({ ...settings, templateId: t.id });
              }}
            >
              <TemplateIcon>
                <TemplateImg
                  $selectedItem={settings.templateId === t.id}
                  src={t.image}
                  alt={t.id}
                />
              </TemplateIcon>
            </TemplateItem>
          );
        })}
      </TemplatesWrapper>
    </SelectTemplateWrapper>
  );
};
