import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top:6px;
  margin-top:6px;
  border-top: 1px solid ${({theme}) => theme.neutralBorder};
  
   &:first-child {
    margin: 0;
    padding: 0;
    border: none;
   }
`

export const Title = styled.div`
  min-width: 200px;
  cursor: pointer;
  margin: 6px 4px;
  gap: 10px;
  display: flex;
  justify-content: space-between;
  color: ${({theme}) => theme.neutralTextWeak};
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
`