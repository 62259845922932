import { IFormatting, WidgetItem } from "../../../../models/Widgets";

export const RadarChartGroupedData = (
  currentWidgetData?: WidgetItem,
  chartSuggestion?: any
) => {
  const data = currentWidgetData?.data || [];
  const groupBy = currentWidgetData?.groupBy?.[0];
  const xAxe = currentWidgetData?.xAxe?.[0] ?? chartSuggestion?.xAxe?.[0];
  const yAxe = currentWidgetData?.yAxe?.[0] ?? chartSuggestion?.yAxe?.[0];

  const xAxes =
    currentWidgetData?.uniqueValues?.[xAxe] ||
    Array.from(new Set(data.map((d: any) => String(d[xAxe])))) ||
    [];

  let unsortedGroupedData: any = {};

  if (groupBy) {
    unsortedGroupedData = data.reduce((acc, obj) => {
      const group = obj[groupBy];
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push({
        x: String(obj[xAxe] ?? obj.year),
        y: obj[yAxe] ?? obj.value,
      });
      return acc;
    }, {});
  } else {
    unsortedGroupedData = {
      default: data.map((l) => ({
        x: String(l[xAxe] ?? l.year),
        y: l[yAxe] ?? l.value,
      })),
    };
  }

  const sortedGroupedData = Object.keys(unsortedGroupedData).reduce(
    (acc: any, curr: string) => {
      const dataMap = new Map(
        unsortedGroupedData[curr].map((d: any) => [d.x, d])
      );

      acc[curr] = xAxes.map((key: string) => dataMap.get(key)).filter(Boolean);

      return acc;
    },
    {}
  );

  return sortedGroupedData;
};

export const getRadarChartDefaultColors = (data: any): IFormatting[] => {
  const groupBy = data.groupBy?.[0];

  if (!groupBy) {
    return [{ key: "default", color: "1" }];
  }

  const keys = data.uniqueValues[groupBy] || [];

  return keys.map((item: string, index: number) => ({
    key: item,
    color: String(index + 1),
  }));
};
