import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatasetsOverviewModal } from "./DatasetsOverview";
import { closeActiveModal } from "../../store/slices/modals";
import { DatasetLibraryModal } from "./DatasetLibrary";
import { CreateProject } from "./CreateProject";
import { ModalsTypes } from "../../models";
import { ProjectSettingsModal } from "./ProjectSettings";
import { DataVizSettings } from "./DataVizSettings";
import { UploadFiles } from "./UploadFiles";
import { PageRecommendedWidgets } from "./PageRecommendedWidgests";
import { getActiveModal } from "../../store/selectors/modals";
import { ShareModal } from "./Share";
import { UpdateProfileModal } from "./UpdateProfile";
import useScrollBlock from "../../hooks/useScrollBlock";
import { DataKpiSettings } from "./DataKpiSettings";
import { CreateComparison } from "./CreateComparison";
import { CreateTeam } from "./CreateTeam";
import { AddToTeam } from "./AddToTeam";
import { InvitePeople } from "./InvitePeople";
import { InviteToTeam } from "./InviteToTeam";
import { EditTeam } from "./EditTeam";
import { ReportConfiguration } from "./ReportConfiguration";
import { setPageIdSelected, setReportActionType } from "../../store/slices/appConfig";

const ModalsWrapper = () => {
  const dispatch = useDispatch();
  const activeModal = useSelector(getActiveModal);
  const [blockScroll, allowScroll] = useScrollBlock();

  const closeModal = () => {
    dispatch(closeActiveModal());
  };

  useEffect(() => {
    if (!activeModal.length) {
      allowScroll();
    } else {
      blockScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModal]);

  useEffect(() => {
    window.addEventListener("popstate", closeModal);

    return () => {
      window.removeEventListener("popstate", closeModal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {activeModal.map((modal: ModalsTypes) => (
        <React.Fragment key={modal}>
          {modal === "createProjectModal" && (
            <CreateProject onClose={closeModal} />
          )}
          {modal === "datasetLibraryModal" && <DatasetLibraryModal />}
          {modal === "datasetOverviewModal" && (
            <DatasetsOverviewModal closeModal={closeModal} />
          )}
          {modal === "projectSettingsModal" && (
            <ProjectSettingsModal closeModal={closeModal} />
          )}
          {modal === "recommendedWidgetsModal" && (
            <DataVizSettings closeModal={closeModal} />
          )}
          {modal === "recommendedKpisModal" && (
            <DataKpiSettings closeModal={closeModal} />
          )}
          {modal === "uploadDatasetModal" && (
            <UploadFiles onClose={closeModal} />
          )}
          {modal === "pageRecommendedWidgetsModal" && (
            <PageRecommendedWidgets onClose={closeModal} />
          )}
          {modal === "shareProjectModal" && <ShareModal onClose={closeModal} />}
          {modal === "uploadProfileModal" && (
            <UpdateProfileModal onClose={closeModal} />
          )}
          {modal === "createComparisonModal" && (
            <CreateComparison onClose={closeModal} />
          )}
          {modal === "createTeamModal" && (
            <CreateTeam onClose={closeModal} />
          )}
          {modal === "editTeamModal" && (
            <EditTeam onClose={closeModal} />
          )}
          {modal === "addToTeamModal" && (
            <AddToTeam onClose={closeModal} />
          )}
          {modal === "invitePeopleModal" && (
            <InvitePeople onClose={closeModal} />
          )}
          {modal === "inviteToTeamModal" && (
            <InviteToTeam onClose={closeModal} />
          )}
          {modal === "createReportModal" && (
            <ReportConfiguration
              onClose={() => {
                dispatch(setPageIdSelected(null));
                dispatch(setReportActionType("unset"));
                closeModal();
              }}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default ModalsWrapper;
