import { useEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";

import { AiKpiDto } from "../../models/Widgets";
import { KPIComponent, KPIProps } from "../KPIComponent";
import {
  HeadingNameAndButton,
  KPIComponentRow,
  KPIName,
  KPIRow,
  KPISeparator,
  KPIWrapperMainContainer,
} from "./styles";
import { SelectBage } from "../Widgets/SelectBage";
import { useResizeObserver } from "usehooks-ts";

export enum KPI_TYPES {
  TREND = "trend",
  HIGH_LOW = "high/low",
  DISTINCTION = "distinction",
  TOP = "top",
}

export interface KPIWrapperProps {
  showNameBlock?: boolean;
  kpi: AiKpiDto;
  recommended: boolean;
  selected: boolean;
  onSelect?: () => void;
  size?: "xl" | "lg" | "md" | "sm";
  isRowView?: boolean;
  kpiId?: string;
  adaptiveWidth?: boolean;
  settings?: boolean;
}

export const KPIComponentWrapper = (props: KPIWrapperProps) => {
  const {
    kpi,
    recommended,
    selected,
    showNameBlock = true,
    onSelect,
    size = "lg",
    isRowView,
    kpiId = "kpi_name",
    adaptiveWidth = false,
    settings = false
  } = props;
  const [kpiProps, setKpiProps] = useState<KPIProps[]>([]);
  const ref = useRef<HTMLDivElement>(null)
  const { width = 0 } = useResizeObserver({
    ref,
  })
  
  const formatNumber = (num: number) => {
    if (!num) return { value: "0", label: "" };
    if (num >= 1e9) {
      return { value: Math.floor(num / 1e9).toString(), label: "B" };
    } else if (num >= 1e6) {
      return { value: Math.floor(num / 1e6).toString(), label: "M" };
    } else if (num >= 1e3) {
      return { value: num.toLocaleString(), label: "" };
    } else {
      return { value: num.toString(), label: "" };
    }
  };


  useEffect(() => {
    const aggregation = kpi?.aggregations?.at(0);
    const data = kpi?.data?.at(0);
    const groupBy = kpi.groupBy;
    const column = kpi.column;
    if (kpi.type === KPI_TYPES.TREND) {
      const { value, label } = formatNumber(data?.[`${aggregation}_${column}`]) || {};
      setKpiProps([
        {
          key: value,
          value,
          size,
          indicator: data?.percentage ? `${data.percentage}%` : undefined,
          label: { position: "right", text: label },
        },
      ]);
    } else {
      let newKpis: KPIProps[] = [];
      const keys = Object.keys(data || {});
      for (let key of keys) {
        const localData = data[key];
        const newKey = `${aggregation}_${column}`;
        const { value, label } = formatNumber(localData[newKey]);
        const subtitle = groupBy ? localData[groupBy] : undefined;

        newKpis.push({
          key: value,
          value,
          size,
          indicator: localData?.percentage ? `${localData.percentage}%` : "0",
          label: { position: "right", text: label },
          subtitle,
        });
      }
      setKpiProps(newKpis || []);
    }
  }, [kpi, size]);

  return (
    <KPIWrapperMainContainer
      $selected={selected}
      $recommended={recommended}
      onClick={onSelect}
      $isRowView={isRowView}
    >
      {showNameBlock ? (
        <HeadingNameAndButton ref={ref}>
          <KPIName
            $width={adaptiveWidth ? `${width ? width - (settings? 45 : 10) + "px" : '100%'}` : kpiProps?.length > 1 ? "260px" : "140px"}
            data-tooltip-id={kpiId}
            data-tooltip-content={kpi.name}
          >
            {kpi.name}
          </KPIName>
          {recommended ? <SelectBage selected={selected} /> : null}
        </HeadingNameAndButton>
      ) : null}

      <KPIRow>
        {kpiProps?.map((kpi, index) => {
          return (
            <KPIComponentRow key={kpi.key}>
              <KPIComponent
                key={kpi.key}
                size={kpi.size}
                value={kpi.value}
                indicator={kpi.indicator}
                label={kpi.label}
                subtitle={kpi.subtitle}
              />
              {index !== kpiProps?.length - 1 ? <KPISeparator /> : null}
            </KPIComponentRow>
          );
        })}
      </KPIRow>
      <Tooltip id={kpiId} />
    </KPIWrapperMainContainer>
  );
};
