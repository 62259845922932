import { useState, useEffect, useCallback, useRef } from "react";

import {
  TooltipBlock,
  TooltipColumn,
  TooltipContainer,
  TooltipHeading,
  TooltipLabel,
  TooltipValue,
} from "./styles";

export interface TooltipProps {
  data: { [key: string]: string };
  xAxe?: string;
  yAxe?: string;
  name?: string;
  children?: any;
  allLables?: any[];
  matrix?: boolean;
  coords?: {
    pageX?: number;
    pageY?: number;
    clientX?: number;
    clientY?: number;
    targetX?: number;
    targetY?: number;
  };
}

export const Tooltip = ({
  data,
  xAxe = "",
  yAxe = "",
  name,
  children,
  allLables,
  matrix,
  coords,
}: TooltipProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipWidth = tooltipRef.current?.clientWidth || 0;
  const tooltipHeight = tooltipRef.current?.clientHeight || 0;

  const { pageX = 0, pageY = 0, clientX = 0, clientY = 0 } = coords || {};

  const triangleHeight = 8;
  const triangleWidth = 10;
  const defaultTriangleOffsetX = 25;
  const defaultTriangleOffsetY = "100%";
  const defaultTriangleSide = "bottom";

  const offsetX = defaultTriangleOffsetX;
  const offsetY = triangleHeight;
  const tooltipEdgeSpacing = 5;

  const [position, setPosition] = useState({
    top: pageY,
    left: pageX - offsetX,
  });
  const [trianglePosition, setTrianglePosition] = useState({
    left: `${defaultTriangleOffsetX}px`,
    top: defaultTriangleOffsetY,
    side: defaultTriangleSide,
  } as any);

  const calcPointerPosition = useCallback(() => {
    let newX = pageX - offsetX;
    let newY = pageY - tooltipHeight - offsetY;
    let isAtEdge = false;
    let triangleLeftPosition = defaultTriangleOffsetX;
    let triangleTopPosition = defaultTriangleOffsetY;
    let triangleSide = defaultTriangleSide;

    // Horizontal edge detection
    if (clientX + tooltipWidth + tooltipEdgeSpacing >= window.innerWidth) {
      newX = window.innerWidth - tooltipWidth - tooltipEdgeSpacing - offsetX;
      isAtEdge = true;
    } else if (clientX - offsetX <= tooltipEdgeSpacing) {
      newX = tooltipEdgeSpacing;
      isAtEdge = true;
    }

    // Vertical edge detection
    if (tooltipHeight + triangleHeight + tooltipEdgeSpacing >= clientY) {
      newY = pageY + triangleHeight;
      triangleTopPosition = `${-triangleHeight}px`;
      triangleSide = "top";
    }

    // Triangle edge detection
    if (isAtEdge) {
      triangleLeftPosition = Math.min(
        Math.max(clientX - newX, tooltipEdgeSpacing + triangleWidth / 2),
        tooltipWidth - tooltipEdgeSpacing - triangleWidth / 2
      );
    }

    setTrianglePosition({
      left: `${triangleLeftPosition}px`,
      top: triangleTopPosition,
      side: triangleSide,
    });

    setPosition({ top: newY, left: newX });
  }, [
    pageX,
    offsetX,
    pageY,
    tooltipHeight,
    offsetY,
    clientX,
    tooltipWidth,
    clientY,
  ]);

  useEffect(() => {
    calcPointerPosition();
  }, [calcPointerPosition]);

  const xAxeLabel = xAxe?.charAt(0)?.toUpperCase() + xAxe?.slice(1);
  const existLabels = Object.keys(data);

  const renderValue = () => {
    if (yAxe === "value") {
      const value = parseFloat(data[yAxe]);
      const hasDecimals = value % 1 !== 0;

      if (hasDecimals) {
        return value?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return value?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return data[yAxe];
  };

  const key = matrix ? data[yAxe] : data[xAxeLabel.toLowerCase()];

  const excludedLabelKeys: string[] = [];
  if (yAxe === "value") {
    excludedLabelKeys.push("value");
  }
  if (name) {
    excludedLabelKeys.push(name);
  }
  if (key) {
    excludedLabelKeys.push(key);
  }
  if (!((data[xAxe] === key || data[xAxe] === name) && isNaN(Number(key)))) {
    excludedLabelKeys.push(xAxe);
  }

  const otherLabels = existLabels?.filter(
    (r) => !excludedLabelKeys.includes(r)
  );
  const groupLabels = otherLabels?.map((r: string) => r?.replace("_", " "));

  return (
    <TooltipContainer
      ref={tooltipRef}
      style={{ top: position.top, left: position.left }}
      $triangleLeft={trianglePosition.left}
      $triangleTop={trianglePosition.top}
      $triangleSide={trianglePosition.side}
    >
      <TooltipBlock>
        {(!!name || !!key) && (
          <TooltipColumn>
            <TooltipHeading>{name || key}</TooltipHeading>
          </TooltipColumn>
        )}

        {yAxe === "value" && (
          <TooltipColumn $border>
            <TooltipLabel>Members</TooltipLabel>
            <TooltipValue>{renderValue()}</TooltipValue>
          </TooltipColumn>
        )}

        {!(
          (data[xAxe] === key || data[xAxe] === name) &&
          isNaN(Number(key))
        ) && (
          <TooltipColumn $border>
            <TooltipLabel>{xAxeLabel}</TooltipLabel>
            <TooltipValue>{data[xAxe]}</TooltipValue>
          </TooltipColumn>
        )}

        {otherLabels?.length
          ? otherLabels?.map((l: string, i: number) => {
              return data[l] === key || data[l] === name ? null : (
                <TooltipColumn key={l} $border>
                  <TooltipLabel>{groupLabels[i]}</TooltipLabel>
                  <TooltipValue>{data[l]}</TooltipValue>
                </TooltipColumn>
              );
            })
          : null}

        {children}
      </TooltipBlock>
    </TooltipContainer>
  );
};
