import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllComments } from "../../../../store/selectors/comments";
import { Message } from "./components/Message/Message";
import { CommentHead } from "./components/CommendHead/CommendHead";
import * as s from "./styles";
import {
  requestFetchComments,
  requestReadComments,
  requestResolveComments,
  setOpenComments,
  setSelectWidgetId,
} from "../../../../store/slices/commnets";
import { calculateTimeElapsed } from "../../../../helpers/calculateTimeElapsed";
import {
  getCurrentProjectId,
  getPageId,
} from "../../../../store/selectors/projects";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import { useNavigate } from "react-router-dom";
import { getIsPublicMode } from "../../../../store/selectors/main";
import { Comment } from "../../../../types/comments";


interface Props {
  setShowAlert2: React.Dispatch<React.SetStateAction<boolean>>;
}

export const sortOptions = [
  {
    label: "Unread",
    value: "isRead",
  },
  {
    label: "Date",
    value: "createdAt",
  },
];

export const DiscussionMain: React.FC<Props> = ({ setShowAlert2 }) => {
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [activeTab, setActiveTab] = useState(false);

  const comments = useSelector(getAllComments);
  const dispatch = useDispatch();
  const projectId = useSelector(getCurrentProjectId);
  const currentPageId = useSelector(getPageId);
  const isPublicRoute = useSelector(getIsPublicMode);
  const navigate = useNavigate();

  const [itemRef] = useClickOutside(setShowAlert2, false);

  useEffect(() => {
    if (!projectId || isPublicRoute) return;
    dispatch(
      requestFetchComments({
        projectId,
        orderBy: selectedSort.value,
        orderDirection: "DESC",
      })
    );
  }, [dispatch, isPublicRoute, projectId, selectedSort]);

  const handleCloseComments = () => {
    dispatch(setOpenComments());
  };

  const handleResolve = (comment: Comment, isResolved: boolean) => () => {
    const ids = (comment?.replies || []).map(({ id }) => id);
    dispatch(requestResolveComments({ ids: [comment.id, ...ids], isResolved: !isResolved }));
  };

  const handleActions = (id: string, key: string) => {
    if (key === "markAsRead") {
      dispatch(requestReadComments({ ids: [id], isRead: true }));
    }
  };

  const handleCommentSelect = (widgetId: string, pageId: string) => {
    if (currentPageId !== pageId) {
      const path = `/projects/${projectId}/${pageId}`;
      navigate(path);
    }
    dispatch(setSelectWidgetId(widgetId));
  };

  return (
    <s.CommentsWrapper ref={itemRef}>
      <CommentHead
        setSelectedSort={setSelectedSort}
        selectedSort={selectedSort}
        setShowAlert2={setShowAlert2}
        onClose={handleCloseComments}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />
      <s.Comments>
        <s.MessagesContainer>
          {comments
            .filter((comment) => comment.isResolved === activeTab)
            .map((comment) => (
              <Message
                key={comment.id}
                author={comment?.author?.name}
                avatarUrl={comment.image}
                time={calculateTimeElapsed(comment.createdAt)}
                message={comment.content}
                isYou={comment?.author?.role === "owner"}
                isResolved={comment.isResolved}
                handleResolve={handleResolve(comment, comment.isResolved)}
                replies={comment?.replies}
                isRead={comment.isRead}
                handleActions={(key) => handleActions(comment.id, key)}
                onSelect={() =>
                  handleCommentSelect(comment.widgetId, comment.pageId)
                }
              />
            ))}
        </s.MessagesContainer>
      </s.Comments>
    </s.CommentsWrapper>
  );
};
