import styled from "styled-components";

export const EmptyPageWrapper = styled.div`
  display: flex;
  height: calc(100dvh - 82px - 64px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 443px;
  margin: 24px 0;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
`;
