import React, { useState } from "react";
import { CommentsButton, CommentsWrapper } from "./styles";
import { CommentsIcon, DiscussionThread } from "./components";
import { useSelector } from "react-redux";
import {
  getGroupedComments,
  getSelectedWidgetId,
} from "../../store/selectors/comments";
import { useDispatch } from "react-redux";
import { clearSelectCommentsId } from "../../store/slices/commnets";
import { getIsPublicMode } from "../../store/selectors/main";
import { useSyncedState } from "../../hooks/useSyncedState";

interface WrapperProps {
  id: string;
  isStorytelling?: boolean;
  isComparison?: boolean;
  isReports?: boolean;
}

const Comments: React.FC<WrapperProps> = ({
  id,
  isStorytelling,
  isComparison,
  isReports,
}) => {
  const [selected, setSelected] = useState<string | null>();
  const [isHidden] = useSyncedState("isHiddenCommentIds", false);

  const selectedWidgetId = useSelector(getSelectedWidgetId);
  const groupedComments = useSelector(getGroupedComments);
  const isPublicMode = useSelector(getIsPublicMode);

  const dispatch = useDispatch();

  const grouped = groupedComments.find(
    (comment) => comment.widgetId === id
  );

  const commentsPerWidget = grouped && {...grouped, comments: grouped?.comments.filter((comment) => !comment.isResolved)}

  const hasReadComments = commentsPerWidget?.comments?.some(
    (comment) => comment.isRead === true || comment?.author?.role === "owner"
  );

  const handleClick = (id: string | null) => () => {
    if (!id) {
      dispatch(clearSelectCommentsId());
    }
    setSelected(id);
  };


  if (isHidden || isPublicMode || (!commentsPerWidget?.comments.length && id !== selectedWidgetId)) {
    return null;
  }

  return (
    <CommentsWrapper
      $isStorytelling={isStorytelling}
      $isComparison={isComparison}
      $isReports={isReports}
    >
      <CommentsButton onClick={handleClick(id)}>
        <CommentsIcon
          number={commentsPerWidget?.comments.length || 0}
          isActive={selected === id}
          isRead={hasReadComments}
        />
      </CommentsButton>
      {(selected === id || selectedWidgetId === id) && (
        <DiscussionThread
          isStorytelling={isStorytelling}
          commentsPerWidget={commentsPerWidget}
          onClose={handleClick(null)}
        />
      )}
    </CommentsWrapper>
  );
};

export default Comments;
