import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FirstPage } from "./components/FirstPage";
import { LastPage } from "./components/LastPage";
import { ReportContent } from "./components/ReportContent";
import { downloadPDF } from "../../helpers/generatePDF";
import {
  resetPDFGenerationTrigger,
  setPDFGenerationProgress,
} from "../../store/slices/pdfSlice";
import {
  documentName,
  pdfGenerationTrigger,
  pdfGenerationProgress,
} from "../../store/selectors/reportPDF";
import * as s from "./styled";
import { getPageBanner, getPageSettings } from "../../store/selectors/projects";
import MiniatureNavigation from "./Miniature";
import { useSyncedState } from "../../hooks/useSyncedState";
import { defaultHeader } from "../AddStorytellingSection";
import { IPageHeader } from "../../store/slices/pageContentGenerator";
import { WidgetItem } from "../../models/Widgets";
import { useChartWidgetsReorder } from "./hooks/useChartWigetsReorder";
import { requestPageWidgets, requestUpdatePage } from "../../store/slices/projectPages";
import { requestDeleteWidgetWithCallback } from "../../store/slices/widgets";
import { ReportConfirm } from "../Modals/ReportConfirm";

interface Props {
  loading: boolean;
}

export const ReportMain: FC<Props> = ({ loading }) => {
  const [file, setFile] = useState();
  const [localItems, setLocalItems] = useState<WidgetItem[]>([]);
  const [isRemoveCover, setRemoveCover] = useState(false);
  const header = useSelector(getPageBanner);

  const [contentItem, setContentItem] = useState<IPageHeader | undefined>(
    header || defaultHeader
  );
  const name = useSelector(documentName);
  const pdfTrigger = useSelector(pdfGenerationTrigger);
  const progress = useSelector(pdfGenerationProgress);
  const currentPage = useSelector(getPageSettings);
  const containerRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isHidden, setIsHidden] = useSyncedState("isHiddenCommentIds", false);

  const { handleDragEnd, groupItems } = useChartWidgetsReorder()

  const groupedItems = groupItems(localItems);


  const dispatch = useDispatch();

  const updateLoader = useCallback(
    (progress: number) => {
      dispatch(setPDFGenerationProgress(progress));
    },
    [dispatch]
  );

  const handleGeneratePDF = useCallback(() => {
    if (containerRef.current) {
      downloadPDF(containerRef, name, updateLoader);
    }
  }, [name, updateLoader]);

  const handleRemove = (index: number, lastIndex: number) => {
    if (currentPage.showHeader && (index === 0 || index === lastIndex)) {
      setRemoveCover(true)
      return;
    }
    if (currentPage.tableOfContents && ((currentPage.showHeader && index === 1) || (!currentPage.showHeader && index === 0))) {
      dispatch(requestUpdatePage({
        ...currentPage,
        tableOfContents: false,
      }))
      return;
    }

    const convertIndex = (index: number): number => {
      const offset = Number(currentPage.tableOfContents) + Number(currentPage.showHeader);
      return index - offset;
    };
  
    (groupedItems?.[convertIndex(index)] || []).forEach((widget) => {
      dispatch(
        requestDeleteWidgetWithCallback({ id: widget.id, callbacks: {
          onSuccess: () => {
            dispatch(
              requestPageWidgets({
                pageId: currentPage.id!,
                includeData: true,
              })
            );
          },
        }, })
      );
    })
  }

  useEffect(() => {
    if (progress > 5) {
      setIsHidden(true);
    }
    if (progress === 100) {
      setIsHidden(false);
    }
  }, [progress, setIsHidden]);

  useEffect(() => {
    if (pdfTrigger) {
      handleGeneratePDF();
      dispatch(resetPDFGenerationTrigger());
    }
  }, [pdfTrigger, dispatch, handleGeneratePDF]);

  return (
    <s.Container>
      <s.Wrapper>
        <s.ProgressBarContainer>
          <s.ProgressBar width={progress} />
        </s.ProgressBarContainer>
        <s.PageContainer className={progress > 0 ? 'generating-pdf' : ''} ref={containerRef} id="page-container">
          {currentPage.showHeader && (
            <FirstPage
              file={file}
              setFile={setFile}
              contentItem={contentItem}
              setContentItem={setContentItem}
            />
          )}
          <ReportContent
            groupedItems={groupedItems}
            setLocalItems={setLocalItems}
          />
          {currentPage.showHeader && (
            <LastPage file={file} contentItem={contentItem} />
          )}
        </s.PageContainer>
      </s.Wrapper>
      <MiniatureNavigation
        onReorder={(source, destination) =>
          handleDragEnd(groupedItems, source, destination)
        }
        onRemove={handleRemove}
        containerRef={containerRef}
        loading={loading}
      />
      {isRemoveCover && <ReportConfirm onClose={() => setRemoveCover(false)} />}
    </s.Container>
  );
};


