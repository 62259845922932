import React, { useEffect, useState } from "react";
import {
  ButtonsWrapper,
  Close,
  Container,
  DropDownMenu,
  FilterWrapper,
  SearchInput,
  SearchWrapper,
  Separator,
  ValueItem,
  ValuesWrapper,
} from "./styles";
import { Filter } from "react-bootstrap-icons";
import {
  ManagementUserList,
  SortedColumn,
} from "../../pages/UserManagment/pages/Users/data/mock";
import { Search } from "../SearchBar";
import { CheckBox } from "../Inputs/CustomCheckbox/CheckBox";
import { IActiveFilters } from "../UserManagementComponents/Users/UsersList";
import { applyFilters } from "./utils/filters";

type Props = {
  keyName: SortedColumn;
  allUsersList: ManagementUserList[];
  disabled?: boolean;
  filter?: boolean;
  setIsOpenFilter: React.Dispatch<
    React.SetStateAction<keyof ManagementUserList | null>
  >;
  isOpenFilter: SortedColumn | null;
  activeFilters: IActiveFilters;
  setActiveFilters: React.Dispatch<React.SetStateAction<IActiveFilters>>;
};

const UserManagementFilter = ({
  keyName,
  allUsersList,
  disabled,
  filter = true,
  setIsOpenFilter,
  isOpenFilter,
  activeFilters,
  setActiveFilters,
}: Props) => {
  const allUniqueValues = [
    ...new Set(
      allUsersList.flatMap((item) => {
        const value = item[keyName];
        return Array.isArray(value) ? value.map(String) : [String(value)];
      })
    ),
  ];

  const uniqueValues = [
    ...new Set(
      applyFilters({
        skip: keyName,
        activeFilters,
        allUsersList,
      }).flatMap((item) => {
        const value = item[keyName];
        return Array.isArray(value) ? value.map(String) : [String(value)];
      })
    ),
  ];

  const [value, setValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");

  const [selectedUniqueValues, setSelectedUniqueValues] =
    useState<string[]>(uniqueValues);

  const filtredUniqueValuesList = uniqueValues.filter((item) =>
    item
      .toLocaleString()
      .toLowerCase()
      .match(searchValue.toLocaleString().toLowerCase())
  );

  const updateFilterValues = (updatedValues: string[]) => {
    const allActiveFilters = Object.keys(activeFilters);
    const existCurrentFilter = allActiveFilters.some(
      (item) => item === keyName
    );
    const existAllUniqueValuesInUpdatedData = uniqueValues.every((value) =>
      updatedValues.includes(value)
    );

    if (existCurrentFilter) {
      if (existAllUniqueValuesInUpdatedData) {
        const newFilter = Object.fromEntries(
          Object.entries(activeFilters).filter(([key]) => key !== keyName)
        );
        setActiveFilters(newFilter);
        return;
      }

      setActiveFilters((prev) => ({
        ...prev,
        [keyName]: {
          ...prev[keyName],
          filter: updatedValues,
        },
      }));
    } else {
      setActiveFilters((prev) => ({
        ...prev,
        [keyName]: {
          filter: updatedValues,
          savedUniqueValues: uniqueValues,
          updatedUniqueValues: uniqueValues,
        },
      }));
    }
  };

  const updateFilterSearch = (value: string) => {
    const allActiveFilters = Object.keys(activeFilters);
    const existCurrentFilter = allActiveFilters.some(
      (item) => item === keyName
    );

    if (existCurrentFilter) {
      if (!value && !activeFilters[keyName].filter) {
        const newData = Object.fromEntries(
          Object.entries(activeFilters).filter(([key]) => key !== keyName)
        );
        setActiveFilters(newData);
        return;
      }

      setActiveFilters((prev) => ({
        ...prev,
        [keyName]: { ...prev[keyName], search: value },
      }));
    } else {
      if (!value) {
        return;
      }

      setActiveFilters((prev) => ({
        ...prev,
        [keyName]: {
          search: value,
        },
      }));
    }
  };

  const handleSelectAll = () => {
    if (searchValue) {
      const ifAllExist = filtredUniqueValuesList.every((item) =>
        selectedUniqueValues.includes(item)
      );

      if (ifAllExist) {
        const filterWithoutSelectedData = selectedUniqueValues.filter(
          (item) => !filtredUniqueValuesList.includes(item)
        );

        setSelectedUniqueValues(filterWithoutSelectedData);
        updateFilterValues(filterWithoutSelectedData);
      } else {
        const combineSelectedValues = [
          ...new Set([...filtredUniqueValuesList, ...selectedUniqueValues]),
        ];
        setSelectedUniqueValues(combineSelectedValues);
        updateFilterValues(combineSelectedValues);
      }

      return;
    }

    if (uniqueValues.length > selectedUniqueValues.length) {
      setSelectedUniqueValues(allUniqueValues);
      updateFilterValues(allUniqueValues);
    } else {
      setSelectedUniqueValues([]);
      updateFilterValues([]);
    }
  };

  const handleSelectItem = (value: string) => {
    const updatedValues = selectedUniqueValues.includes(value)
      ? selectedUniqueValues.filter((item) => item !== value)
      : [...selectedUniqueValues, value];

    updateFilterValues(updatedValues);
    setSelectedUniqueValues(updatedValues);
  };

  useEffect(() => {
    updateFilterSearch(value);
  }, [value]);

  return (
    <Container $disabled={disabled || !!activeFilters[keyName]?.filter}>
      <SearchInput
        disabled={disabled || !!activeFilters[keyName]?.filter}
        placeholder={
          !!activeFilters[keyName]?.filter
            ? "Filter is active"
            : "Type to filter"
        }
        value={!!activeFilters[keyName]?.filter ? "Filter is active" : value}
        onChange={(e) => setValue(e.target.value)}
      />

      <ButtonsWrapper>
        {!!value && <Close onClick={() => setValue("")} />}
        {filter && (
          <>
            <Separator />
            <FilterWrapper
              $active={!!activeFilters[keyName]}
              onClick={() =>
                setIsOpenFilter((prev) => (prev === keyName ? null : keyName))
              }
            >
              <Filter />
            </FilterWrapper>
          </>
        )}
      </ButtonsWrapper>

      {isOpenFilter === keyName && (
        <DropDownMenu>
          <SearchWrapper>
            <Search width={"xs"} onChange={(value) => setSearchValue(value)} />
          </SearchWrapper>
          <ValuesWrapper>
            <ValueItem
              $selected={filtredUniqueValuesList.every((item) =>
                selectedUniqueValues.includes(item)
              )}
            >
              <CheckBox
                onChange={handleSelectAll}
                checked={filtredUniqueValuesList.every((item) =>
                  selectedUniqueValues.includes(item)
                )}
                status={
                  filtredUniqueValuesList.every((item) =>
                    selectedUniqueValues.includes(item)
                  )
                    ? "checked"
                    : filtredUniqueValuesList.filter((item) =>
                        selectedUniqueValues.includes(item)
                      ).length
                    ? "indeterminate"
                    : "none"
                }
              />
              Select All
            </ValueItem>
            {filtredUniqueValuesList.map((item) => {
              const isSelected = selectedUniqueValues.includes(item);
              return (
                <ValueItem $selected={isSelected}>
                  <CheckBox
                    onChange={() => handleSelectItem(item)}
                    checked={isSelected}
                    status={isSelected ? "checked" : "none"}
                  />
                  {item}
                </ValueItem>
              );
            })}
          </ValuesWrapper>
        </DropDownMenu>
      )}
    </Container>
  );
};

export default UserManagementFilter;
