import { WidgetItem } from "../../../../models/Widgets";

interface chartData {
  [key: string]: any;
}

export const getGroupedData = (
  currentWidgetData?: WidgetItem,
  chartSuggestion?: any
): Record<string, chartData[]> => {
  const groupBy = currentWidgetData?.groupBy?.[0];
  const xAxe = currentWidgetData?.xAxe?.[0] ?? chartSuggestion?.xAxe?.[0];
  const yAxe = currentWidgetData?.yAxe?.[0] ?? chartSuggestion?.yAxe?.[0];

  if (!currentWidgetData?.data) {
    return { default: [] };
  }

  if (groupBy) {
    return currentWidgetData.data.reduce(
      (acc: Record<string, chartData[]>, d: chartData) => {
        const key = d[groupBy];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push({
          [xAxe]: d[xAxe],
          [yAxe]: d[yAxe],
        });
        return acc;
      },
      {}
    );
  } else {
    return {
      default: currentWidgetData.data.map((d: chartData) => ({
        [xAxe]: d[xAxe],
        [yAxe]: d[yAxe],
      })),
    };
  }
};
