import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import * as s from "./styles";
import { getMeasure, getPopulations, MeasuresType } from "../utils";
import { useSelector } from "react-redux";
import { getCurrentProject } from "../../../../store/selectors/projects";
import { getAllDatasets } from "../../../../store/selectors/datasets";
import RadioButton from "../../../Inputs/CustomRadio/RadioButton";
import { useDispatch } from "react-redux";
import { requestGetAllDatasets } from "../../../../store/slices/datasets";

type FormContentProps = {
  selectedOption: string | null;
  setSelectedOption: Dispatch<SetStateAction<string | null>>;
  selectedChip: string | null;
  setSelectedChip: Dispatch<SetStateAction<string | null>>;
};

export const FormContent: React.FC<FormContentProps> = ({
  selectedOption,
  setSelectedOption,
  selectedChip,
  setSelectedChip,
}) => {
  const dispatch = useDispatch();
  const projectData = useSelector(getCurrentProject);
  const allDatasets = useSelector(getAllDatasets);
  const [allMeasures, setAllMeasures] = useState<MeasuresType[]>([]);

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!process.env.REACT_APP_DATA_SET_URL) {
          return;
        }
        if (!allDatasets.items?.length) {
          return;
        }
        const response = await fetch(process.env.REACT_APP_DATA_SET_URL);
        if (!response.ok) {
          return;
        }
        const data = await response.json();

        const datasetName = projectData.projectData.datasets.at(0)?.name;
        const datasetId = allDatasets.items.find(
          (item) => item.name === datasetName
        ).id;

        const measures: MeasuresType[] =
          data && datasetId ? data[datasetId] : [];

        setAllMeasures(measures ?? []);
      } finally {
      }
    };

    fetchData();
  }, [projectData.projectData.datasets, allDatasets.items]);

  return (
    <s.FormWrapper>
      <s.FormGroup>
        <s.Label>Population</s.Label>
      </s.FormGroup>
      <s.ChipContainer>
        {getPopulations(allMeasures).map((population) => (
          <s.Chip
            key={population.bucket}
            $selected={selectedChip === population.bucket}
            onClick={() => {
              setSelectedChip(population.bucket);
              setSelectedOption(null);
            }}
          >
            {population.bucket} <span>{population.count}</span>
          </s.Chip>
        ))}
      </s.ChipContainer>
      <s.OptionsContainer>
        {getMeasure(allMeasures, selectedChip).map((option) => (
          <s.Option
            $selected={selectedOption === option.id}
            key={option.id}
            onClick={() => setSelectedOption(option.id)}
          >
            <s.RadioButtonWrapper>
              <RadioButton
                name={"Measures"}
                onChange={() => setSelectedOption(option.id)}
                value={option.id}
                checked={selectedOption === option.id}
              />
            </s.RadioButtonWrapper>
            <s.Label htmlFor={option.id}>
              {option.id}
              <p className="font-normal text-sm text-gray-500">
                {option.measureDescriptionDesktop}
              </p>
            </s.Label>
          </s.Option>
        ))}
      </s.OptionsContainer>
    </s.FormWrapper>
  );
};
