import styled from "styled-components";

export const Line = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.neutralBorder};
  height: 1px;
  border-radius: 1px;
  margin: 5px 0px; 
`
export const SubMenuWrapper = styled.ul<{
  $open: boolean;
}>`
  position: absolute;
  top: 100%;
  right: 0;

  background: ${({ theme }) => theme.neutralBackgroundBase};
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  width: 184px;
  border-radius: 6px;
  z-index: 4;
  box-shadow: rgba(99, 99, 99, 0.2) 0 0 5px 0;
  opacity: ${({ $open }) => ($open ? 1 : 0)};
  
  
  li {
    list-style: none;
    cursor: pointer;
    padding: 8px 4px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.neutralText};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.1s ease-in-out;
    gap: 4px;

    &:hover {
      background: ${({ theme }) => theme.neutralBackgroundHover};
    }
    &:active {
      background: ${({ theme }) => theme.neutralBackgroundHover};
    }

    svg {
      height: 16px;
      width: 16px;
    }

    &:last-child {
      color: ${({ theme }) => theme.alertText};
    }
  }
`;