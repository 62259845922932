import {
  IFormatting,
  IMarkersType,
  WidgetItem,
} from "../../../../models/Widgets";

export const AreaChartGroupedData = (
  currentWidgetData?: WidgetItem,
  areaChartSuggestion?: any
) => {
  const data = currentWidgetData?.data || [];
  const groupBy = currentWidgetData?.groupBy?.[0];

  const xAxe = currentWidgetData?.xAxe?.[0] ?? areaChartSuggestion?.xAxe?.[0];

  const yAxe = currentWidgetData?.yAxe?.[0] ?? areaChartSuggestion?.yAxe?.[0];

  const xAxes =
    currentWidgetData?.uniqueValues?.[xAxe] ||
    Array.from(new Set(data.map((d: any) => String(d[xAxe])))) ||
    [];

  const unsortedGroupedData = data?.reduce((acc, obj) => {
    const group = groupBy ? obj[groupBy] : "default";

    if (!acc[group]) {
      acc[group] = [];
    }

    acc[group].push({
      x: String(obj[xAxe] ?? obj.year),
      y: obj[yAxe] ?? obj.value,
    });

    return acc;
  }, {});

  const sortedGroupedData = Object.keys(unsortedGroupedData).reduce(
    (acc: any, curr: string) => {
      const dataMap = new Map(
        unsortedGroupedData[curr].map((d: any) => [d.x, d])
      );

      acc[curr] = xAxes.map((key: string) => dataMap.get(key)).filter(Boolean);

      return acc;
    },
    {}
  );

  return sortedGroupedData;
};

export const AreaChartDefaultMarkers = (data: any): IMarkersType[] => {
  const groupedData = AreaChartGroupedData(data);
  // @ts-ignore need to be fixed
  const markers: IMarkersType[] = Object.keys(groupedData).map(
    (item, index) => ({
      key: item,
      shape: "donut",
    })
  );

  return markers;
};

export const getAreaChartDefaultColors = (data: any): IFormatting[] => {
  const groupedData = AreaChartGroupedData(data);
  // @ts-ignore need to be fixed
  const colors: IFormatting[] = Object.keys(groupedData).map((item, index) => ({
    key: item,
    color: String(index + 1),
  }));

  return colors;
};
