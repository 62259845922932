import { Paragraph, TableHeaderElement } from "./style";
import { ThreeDots } from "react-bootstrap-icons";

type THProps = {
  text: string;
  size?: string;
  onClick?: (e: React.MouseEvent) => void; // Add onClick prop
};

export const THElement = ({ text, size, onClick }: THProps) => {
  return (
    <TableHeaderElement $size={size} >
      <Paragraph>{text}</Paragraph>
      <ThreeDots onClick={(e) => {e.stopPropagation(); e.preventDefault(); onClick?.(e)}} style={{ fill: "#5F6877" }} />
    </TableHeaderElement>
  );
};
