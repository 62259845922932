import { WidgetItem } from "../../../../models/Widgets";

export const PunchcardChartGroupedData = (
  currentWidgetData?: WidgetItem,
  punchcardChartSuggestion?: any
) => {
  const groupBy =
    currentWidgetData?.groupBy?.[0] ??
    Object.keys(currentWidgetData?.uniqueValues ?? {})[0];

  const xAxe =
    currentWidgetData?.xAxe?.[0] ?? punchcardChartSuggestion?.xAxe?.[0];
  const yAxe =
    currentWidgetData?.yAxe?.[0] ?? punchcardChartSuggestion?.yAxe?.[0];

  const mapData = (data: any) =>
    data.map((item: any) => ({
      x: item[xAxe] ?? item.year,
      y: item[yAxe] ?? item.value,
    }));

  if (groupBy) {
    return currentWidgetData?.data?.reduce((acc, obj) => {
      const group = obj[groupBy];
      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push({
        x: obj[xAxe] ?? obj.year,
        y: obj[yAxe] ?? obj.value,
      });
      return acc;
    }, {});
  } else {
    return {
      default: mapData(currentWidgetData?.data ?? []),
    };
  }
};
