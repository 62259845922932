import React from "react";
import { mockDataUsersList } from "../Users/data/mock";
import UsersList from "../../../../components/UserManagementComponents/Users/UsersList";

type Props = {};

const TeamsById: React.FC<Props> = (props) => {
  return (
    <div>
      <UsersList mockDataUsersList={mockDataUsersList.slice(0, 10)} teamTable />
    </div>
  );
};

export default TeamsById;
