import React, { useEffect, useState } from "react";
import { THElement } from "../../components/CreateProjectDataTable/components/THElement";
import { TRElement } from "../../components/CreateProjectDataTable/components/TRElement";
import { thirdStepTH } from "../../../../../../constants/measure";
import { useDispatch, useSelector } from "react-redux";
import { getDatasetContents } from "../../../../../../store/selectors/datasets";
import {
  CloseIcon,
  ContainerBlock,
  ContainerHeader,
  ContainerHeaderRow,
  ContainerWrapper,
  DataSetblock,
  DatasetPreviewLoaderWrapper,
  HeadingIcon,
  HeadingText,
  Icon,
  ModalFooter,
  ModalHeading,
  ModalHeadingWrapper,
  Table,
  TableBody,
  TableColumn,
  TableHeader,
  TableHeading,
  TableRow,
  TableWrapper,
} from "./style";
import { getIconSrc } from "../../../../../../helpers/getIconType";
import { Search } from "../../../../../SearchBar";
import { ArrowRight, ChevronLeft } from "react-bootstrap-icons";
import {
  ModalFooterButtons,
  ModalFooterWrapper,
  Steps,
} from "../../../../styles";
import { Button } from "../../../../../Button";
import StepDot from "../../../../../Inputs/CustomStepDot/StepDot";
import { useDebounceCallback } from "usehooks-ts";
import { requestGetDatasetContents } from "../../../../../../store/slices/datasets";
import { Loader } from "../../../../../Loader";
import { MeasuresType } from "../../../../CreateComparison/utils";

type Props = {
  programSelected: string;
  populationSelected: string;
  measureSelected?: string;
  setMeasuresSet: any;
  handleOnClose: () => void;
  handleCreate: () => void;
  setActiveStep: (tab: number) => void;
  allMeasures: MeasuresType[];
};

const PreviewStep = ({
  populationSelected,
  measureSelected,
  setMeasuresSet,
  handleOnClose,
  handleCreate,
  setActiveStep,
  allMeasures,
  programSelected,
}: Props) => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState<string>("");
  const [localMeasure, setLocalMeasure] = useState<string | undefined>();
  const datasetContents = useSelector(getDatasetContents);
  const headers = datasetContents?.fields;
  const [totalMeasures, setMeasures] = useState<any[]>([]);
  const [currentMeasures, setCurrentMeasures] = useState<any[]>([]);
  const [search, setSearch] = useState<string | undefined>();
  const debounced = useDebounceCallback(setSearch, 500);
  const [selectedDatasetContents, setDatasetContents] = useState<any[]>([""]);
  const [skip, setSkip] = useState<number>(0);
  const [countItems, setCountItems] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const resetSearch = (value: string) => {
    setSkip(0);
    setDatasetContents([]);
    setCountItems(0);
    setSearchInput(value);
  };

  const debouncedSearch = useDebounceCallback(resetSearch, 500);

  useEffect(() => {
    const newValue = search?.toLowerCase();
    if (newValue) {
      const measures = totalMeasures?.filter((r) => {
        const measureDescriptionDesktop: string =
          r?.measureDescriptionDesktop?.toLowerCase();
        const id: string = r?.id?.toLowerCase();
        return (
          id?.includes(newValue) ||
          measureDescriptionDesktop?.includes(newValue)
        );
      });
      setCurrentMeasures(measures);
    } else {
      if (totalMeasures?.length) {
        setCurrentMeasures(totalMeasures);
      } else {
        const measures = allMeasures.filter(
          (m) => m.bucket === populationSelected
        );
        setMeasures(measures);
        setCurrentMeasures(measures);
      }
    }
  }, [allMeasures, populationSelected, search, totalMeasures]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      requestGetDatasetContents({
        id: programSelected!,
        limit: 20,
        skip,
        search: searchInput,
        query: JSON.stringify({ $match: { "data.measure": measureSelected } }),
        callbacks: {
          onSuccess: (data) => {
            if (data?.items) {
              setCountItems(data.count);
              if (localMeasure === measureSelected) {
                setDatasetContents((selectedDatasetContents) => [
                  ...selectedDatasetContents,
                  ...data.items,
                ]);
              } else {
                setDatasetContents([...data.items]);
              }
            }
            setLocalMeasure(measureSelected);
            setIsLoading(false);
          },
          onError: () => {
            setIsLoading(false);
          },
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, searchInput, dispatch, measureSelected]);

  const loadMore = () => {
    setSkip(skip + 20);
  };

  return (
    <ContainerWrapper>
      <ContainerBlock>
        <div>
          <ContainerHeader>
            <ContainerHeaderRow>
              <ChevronLeft
                style={{ cursor: "pointer" }}
                onClick={() => setActiveStep(3)}
              />
              <ModalHeading>Select Measure</ModalHeading>
            </ContainerHeaderRow>
            <ContainerHeaderRow>
              <Search width="xs" onChange={debounced} />
              <CloseIcon onClick={handleOnClose} />
            </ContainerHeaderRow>
          </ContainerHeader>
          <TableHeader>
            {thirdStepTH.map((item) => (
              <THElement text={item.text} key={item.text}></THElement>
            ))}
          </TableHeader>
          <TableBody>
            {currentMeasures?.map((item: any) => {
              return (
                <TRElement
                  key={item.id}
                  id={item.id}
                  header={item.id}
                  subHeader={item.measureDescriptionDesktop}
                  measures={0}
                  selected={measureSelected === item.id}
                  selectHandler={() => setMeasuresSet(item.id)}
                  fields={datasetContents?.fields}
                />
              );
            })}
          </TableBody>
        </div>
        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <Steps>
            <StepDot active={false} finished={true} />
            <StepDot active={false} finished={true} />
            <StepDot active={true} finished={false} />
          </Steps>

          <ModalFooterButtons>
            <Button
              name={"Create"}
              onClick={handleCreate}
              type="submit"
              variant={!measureSelected ? "disabled" : "secondary"}
              disabled={!measureSelected}
              size="medium"
              icon={<ArrowRight />}
            />
          </ModalFooterButtons>
        </ModalFooterWrapper>
      </ContainerBlock>
      <DataSetblock>
        <ModalHeadingWrapper>
          <ModalHeading>
            {measureSelected ||
              datasetContents?.name.charAt(0).toUpperCase()! +
                datasetContents?.name.slice(1, -4)}
          </ModalHeading>
          <ContainerHeaderRow>
            <Search
              width="xs"
              placeholder="Search"
              onChange={debouncedSearch}
            />
            <CloseIcon onClick={() => setActiveStep(3)} />
          </ContainerHeaderRow>
        </ModalHeadingWrapper>
        <TableWrapper
          $disableScroll={isLoading || !selectedDatasetContents.length}
        >
          <Table>
            {headers?.map((datasetItem: any, idx: number) => {
              return (
                <TableColumn key={idx}>
                  <TableHeading>
                    <HeadingIcon>
                      <Icon
                        src={getIconSrc({
                          type: datasetItem.type,
                          subtype: datasetItem.subtype,
                          state: "simple",
                        })}
                      />
                    </HeadingIcon>
                    <HeadingText>
                      {datasetItem.label?.charAt(0)?.toUpperCase()! +
                        datasetItem.label?.slice(1)}
                    </HeadingText>
                  </TableHeading>
                  {selectedDatasetContents
                    ?.slice(1)
                    .map((item: any, idx: number) => {
                      return (
                        <TableRow key={idx}>
                          {item[datasetItem?.key]
                            ? item[datasetItem?.key]
                            : "No data"}
                        </TableRow>
                      );
                    })}
                </TableColumn>
              );
            })}
          </Table>
          {isLoading || !selectedDatasetContents.length ? (
            <DatasetPreviewLoaderWrapper>
              {isLoading ? <Loader /> : "No Results"}
            </DatasetPreviewLoaderWrapper>
          ) : null}
          {countItems > selectedDatasetContents.length ? (
            <ModalFooter>
              <Button
                onClick={loadMore}
                variant={"primary"}
                size="xs"
                name={"Load More"}
              />
            </ModalFooter>
          ) : null}
        </TableWrapper>
      </DataSetblock>
    </ContainerWrapper>
  );
};

export default PreviewStep;
