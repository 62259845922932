import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  // ButtonsWrapper,
  Label,
  TeamContainer,
  Title,
  WrapperContent,
  ImageOverlay,
  ImageContainer,
  // Chip,
  TopWrapperContent,
  Dots,
  LabelsWrapper,
} from "./styles";
import { ProjectCardUsers } from "../../../ProjectCardUsers";
import { TeamItemProps } from "./teamItem.interface";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";
import { DropDown } from "../DropDown";
import { useDispatch } from "react-redux";
import { selectTeam } from "../../../../store/slices/userManagement";

export const TeamItem: React.FC<TeamItemProps> = ({
  users,
  name,
  id,
  Image,
  programs,
  color,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuRef = useRef<any>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  useOutsideClick(menuRef, () => {
    setMenuOpen(false);
  });

  return (
    <>
      <TeamContainer
        onClick={() => {
          navigate(`/management/teams/${id}`);
        }}
      >
        <ProjectCardUsers users={users} />
        {/* <ButtonsWrapper>
          <Chip>
            {role}
          </Chip>
        </ButtonsWrapper> */}
        <ImageContainer $blur={!Image} $fill={color}>
          <Image />
        </ImageContainer>
        <ImageOverlay />
        <WrapperContent>
          <TopWrapperContent>
            <LabelsWrapper>
              {programs.map((program) => {
                return <Label>{program}</Label>;
              })}
            </LabelsWrapper>
            <div ref={menuRef}>
              <Dots
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(selectTeam(id))
                  setMenuOpen(!menuOpen);
                }}
              />
              {menuOpen && <DropDown menuOpen={menuOpen} />}
            </div>
          </TopWrapperContent>
          <Title>{name}</Title>
        </WrapperContent>
      </TeamContainer>
    </>
  );
};
