import React from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  More,
  MoreDiv,
  ProjectCount,
  ProjectDescription,
  ProjectName,
  ProjectNameHeading,
  ProjectRole,
  ProjectRoleLabel,
  ProjectsCountLabel,
  ProjectsTableBody,
  ProjectsTableBodyRow,
  ProjectsTableContainer,
  ProjectsTableHead,
  ProjectsTableHeaderRow,
  ProjectsTableWrapper,
  UploadedOn,
  UploadedTime,
  UsersIcons,
} from "./styles";

import { getProjects } from "../../store/selectors/projects";
import { useSortByCreatedDate } from "../../hooks/useSortByDate";
import { EmptyProjects } from "../EmptyProjects";
import { setActiveModal } from "../../store/slices/modals";
import { ProjectItemI } from "../../models/Projects";
import { Chip } from "../ProfileSideBar/styles";
import { ThreeDots } from "react-bootstrap-icons";

export const ProjectsTable = () => {
  const { items: projects } = useSelector(getProjects);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { handleSortByCreatedDate, sortItemsByCreatedDate } =
    useSortByCreatedDate("desc");
  const sortedItems = sortItemsByCreatedDate(projects ? projects : []);

  const openCreateProjectModal = () =>
    dispatch(setActiveModal({ id: "createProjectModal" }));

  return (
    <ProjectsTableWrapper>
      {!sortedItems.length ? (
        <EmptyProjects
          openNewProjectModal={openCreateProjectModal}
        ></EmptyProjects>
      ) : (
        <ProjectsTableContainer>
          <ProjectsTableHead>
            <ProjectsTableHeaderRow>
              <ProjectNameHeading
                style={{
                  minWidth: "268px",
                  borderTopLeftRadius: "7px",
                  borderRight: "1px solid #DFE1E4",
                }}
              >
                Name
              </ProjectNameHeading>
              <th
                style={{ paddingLeft: "8px", borderRight: "1px solid #DFE1E4" }}
              >
                Description
              </th>
              <th
                style={{
                  paddingLeft: "8px",
                  minWidth: "96px",
                  borderRight: "1px solid #DFE1E4",
                }}
              >
                Pages
              </th>
              <th
                style={{
                  paddingLeft: "8px",
                  minWidth: "120px",
                  borderRight: "1px solid #DFE1E4",
                }}
              >
                Role
              </th>
              <th
                style={{
                  paddingLeft: "8px",
                  minWidth: "150px",
                  borderRight: "1px solid #DFE1E4",
                }}
              >
                Team
              </th>
              <th
                style={{
                  paddingLeft: "8px",
                  borderRight: "1px solid #DFE1E4",
                }}
              >
                {/* <UploadedOnIcon onClick={handleSortByCreatedDate} /> */}
                Updated on
              </th>
              <th
                style={{ minWidth: "32px", borderTopRightRadius: "7px" }}
              ></th>
            </ProjectsTableHeaderRow>
          </ProjectsTableHead>
          {sortedItems.map((project: ProjectItemI) => (
            <ProjectsTableBody
              key={project.id}
              onClick={() => navigate(`/projects/${project.id}`)}
            >
              <ProjectsTableBodyRow>
                <ProjectName>{project.name}</ProjectName>
                <ProjectDescription>{project.description}</ProjectDescription>
                <ProjectCount>
                  <ProjectsCountLabel>4</ProjectsCountLabel>
                </ProjectCount>
                <ProjectRole>
                  <ProjectRoleLabel $role={"owner"}>Owner</ProjectRoleLabel>
                </ProjectRole>
                <UsersIcons>
                  <Chip $selected={false}>Administratives</Chip>
                </UsersIcons>
                <UploadedOn>
                  {format(new Date(project.createdAt), "MM.dd.yyyy")}
                  <UploadedTime>
                    {format(new Date(project.createdAt), " hh:mm a")}
                  </UploadedTime>
                </UploadedOn>
                <More>
                  <MoreDiv>
                    <ThreeDots />
                  </MoreDiv>
                </More>
              </ProjectsTableBodyRow>
            </ProjectsTableBody>
          ))}
        </ProjectsTableContainer>
      )}
    </ProjectsTableWrapper>
  );
};
