import { Pencil, PlusLg, Trash } from "react-bootstrap-icons";

import { Line, SubMenuWrapper } from "./styles";
import { setActiveModal } from "../../../../store/slices/modals";
import { useDispatch } from "react-redux";

interface Props {
  menuOpen: boolean
}

export const DropDown: React.FC<Props> = ({ menuOpen }) => {
  const dispatch = useDispatch()
  return (
    <SubMenuWrapper
      $open={menuOpen}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <li
        onClick={() => {
          dispatch(setActiveModal({ id: 'editTeamModal' }))
        }}
      >
        <Pencil />
        Edit
      </li>
      <li
        onClick={() => { }}
      >
        <PlusLg />
        Invite People
      </li>
      {/* <li
        onClick={() => {}}
      >
        <Link45deg />
        Copy Link
      </li> */}
      <Line />
      <li
        onClick={() => { }}
      >
        <Trash />
        Remove
      </li>
    </SubMenuWrapper>
  )
}