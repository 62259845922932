import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getPageSettings } from "../../store/selectors/projects";
import { colorsPalettes } from "../../constants";
import { ColorRangeI } from "../../models/Pages";

import {
  CountLegendItems,
  CountLegendModal,
  LegendItemColor,
  LegendItemName,
  LegendItemWrapper,
  LegendPalette,
  LegendPaletteColor,
  LegendPaletteWrapper,
  LegendWrapper,
  UnitLegend,
} from "./styles";

export interface ChartLegendValue {
  label: string;
  color: string;
  key?: string;
}

interface ChartLegendInterface {
  legendType: "unit" | "palette";
  legendValues?: ChartLegendValue[];
  colorRanges?: ColorRangeI[];
  chartWidth?: number;
  isRight?: boolean;
  offset?: number;
}

export const ChartLegend = ({
  legendType,
  legendValues,
  colorRanges,
  chartWidth,
  isRight,
  offset,
}: ChartLegendInterface) => {
  const { styleId } = useSelector(getPageSettings);
  const colorPalette = colorsPalettes.find((palette) => palette.id === styleId);
  const unitColor2 = colorPalette?.colors[1].mainColor;
  const colorPaletteVariations = colorPalette?.colors.find(
    (color) => color.mainColor === unitColor2
  )?.variations;

  const [visibleValues, setVisibleValues] = useState<ChartLegendValue[]>([]);
  const [orderedLegendValues, setOrderedLegendValues] = useState<
    ChartLegendValue[]
  >([]);

  const calculateLabelWidth = (label: string): number => {
    return label.length * 7 + 17;
  };

  useEffect(() => {
    const priorityLabels = new Set(["Other", "All"]);
    const currentWidth = 40;
    const visibleValues: ChartLegendValue[] = [];

    const orderedValues: ChartLegendValue[] =
      legendValues?.slice().sort((a, b) => {
        const aPriority = priorityLabels.has(a.label) ? 1 : 0;
        const bPriority = priorityLabels.has(b.label) ? 1 : 0;
        return aPriority - bPriority;
      }) || [];

    setOrderedLegendValues(orderedValues);

    let accumulatedWidth = currentWidth;
    for (const item of orderedValues) {
      const labelWidth = calculateLabelWidth(item.label);

      if (accumulatedWidth + labelWidth >= chartWidth!) {
        break;
      }

      visibleValues.push(item);
      accumulatedWidth += labelWidth;
    }

    setVisibleValues(visibleValues);
  }, [legendValues, chartWidth]);

  return (
    <LegendWrapper $isRight={isRight} $offset={offset}>
      {legendType === "unit" && (
        <UnitLegend>
          {visibleValues?.map((legendValue, index) => {
            return (
              <LegendItemWrapper key={legendValue + String(index)}>
                <LegendItemColor $color={legendValue.color}></LegendItemColor>
                <LegendItemName>{legendValue.label}</LegendItemName>
              </LegendItemWrapper>
            );
          })}
          {orderedLegendValues?.length! > visibleValues?.length! && (
            <CountLegendItems>
              +{orderedLegendValues?.length! - visibleValues?.length!}
              <CountLegendModal>
                {orderedLegendValues?.map((legendValue, index) => {
                  if (!visibleValues?.includes(legendValue)) {
                    return (
                      <LegendItemWrapper key={legendValue + String(index)}>
                        <LegendItemColor
                          $color={legendValue.color}
                        ></LegendItemColor>
                        <LegendItemName>{legendValue.label}</LegendItemName>
                      </LegendItemWrapper>
                    );
                  }
                  return null;
                })}
              </CountLegendModal>
            </CountLegendItems>
          )}
        </UnitLegend>
      )}

      {legendType === "palette" && !colorRanges?.length ? (
        <LegendPaletteWrapper>
          Low
          <LegendPalette>
            {colorPaletteVariations?.map((color: string, index) => (
              <LegendPaletteColor
                $color={color}
                key={index}
              ></LegendPaletteColor>
            ))}
          </LegendPalette>
          High
        </LegendPaletteWrapper>
      ) : null}

      {legendType === "palette" && colorRanges?.length ? (
        <LegendPaletteWrapper>
          Low
          <LegendPalette>
            {colorRanges?.map((range: ColorRangeI, index) => (
              <LegendPaletteColor
                $color={range.color}
                key={index}
              ></LegendPaletteColor>
            ))}
          </LegendPalette>
          High
        </LegendPaletteWrapper>
      ) : null}
    </LegendWrapper>
  );
};
