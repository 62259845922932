import React from "react";
import {
  ButtonsWrapper,
  Chip,
  Container,
  More,
  SelectedTeam,
  SelectedText,
  SelectedUsers,
  Tab,
  Tabs,
  TeamName,
} from "./styles";
import { Button } from "../Button";
import { PlusLg, Trash, People, XLg, ArrowLeft } from "react-bootstrap-icons";
import { managementPaths } from "./data/paths";
import { useLocation } from "react-router-dom";
import { setActiveModal } from "../../store/slices/modals";

import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSelectedUsers } from "../../store/selectors/userManagement";
import { useDispatch } from "react-redux";
import { resetSelectedUsers } from "../../store/slices/userManagement";
import { teams } from "../../pages/UserManagment/pages/Teams";
import { toast } from "react-toastify";
type Props = {};

const UserManagementMenu: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { id } = useParams();

  const selectedUsers = useSelector(getSelectedUsers);

  const selectedUsersCount = selectedUsers.length;

  const resetAll = () => dispatch(resetSelectedUsers());
  return (
    <Container>
      {selectedUsersCount ? (
        <SelectedUsers>
          <XLg onClick={resetAll} />
          <SelectedText> {selectedUsersCount} selected </SelectedText>
        </SelectedUsers>
      ) : id ? (
        <SelectedTeam>
          <ArrowLeft onClick={() => navigate("/management/teams")} />
          <TeamName>{teams.find((item) => item.id === id)?.name}</TeamName>
          {teams
            .find((item) => item.id === id)
            ?.programs.map((program) => (
              <Chip>{program}</Chip>
            ))}
          <More />
        </SelectedTeam>
      ) : (
        <Tabs>
          {managementPaths.map((item) => (
            <Tab $selected={pathname === item.path} to={item.path}>
              {item.icon} {item.title}
            </Tab>
          ))}
        </Tabs>
      )}
      {pathname === "/management/users" && (
        <>
          {!!selectedUsersCount ? (
            <ButtonsWrapper>
              <Button
                onClick={() => {}}
                variant={"danger"}
                icon={<Trash />}
                name="Delete"
                size={"large"}
              />
              <Button
                onClick={() => {
                  if (selectedUsers.length >= 50) {
                    toast.warn("You can add maximum 50 members to team");
                    return;
                  }
                  dispatch(setActiveModal({ id: "addToTeamModal" }));
                }}
                variant={"secondary"}
                icon={<People />}
                name="Add to Teams"
                size={"large"}
              />
            </ButtonsWrapper>
          ) : (
            <Button
              onClick={() => {
                dispatch(setActiveModal({ id: "invitePeopleModal" }));
              }}
              variant={"secondary"}
              icon={<PlusLg />}
              name="Invite People"
              size={"large"}
            />
          )}
        </>
      )}

      {pathname.includes("/management/teams") && (
        <>
          {!selectedUsersCount ? (
            id ? (
              <Button
                onClick={() => {
                  dispatch(setActiveModal({ id: "inviteToTeamModal" }));
                }}
                variant={"secondary"}
                icon={<PlusLg />}
                name="Invite People"
                size={"large"}
              />
            ) : (
              <Button
                onClick={() => {
                  dispatch(setActiveModal({ id: "createTeamModal" }));
                }}
                variant={"secondary"}
                icon={<PlusLg />}
                name="Create Team"
                size={"large"}
              />
            )
          ) : (
            <Button
              onClick={() => {}}
              variant={"danger"}
              name="Remove from Team"
              size={"large"}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default UserManagementMenu;
