import { ManagementUserList, SortedColumn } from "../../../pages/UserManagment/pages/Users/data/mock";
import moment from 'moment';

export const sortTableDescending = (key: SortedColumn, usersData: ManagementUserList[], setUsersList: React.Dispatch<React.SetStateAction<ManagementUserList[]>>) => {
  setUsersList(() => {
    if(key === "projects") {
      return usersData.sort((a: ManagementUserList, b: ManagementUserList) => b.projects - a.projects)
    }
    if(key === "team") {
      return usersData.sort((a: any, b: any) => (b[key].length ? b[key].at(0) : "").localeCompare(a[key].length ? a[key].at(0) : ""))
    }
    if(key === "addedOn") {
      return usersData.sort((a: ManagementUserList, b: ManagementUserList) => {

        const a_data  = moment(a.addedOn, "DD.MM.YYYY hh:mm A").valueOf() / 1000;
        const b_data  = moment(b.addedOn, "DD.MM.YYYY hh:mm A").valueOf() / 1000;
        
        return b_data - a_data
      })
    }
      return usersData.sort((a: any, b: any) => b[key].localeCompare(a[key]))
    }
  )
}

export const sortTableAscending = (key: SortedColumn, usersData: ManagementUserList[], setUsersList: React.Dispatch<React.SetStateAction<ManagementUserList[]>>) => {
  setUsersList(() => {
    if(key === "projects") {
      return usersData.sort((a: ManagementUserList, b: ManagementUserList) => a.projects - b.projects)
    }

    if(key === "team") {
      return usersData.sort((a: any, b: any) => (a[key].length ? a[key].at(0) : "").localeCompare(b[key].length ? b[key].at(0) : ""))
    }

    if(key === "addedOn") {
      return usersData.sort((a: ManagementUserList, b: ManagementUserList) => {

        const a_data  = moment(a.addedOn, "DD.MM.YYYY hh:mm A").valueOf() / 1000;
        const b_data  = moment(b.addedOn, "DD.MM.YYYY hh:mm A").valueOf() / 1000;
        
        return a_data - b_data
      })
    }

      return usersData.sort((a: ManagementUserList, b: ManagementUserList) => a[key].localeCompare(b[key]))
    }
  )
}