import React, { useEffect, useState } from "react";
import { THElement } from "../../components/CreateProjectDataTable/components/THElement";
import {
  Container,
  TableBody,
  TableHeader,
} from "../../components/CreateProjectDataTable/style";
import { TRElement } from "../../components/CreateProjectDataTable/components/TRElement";
import { thirdStepTH } from "../../../../../../constants/measure";
import { requestGetDatasetContents } from "../../../../../../store/slices/datasets";
import { useDispatch, useSelector } from "react-redux";
import { getDatasetContents } from "../../../../../../store/selectors/datasets";
import { Loader } from "../../../../../Loader";
import { MeasureLoaderWrapper } from "./style";
import { MeasuresType } from "../../../../CreateComparison/utils";
import SortingMenu from "../../../../../SortMenuTable/SortTableMenu";

type Props = {
  programSelected: string;
  populationSelected: string;
  measureSelected?: string;
  setMeasuresSet: any;
  search?: string;
  goToPreview?: () => void;
  allMeasures: MeasuresType[];
};

const SelectMeasureStep = ({
  populationSelected,
  measureSelected,
  setMeasuresSet,
  search,
  allMeasures,
  goToPreview,
  programSelected,
}: Props) => {
  const dispatch = useDispatch();
  const datasetContents = useSelector(getDatasetContents);
  const [totalMeasures, setMeasures] = useState<any[]>([]);
  const [currentMeasures, setCurrentMeasures] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [columnMenu, setColumnMenu] = useState<{
    top: number;
    left: number;
    key: string;
  } | null>(null);
  const [activeSort, setActiveSort] = useState<{ key: string; order: "asc" | "desc" }>({
    key: "",
    order: "asc",
  });

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      requestGetDatasetContents({
        id: programSelected,
        limit: 20,
        skip: 0,
        callbacks: {
          onSuccess: (data) => {
            setIsLoading(false);
          },
        },
      })
    );
  }, [dispatch, programSelected]);

  useEffect(() => {
    if (search) {
      const newValue = search?.toLowerCase();
      const newMeasures = totalMeasures?.filter((r) => {
        const measureDescriptionDesktop: string =
          r?.measureDescriptionDesktop?.toLowerCase();
        const id: string = r?.id?.toLowerCase();
        return (
          id?.includes(newValue) ||
          measureDescriptionDesktop?.includes(newValue)
        );
      });
      setCurrentMeasures(newMeasures);
    } else {
      if (totalMeasures?.length) {
        setCurrentMeasures(totalMeasures);
      } else {
        const newMeasures = allMeasures.filter(
          (m) => m.bucket === populationSelected
        );
        setMeasures(newMeasures);
        setCurrentMeasures(newMeasures);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const columns = [
    {
      key: "measureDescriptionDesktop",
      label: "Measure Name",
      size: undefined
    },
  ];

  const handleColumnClick = (event: React.MouseEvent, key: string) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const top = rect.bottom;
    const left = rect.left;
    setColumnMenu({ top: top + 60, left: left - 10, key });
  };

  return (
    <Container>
      <TableHeader>
        {columns.map((column) => (
          <THElement
            text={column.label}
            key={column.key}
            size={column?.size}
            onClick={(e) => handleColumnClick(e, column.key)}
          />
        ))}
      </TableHeader>
      {isLoading ? (
        <MeasureLoaderWrapper>
          <Loader />
        </MeasureLoaderWrapper>
      ) : null}
      <TableBody>
        {currentMeasures
          .map((item: any) => {
            return (
              <TRElement
                key={item.id}
                id={item.id}
                header={item.id}
                subHeader={item.measureDescriptionDesktop}
                measures={0}
                selected={measureSelected === item.id}
                selectHandler={() => setMeasuresSet(item.id)}
                fields={datasetContents?.fields}
                goToPreview={goToPreview}
              />
            );
          })}
      </TableBody>
      {columnMenu && (
        <SortingMenu
          top={columnMenu.top}
          left={columnMenu.left}
          data={currentMeasures}
          setData={setCurrentMeasures}
          columns={columns}
          activeSort={activeSort}
          setActiveSort={setActiveSort}
          closeMenu={() => setColumnMenu(null)}
          columnKey={columnMenu.key} // Pass column key to SortingMenu
        />
      )}
    </Container>
  );
};

export default SelectMeasureStep;
