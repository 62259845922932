import styled from "styled-components";
import {
  ToggleOff,
  ToggleOn,
  ChevronCompactRight,
  SlashLg,
} from "react-bootstrap-icons";


export const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;
export const InvitedBy = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: ${({ theme }) => theme.primaryText};
  cursor: pointer;
`;

export const Chip = styled.button<{ $selected: boolean }>`
  all: unset;
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.primaryBackgroundActive : theme.neutralBackground};
  color: ${({ $selected, theme }) =>
    $selected ? theme.primaryText : theme.neutralText};
  padding: 4px 3px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  transition: 0.2s ease-in-out;

  span {
    font-size: 12px;
    font-weight: 500;
    color: ${({ $selected, theme }) =>
    $selected ? theme.primaryText : theme.neutralText};
  }

  &:active {
    background-color: ${({ theme }) =>
    theme.neutralBackgroundActive} !important;
  }

  &:hover {
    background-color: ${({ $selected, theme }) =>
    $selected ? theme.primaryBackgroundActive : theme.neutralBackgroundHover};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.neutralBackground};
    color: ${({ theme }) => theme.neutralTextDisabled};
  }
`;

export const Slash = styled(SlashLg)`
  display: block;
`;

export const Arrow = styled(ChevronCompactRight)`
  display: none;
`;

export const ProfileSideBarWrapper = styled.div`
  max-width: 356px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 8px;
  padding: 10px 20px 16px;
`;

export const ProfileSideBarHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralText};
`;

export const ProfileSideBarSectionWrapper = styled.div`
  margin-top: 12px;
`;

export const ProfileSideBarAboutCardWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
`;

export const ProfileSideBarAboutCardDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ProfileSideBarAboutCardTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.neutralTextWeak};
  font-size: 12px;
  font-weight: 400;
`;

export const ProfileSideBarAboutCardIcon = styled.div`
  svg {
    height: 14px;
    width: 14px;

    path {
      color: ${({ theme }) => theme.neutralTextWeak};
    }
  }
`;

export const ProfileSideBarAboutCardDescription = styled.div<{
  $noData?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme, $noData }) =>
    $noData ? theme.neutralTextDisabled : theme.neutralText};
`;

export const TermsOfUse = styled.div`
  margin-top: 70px;
  font-size: 11px;
  font-weight: 400;
  color: ${({ theme }) => theme.primaryText};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.primaryTextHover};
  }
`;

export const SecurityButton = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryText};
  margin-top: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    ${Slash} {
      display: none;
    }

    ${Arrow} {
      display: block;
    }

    color: ${({ theme }) => theme.primaryTextHover};
  }
`;

export const ViewToggleOn = styled(ToggleOn)`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

export const ViewToggleOff = styled(ToggleOff)`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;
