import { useCallback, useEffect, useMemo, useState } from "react";
import useMeasure from "react-use-measure";
import { sankey } from "d3-sankey";
import { GradientPurpleTeal } from "@visx/gradient";
import { LinkHorizontal } from "@visx/shape";
import { Text } from "@visx/text";
import { useDispatch, useSelector } from "react-redux";

import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  Title,
} from "../VerticalBarchart/styles";
import { setActiveModal } from "../../../store/slices/modals";
import {
  getInteractivityState,
  getPageSettings,
} from "../../../store/selectors/projects";
import { ChartLegend } from "../../ChartLegend";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { Tooltip, TooltipProps } from "../Tooltip";
import { Loader } from "../../Loader";
import { ColorRangeI } from "../../../models/Pages";
import { hexToRGBA } from "../../../helpers/hexToRgba";
import { BlockLabelAxe, LabelAxe } from "../styles";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { generateBreakPoints } from "../../../helpers/generateBreakPoints";
import { getClientPosition } from "../components/LabelTooltip";
import { createPortal } from "react-dom";
import { TooltipLabel } from "../TooltipLabel";
import { getSequentialColorsHex } from "../../../constants/utils/getSequentialsColors";
import { getAvailableWidgetTypes } from "../widgetHelpers";

interface Node {
  name: string;
}
interface RawLink {
  source: string;
  target: string;
  value: string;
}
interface Link {
  source: string;
  target: string;
  value: number;
}

interface SankeyInterface {
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  currentWidget: WidgetItem;
  isRight?: boolean;
}

export const SankeyPlot = ({
  storytelling,
  recommended,
  currentWidget,
  showLegend = true,
  selected = false,
  hideName = false,
  isRight,
}: SankeyInterface) => {
  const dispatch = useDispatch();

  const [ref, bounds] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const interactivity = useSelector(getInteractivityState);
  const isPublicRoute = useSelector(getIsPublicMode);
  const aiSuggestions = useSelector(getAiSuggestions);
  const { dashType } = useSelector(getPageSettings);

  const [localSelected, setLocalSelected] = useState("");
  const [hoveredElement, setHoveredElement] = useState<null | string>();
  const [graph, setGraph] = useState<any>();
  const [nodes, setNodes] = useState<Node[]>([]);
  const [links, setLinks] = useState<RawLink[]>([]);
  const [xAxe, setXAxe] = useState<string>();
  const [yAxe, setYAxe] = useState<string>();
  const [dimensionKey, setDimensionKey] = useState<string>();
  const [colorRanges, setColorRanges] = useState<ColorRangeI[]>([]);
  const [values, setValues] = useState<number[]>([]);
  const [variations, setVariations] = useState<string[]>([]);
  const [tooltip, setTooltip] = useState<TooltipProps | null>(null);
  const [tooltipLabel, setTooltipLabel] = useState<{
    name?: string;
    x: number;
    y: number;
  } | null>(null);

  const width = bounds.width || 1084;
  const height = bounds.height - 10 || 200;

  const sankeyChartSuggestion = aiSuggestions?.find(
    (chart: AiSuggestionsDto) => chart.chartType === "sankeyChart"
  );

  const layout = sankey<Node, Link>()
    .nodeId((d: any) => d.name)
    .nodePadding(14)
    .nodeSort(() => {
      return undefined;
    });
  const padding = 4;

  const gradientSeq = [GradientPurpleTeal];

  const maxDepth = useMemo(
    () =>
      graph?.nodes ? Math.max(...graph?.nodes?.map((n: any) => n.depth!)) : 0,
    [graph]
  );

  const activeLinks = useMemo(() => {
    if (localSelected === "") return new Set();
    return new Set(
      graph?.links?.filter(
        (l: any) =>
          layout.nodeId()(l.source as any) === localSelected ||
          layout.nodeId()(l.target as any) === localSelected
      )
    );
  }, [localSelected, graph?.links, layout]);

  useEffect(() => {
    !interactivity && setLocalSelected("");
  }, [interactivity]);

  useEffect(() => {
    const nodeWidth = bounds.width > 400 && height > 270 ? 70 : 65;
    layout.nodeWidth(nodeWidth);
    if (nodes?.length && links?.length && height && width) {
      setGraph(
        layout.extent([
          [padding, padding],
          [width - padding, height - padding * 2],
        ])({
          nodes,
          links: links?.map((l) => ({ ...l, value: Number(l.value) })),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, links, nodes, width]);

  const generateColorRanges = useCallback(() => {
    if (variations?.length && values) {
      const colorRanges = [];

      const sortedValues = [...new Set(values)].sort();
      const steps = generateBreakPoints(sortedValues);
      for (let i = 0; i < steps?.length; i++) {
        const end = i === steps?.length - 1 ? steps[i] * 3 : steps[i + 1];
        const colorRange = {
          color: variations[i],
          start: parseInt(steps[i]?.toString()),
          end: parseInt(end?.toString()),
        };
        colorRanges.push(colorRange);
      }
      setColorRanges(colorRanges || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variations, values, generateBreakPoints]);

  useEffect(() => {
    if (currentWidget) {
      const sankeysChartData = currentWidget?.data;
      if (sankeysChartData?.length) {
        const colorPaletteVariations = getSequentialColorsHex(
          currentWidget.palette?.paletteId
        );

        setVariations(colorPaletteVariations?.slice());
        let dimensionKey =
          currentWidget?.arrangeBy && currentWidget?.arrangeBy?.length
            ? currentWidget?.arrangeBy?.at(0) ||
              Object.keys(currentWidget?.uniqueValues || {}).at(0)
            : sankeyChartSuggestion?.groupBy?.at(0);
        const xAxe =
          currentWidget?.arrangeBy && currentWidget?.arrangeBy?.length
            ? currentWidget?.arrangeBy?.at(1) ||
              Object.keys(currentWidget?.uniqueValues || {}).at(1)
            : sankeyChartSuggestion?.xAxe?.at(0);

        setXAxe(xAxe);
        const yAxe = currentWidget?.display?.length
          ? currentWidget?.display?.at(0)
          : sankeyChartSuggestion?.yAxe?.at(0);
        setYAxe(yAxe);
        if (!dimensionKey) {
          const dataKeys = Object.keys(currentWidget.data?.at(0) || {});
          const notUseKey = dataKeys?.filter((r) => ![yAxe, xAxe].includes(r));
          dimensionKey = notUseKey?.at(0);
        }
        setDimensionKey(dimensionKey);

        if (dimensionKey) {
          const xAxes = sankeysChartData?.reduce((t, l) => {
            if (!t.includes(l[xAxe])) {
              // @ts-ignore need to check type
              return [l[xAxe], ...t];
            }
            return t;
          }, []);
          xAxes?.sort();
          const yAxes = sankeysChartData?.reduce((t, l) => {
            if (!t.includes(l[yAxe])) {
              // @ts-ignore need to check type
              return [l[yAxe], ...t];
            }
            return t;
          }, []);
          yAxes?.sort();

          let dimensionDetails = sankeysChartData?.reduce((t, l) => {
            if (!t.includes(l[dimensionKey])) {
              // @ts-ignore need to check type
              return [l[dimensionKey], ...t];
            }
            return t;
          }, []);
          dimensionDetails?.sort();
          // @ts-ignore need to check type
          const nodes: Node[] = [...xAxes, ...dimensionDetails].map((name) => {
            return {
              name,
            };
          });
          let newValues: number[] = [];
          if (xAxe === "value") {
            newValues =
              currentWidget?.data?.map((item) => Number(item[xAxe])) || [];
          } else {
            newValues =
              currentWidget?.data?.map((item) => Number(item[yAxe])) || [];
          }
          setValues(newValues);
          const links: RawLink[] = sankeysChartData.map((a) => ({
            source: a[xAxe],
            target: a[dimensionKey],
            value: a[yAxe],
          }));
          setNodes(nodes);
          setLinks(links);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentWidget,
    sankeyChartSuggestion?.groupBy,
    sankeyChartSuggestion?.xAxe,
    sankeyChartSuggestion?.yAxe,
  ]);

  const xAxeLabel = useMemo(
    () => (xAxe ? xAxe.charAt(0).toUpperCase() + xAxe.slice(1) : ""),
    [xAxe]
  );

  const dimensionKeyLabel = useMemo(
    () =>
      dimensionKey
        ? dimensionKey?.charAt(0)?.toUpperCase() + dimensionKey?.slice(1)
        : "",
    [dimensionKey]
  );

  useEffect(() => {
    if (variations?.length) {
      generateColorRanges();
    }
  }, [variations?.length, values, generateColorRanges]);

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  const availableWidgetsCount = useMemo(() => {
    if (recommended) {
      return 0;
    }

    return getAvailableWidgetTypes(currentWidget).length;
  }, [currentWidget, recommended]);

  const handleSelect = useCallback(
    (node: any) => {
      const id = layout.nodeId()(node) as string;
      if (localSelected === id) setLocalSelected("");
      else setLocalSelected(id);
    },
    [layout, localSelected]
  );

  if (!nodes || !nodes?.length) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  const getNodeIsActive = (node: any) => {
    const links = node?.sourceLinks?.length
      ? node?.sourceLinks
      : node?.targetLinks;

    const actives = [];
    for (let link of links) {
      if (activeLinks.has(link)) {
        actives.push(true);
      }
    }
    return links?.length === actives?.length;
  };

  return (
    <>
      <HeaderWrapper
        $isRow={
          (!isRight && !hideName && recommended) ||
          (!isRight && !isPublicRoute && !recommended && isEditMode)
        }
      >
        {!storytelling && (
          <>
            {!hideName ? (
              <Title>
                {name}
                {showLegend &&
                currentWidget?.legend &&
                dashType !== "comparison" ? (
                  <ChartLegend legendType="palette" colorRanges={colorRanges} />
                ) : null}
              </Title>
            ) : (
              <div />
            )}
            {!isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={false}
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {availableWidgetsCount + 1}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </>
        )}
        {!recommended &&
          showLegend &&
          currentWidget?.legend &&
          dashType === "comparison" && (
            <ChartLegend
              isRight={isRight}
              offset={-38}
              legendType="palette"
              colorRanges={colorRanges}
            />
          )}
      </HeaderWrapper>
      <BlockLabelAxe>
        <LabelAxe>{xAxeLabel}</LabelAxe>
        <LabelAxe>{dimensionKeyLabel}</LabelAxe>
      </BlockLabelAxe>
      <svg
        ref={ref}
        width="100%"
        style={{ position: "relative", height: "calc(100%)" }}
      >
        {gradientSeq.map((G) => (
          <G id={G.name} key={G.name} />
        ))}

        {graph?.links?.map((link: any, index: number) => {
          let mainColor = colorRanges.find(
            (r) => r.start <= link.value && r.end >= link.value
          );
          if (!mainColor) {
            mainColor = colorRanges[colorRanges?.length - 1];
          }
          const key = `${String(link.source.name)?.trim()}-${String(
            link.target.name
          )?.trim()}-${index}`;
          return (
            <LinkHorizontal
              style={{ transition: "0.3s" }}
              key={key}
              data={link}
              source={(l: any) => ({ x: l.source.x1, y: l.y0 })}
              target={(l: any) => ({ x: l.target.x0, y: l.y1 })}
              name={link.value}
              x={(n) => n.x}
              y={(n) => n.y}
              fill="none"
              opacity={
                hoveredElement
                  ? key === hoveredElement
                    ? 1
                    : 0.5
                  : activeLinks.size > 0 && !activeLinks.has(link)
                  ? 0.5
                  : 1
              }
              stroke={
                activeLinks.has(link) ||
                (hoveredElement && key === hoveredElement)
                  ? hexToRGBA(mainColor?.color!, 1)
                  : hexToRGBA(mainColor?.color!, 0.5)
              }
              strokeWidth={link.width - 3}
              onMouseMove={(event: any) => {
                if (currentWidget.tooltip && !recommended) {
                  const { pageX, pageY, clientX, clientY } = event;
                  const coords = { pageX, pageY, clientX, clientY };

                  setHoveredElement(key);
                  setTooltip({
                    name: link.target.name,
                    data: {
                      [xAxe as string]: link?.source?.name,
                      [yAxe as string]: link.value,
                      [dimensionKey as string]: link?.target?.name,
                    },
                    coords,
                  });
                }
              }}
              onMouseLeave={() => {
                setTooltip(null);
                setHoveredElement(null);
              }}
            />
          );
        })}

        {graph?.nodes?.map((node: any, i: any) => {
          const different = Math.floor(height / 35) + 3;

          const isActive = getNodeIsActive(node);
          const preliminaryFontSize = different + 5;
          const fontSize = preliminaryFontSize >= 12 ? 12 : preliminaryFontSize;

          const name =
            String(node?.name)?.length <= 7
              ? node?.name
              : `${String(node?.name)?.slice(0, 7)}...`;

          return (
            <Text
              key={node.name}
              dx={
                node.depth === maxDepth
                  ? node.x0! +
                    (bounds.width > 400 && bounds.height > 270 ? 70 : 65)
                  : node.x1! - Number(String(node.x1 - node.x0))
              }
              dy={node.y0! + different - (bounds.height > 270 ? 0 : 0.5)}
              style={{
                fontSize,
                fontWeight: isActive ? 600 : 400,
                cursor: "pointer",
              }}
              verticalAnchor={
                bounds.width > 400 && bounds.height > 270 ? "end" : "middle"
              }
              textAnchor={node.depth === maxDepth ? "end" : "start"}
              className="sankey-line"
              onClick={() => interactivity && handleSelect(node)}
              onMouseEnter={(e: any) => {
                const { x, y } = getClientPosition(e);

                if (node.name?.length > 7 && setTooltipLabel) {
                  setTooltipLabel({
                    name: node.name,
                    x: x - 27,
                    y: y - 55,
                  });
                }
              }}
              onMouseLeave={() => setTooltipLabel && setTooltipLabel(null)}
            >
              {name}
            </Text>
          );
        })}

        {graph?.nodes?.map((node: any) => {
          const different = Math.floor(height / 15);
          const isActive = getNodeIsActive(node);

          return different > 4 ? (
            <line
              key={node.name}
              x1={node.x0}
              y1={node.y0}
              x2={node.x1}
              y2={node.y0}
              stroke={isActive ? "#939BA7" : "#D3DBE3"}
            />
          ) : null;
        })}
      </svg>

      {tooltip &&
        xAxe &&
        yAxe &&
        createPortal(
          <Tooltip
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
            coords={tooltip.coords}
          />,
          document.body
        )}

      {tooltipLabel &&
        createPortal(
          <TooltipLabel
            x={tooltipLabel.x}
            y={tooltipLabel.y}
            name={tooltipLabel.name}
          />,
          document.body
        )}
    </>
  );
};
