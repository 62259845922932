import { useCallback, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";

const TruncatedText = styled.text<{
  $hasExcessiveSplitLength?: boolean;
}>`
  display: block;
  text-overflow: ellipsis;
  cursor: ${({ $hasExcessiveSplitLength }) =>
    $hasExcessiveSplitLength ? "pointer" : "default"};
`;

const Label = styled.div`
  text-anchor: start;
  font-size: 11px;
  font-weight: 400;
  color: #59616f;
  overflow: hidden;
  display: block;
  position: absolute;
  text-overflow: ellipsis;
  background: #fff;
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
`;

export const getRelativePosition = (element: any, event: any) => {
  const rect = element.getBoundingClientRect();
  return {
    x: event.clientX - rect.left,
    y: event.clientY - rect.top,
  };
};

export const getClientPosition = (event: any) => {
  return {
    x: event.pageX,
    y: event.pageY,
  };
};

export interface TickLabelProps {
  formattedValue?: string | number;
  length?: number;
  setTooltip?: (tooltip: LabelTooltipProps | null) => void;
  offsetX?: number;
  offsetY?: number;
  dy?: string | number;
  [key: string]: any;
}

export const TickLabel = ({
  formattedValue,
  length = 5,
  setTooltip,
  offsetX = 0,
  offsetY = 0,
  dy = 0,
  ...tickProps
}: TickLabelProps) => {
  const [labelTooltip, setLabelTooltip] = useState<LabelTooltipProps | null>(
    null
  );

  const splitedVal =
    typeof formattedValue === "number"
      ? formattedValue?.toString().split("")
      : formattedValue?.split("") || [];

  const hasExcessiveSplitLength = splitedVal?.length > length;

  const handleMouseMove = useCallback(
    (e: any) => {
      const { x, y } = getClientPosition(e);

      if (hasExcessiveSplitLength) {
        const tooltipData = {
          data: String(formattedValue),
          x: x + offsetX,
          y: y + offsetY,
        };

        if (setTooltip) {
          setTooltip(tooltipData);
        } else if (setLabelTooltip) {
          setLabelTooltip(tooltipData);
        }
      }
    },
    [formattedValue, hasExcessiveSplitLength, offsetX, offsetY, setTooltip]
  );

  const handleMouseLeave = useCallback(() => {
    if (setTooltip) {
      setTooltip(null);
    } else if (setLabelTooltip) {
      setLabelTooltip(null);
    }
  }, [setTooltip]);

  return (
    <>
      <TruncatedText
        {...tickProps}
        $hasExcessiveSplitLength={hasExcessiveSplitLength}
      >
        <tspan
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          x={tickProps.x || 0}
          dy={dy}
        >
          {splitedVal?.slice(0, length).join("")}
          {hasExcessiveSplitLength ? "..." : ""}
        </tspan>
      </TruncatedText>

      {labelTooltip &&
        createPortal(
          <LabelTooltip
            x={labelTooltip?.x}
            y={labelTooltip?.y}
            data={labelTooltip?.data}
          />,
          document.body
        )}
    </>
  );
};

export interface LabelTooltipProps {
  data: string;
  x: number;
  y: number;
}

export const LabelTooltip = ({ x, y, data }: LabelTooltipProps) => (
  <Label
    style={{
      left: `${x}px`,
      top: `${y - 25}px`,
      position: "absolute",
      zIndex: "100",
    }}
  >
    {data}
  </Label>
);
