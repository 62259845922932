import React, { useState } from "react";
import * as s from "./styles";
import { ChartContainerProps } from "./types/ChartContainerProps.interface";
import { WidgetChartWrapper } from "../../../Widgets";
import { useDispatch, useSelector } from "react-redux";
import { getIsEditMode, getIsPublicMode } from "../../../../store/selectors/main";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  SettingsButtonWrapper,
} from "../../../Widgets/Barchart/styles";
import {
  requestPageWidgets,
  setCurrentWidget,
} from "../../../../store/slices/projectPages";
import { setActiveModal } from "../../../../store/slices/modals";
import { CloseIcon } from "../../../Modals/styles";
import {
  requestDeleteWidget,
  requestWidgetsSuggestions,
} from "../../../../store/slices/widgets";
import { useParams } from "react-router-dom";
import { AVAILABLE_WIDGETS } from "../../../../constants/widgetRecomended";

export const ChartContainer: React.FC<ChartContainerProps> = ({
  item,
  originalItem,
  children,
  disabledSettings,
  isRight,
}) => {
  const { id, pageId } = useParams();
  const isEditMode = useSelector(getIsEditMode);
  const isPublicMode = useSelector(getIsPublicMode);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    dispatch(requestDeleteWidget(item.id));
    setTimeout(() => {
      if (pageId && id) {
        dispatch(requestPageWidgets({ pageId: pageId, includeData: true }));
        dispatch(
          requestWidgetsSuggestions({
            pageId: pageId,
            projectId: id,
            includeData: false,
          })
        );
      }
    }, 500);
  };

  return (
    <s.SectionWrapper
      $storytelling
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      $isEditMode={isEditMode}
    >
      {isRight && !isPublicMode && isEditMode && (
        <s.CloseWrapper>
          {!disabledSettings && (
            <SettingsButtonWrapper
              $modalOpen={false}
              onClick={() => {
                dispatch(setCurrentWidget(originalItem!));
                dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
              }}
            >
              <DatavizRecommendedCount>{AVAILABLE_WIDGETS[item.chartType]?.length + 1}</DatavizRecommendedCount>
              <DatavizSettingsIcon />
            </SettingsButtonWrapper>
          )}
          {open && (
            <CloseIcon
              onClick={() => {
                handleDelete();
              }}
            />
          )}
        </s.CloseWrapper>
      )}
      <s.ContentWrapper>
          <s.HeaderWrapper>
          {!isRight && (<s.Title>{item.name}</s.Title>)}
          </s.HeaderWrapper>
        <s.ChildrenWrapper>
          <WidgetChartWrapper storytelling={item.chartType === "mapChart"}>{children}</WidgetChartWrapper>
        </s.ChildrenWrapper>
      </s.ContentWrapper>
    </s.SectionWrapper>
  );
};
