import { useState } from "react";
import { CheckCircle } from "react-bootstrap-icons";
import { toast } from "react-toastify";

import {
  Actions,
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterWrapper,
  ModalHeading,
  ModalHeadingWrapper,
  ModalOverlay,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { Button } from "../../Button";
import { MultiSelect } from "../../Inputs/CustomMultiSelect/MultiSelect";
import { mockDataUsersList } from "../../../pages/UserManagment/pages/Users/data/mock";
import { ISelect } from "../../Inputs/CustomMultiSelect/types";
import { CustomInput } from "../../Inputs/CustomInput";
import { ContentWrapper, InfoCircleIcon, SelectBlock, SelectBlockLabel, SelectBlockTitle } from "./style";

type ModalProps = {
  onClose: () => void;
};
interface Form {
  name: string,
  managers: string[]
  programs: string[]
}

const defaultForm: Form = {
  name: '',
  managers: [],
  programs: []
}

const programs: ISelect[] = [
  {
    option: 'LTSS',
    value: 'LTSS'
  },
  {
    option: 'CCU',
    value: 'CCU'
  },
]

export const CreateTeam = ({ onClose }: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [form, setForm] = useState(defaultForm);

  const handleOnClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 400);
  };

  const handleSave = () => {
    handleOnClose();
    toast.success("Successfuly! P.S. No endpoint for creating team")
  }

  return (
    <ModalOverlay $centred $isClosing={isClosing} onClick={handleOnClose}>
      <ModalWrapper
        $isClosing={isClosing}
        $centred
        onClick={(e) => e.stopPropagation()}
      >
        <ModalHeadingWrapper $disableSeparator={false}>
          <ModalContainer>
            <ModalHeading>Create Team</ModalHeading>
            <CloseIconWrap>
              <CloseIcon onClick={handleOnClose}></CloseIcon>
            </CloseIconWrap>
          </ModalContainer>
        </ModalHeadingWrapper>
        <OverviewContent>
          <ModalContent>
            <ContentWrapper>
              <CustomInput
                name="name"
                value={form.name}
                type={"text"}
                onChange={(e: string) => {
                  setForm({ ...form, name: e })
                }}
                placeholder={"Name"}
                disablePaddings
                size={'base'}
              />
              <SelectBlock>
                <SelectBlockTitle>
                  Programs
                </SelectBlockTitle>
                <MultiSelect
                  placeholder="Select programs"
                  value={form.programs}
                  disableAddByEnter
                  onSelect={(item) => {
                    setForm({ ...form, programs: item.map((el) => el.value) })
                  }}
                  options={programs}
                />
              </SelectBlock>
              <SelectBlock>
                <SelectBlockTitle>
                  Managers
                </SelectBlockTitle>
                <MultiSelect
                  placeholder="Managers"
                  value={form.managers}
                  limit={50}
                  errors={['@', '.']}
                  limitMessage="You can add maximum 50 members to team"
                  options={mockDataUsersList.map((el) => ({ value: el.email, option: el.name, label: el.email, avatar: true }))}
                  onSelect={(item) => {
                    setForm({
                      ...form,
                      managers: item.map((el) => (typeof el === 'string' ? el : el.value || String(el))),
                    });
                  }}
                />
                <SelectBlockLabel>
                  <InfoCircleIcon />A team can have up to 50 members.
                </SelectBlockLabel>
              </SelectBlock>
            </ContentWrapper>
          </ModalContent>
        </OverviewContent>
        <ModalFooterWrapper>
          <Button
            name="Cancel"
            onClick={handleOnClose}
            variant="neutral"
            size="medium"
          />
          <Actions>
            <Button
              name="Create"
              onClick={handleSave}
              variant={true ? "secondary" : "disabled"}
              disabled={false}
              size="medium"
              icon={<CheckCircle />}
            />
          </Actions>
        </ModalFooterWrapper>
      </ModalWrapper>
    </ModalOverlay>
  );
};
