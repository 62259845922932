import styled from "styled-components";
import { OverviewContent } from "../styles";
export const WidgetsItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0 20px;
  margin-bottom: 20px;
  max-width: 100%;
`;

export const DatasetsWrapper = styled.div`
  padding: 0 20px 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const DatasetItem = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.neutralText};
`

export const OverviewContentExt = styled(OverviewContent)`
  height: calc(100% - 127px);
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const WidgetsCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 4px;
  background-color: ${({ theme }) => theme.primaryBackgroundActive};
  color: ${({ theme }) => theme.primaryText};
  border-radius: 2px;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
`;

export const RecommendedWidgetItemWrapper = styled.div`
  height: 263px; // same as WidgetItem height in AvailableWidgets/styles.ts
`;
