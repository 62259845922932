import styled, { css } from "styled-components";


const textStyle = css`
  color: ${({theme}) => theme.neutralTextWeak};
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  border-right: 1px solid ${({theme}) => theme.neutralBorder};
  padding: 9px 8px;
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
`

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${({theme}) => theme.neutralBorder};
  overflow: visible;
`
export const TableHeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TableHead = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({theme}) => theme.neutralBackground};
  border-bottom: 1px solid ${({theme}) => theme.neutralBorder};

`
export const TableFilters = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.neutralBorder};
`

export const CheckboxWrapper = styled.div<{$noPadding?: boolean}>`
  ${textStyle};
  min-width: 33px;
  height: 35px;
  ${({$noPadding}) => $noPadding && `padding: 0;`}

`

export const Name = styled.div<{$noPadding?: boolean}>`
  ${textStyle};
  height: 100%;
  width: 100%;
  ${({$noPadding}) => $noPadding && `padding: 0;`}
`

export const EmailAddress = styled.div<{$noPadding?: boolean}>`
  ${textStyle};
  min-width: 316px;
  max-width: 316px;
  margin-left: auto;
  ${({$noPadding}) => $noPadding && `padding: 0;`}

`

export const Position = styled.div<{$noPadding?: boolean}>`
  ${textStyle};
  min-width: 200px;
  max-width: 200px;
  ${({$noPadding}) => $noPadding && `padding: 0;`}

`

export const Role = styled.div<{$noPadding?: boolean}>`
  ${textStyle};
  min-width: 120px;
  max-width: 120px;
  ${({$noPadding}) => $noPadding && `padding: 0;`}

`

export const Team = styled.div<{$noPadding?: boolean}>`
  ${textStyle};
  min-width: 200px;
  max-width: 200px;
  ${({$noPadding}) => $noPadding && `padding: 0;`}

`

export const Projects = styled.div<{$noPadding?: boolean}>`
  ${textStyle};
  min-width: 96px;
  max-width: 96px;
  ${({$noPadding}) => $noPadding && `padding: 0;`}

`

export const AddedOn = styled.div<{$noPadding?: boolean}>`
  ${textStyle};
  min-width: 160px;
  max-width: 160px;
  ${({$noPadding}) => $noPadding && `padding: 0;`}

`

export const LastActive = styled.div<{$noPadding?: boolean}>`
  ${textStyle};
  min-width: 120px;
  max-width: 120px;
  ${({$noPadding}) => $noPadding && `padding: 0;`}

`

export const More = styled.div<{$noPadding?: boolean}>`
  min-width: 32px;
  max-width: 32px;
  ${({$noPadding}) => $noPadding && `padding: 0;`}

`
