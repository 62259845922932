import { styled } from "styled-components";

export const PageContainer = styled.div<{
  $backgroundColor: string;
}>`
  width: 100%;
  height: 1295px;
  display: flex;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;

  .pdf-view {
    border: none;
    border-radius: 0;
  }
  
  .option-menu {
    z-index: 7;
  }
`;

export const DiagonalImageSection = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  background-color: white;
`;

export const ImageContainer = styled.div<{
  $image: string;
  $backgroundColor: string;
}>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  clip-path: polygon(71% 61%, 0 0%, 0 0, 140% 0);
  background: ${({ theme, $backgroundColor }) => $backgroundColor || theme.neutralBackgroundMedium};

  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  background: ${({ theme, $backgroundColor }) => $backgroundColor || theme.neutralBackgroundMedium};
`;

export const SVGLayer = styled.svg<{ $zIndex: number }>`
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: auto;
  z-index: ${props => props.$zIndex};
`;

export const TextContent = styled.div`
  position: relative;
  margin-top: -14%;
  z-index: 5;
  padding: 0 32px;
`;

export const TextSection = styled.div`
  text-align: left;
  z-index: 1;
`;

export const MainTitle = styled.h1`
  color: ${({ theme }) => theme.neutralText};
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  margin-bottom: 24px;
`;

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.neutralText};
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 24px;
  line-height: 32px;
`;

export const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const FooterLogo = styled.div`
  margin-bottom: 16px;
`;

export const FooterText = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${({ theme }) => theme.neutralText};
`;
