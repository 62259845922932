import React from "react";
import { List } from "./styled";
import { PlusLg } from "react-bootstrap-icons";
import { useSearchParams } from "react-router-dom";

import { TeamItem } from "../../../../components/UserManagementComponents/Teams/TeamItem";
import AvatarImage from "../../../../assets/profileAvatar.png";
import { ReactComponent as TeamImage } from "./assets/teamImage.svg";
import { TeamItemProps } from "../../../../components/UserManagementComponents/Teams/TeamItem/teamItem.interface";
import { EmptyPage } from "../../../../components/EmptyPage";
import { ReactComponent as NoDataImage } from "./assets/noDataImage.svg";

type Props = {};

export const users = [
  { name: "Angela Smith", id: "1", avatar: AvatarImage },
  { name: "John Doe", id: "2" },
  { name: "Brad Pitt", id: "3" },
  { name: "John Doe", id: "4", avatar: AvatarImage },
  { name: "Angela Smith", id: "5", avatar: AvatarImage },
  { name: "Brad Pitt", id: "6", avatar: AvatarImage },
];

export const teams: TeamItemProps[] = [
  {
    id: "1",
    Image: TeamImage,
    name: "Research and Analytics",
    users: users,
    role: "viewer",
    programs: ["LTSS", "CCU"],
    color: "#2D6E8D",
  },
  {
    id: "2",
    Image: TeamImage,
    name: "IT and Technical",
    users: users,
    role: "viewer",
    programs: ["CCU"],
    color: "#EEA676",
  },
  {
    id: "3",
    Image: TeamImage,
    name: "Administratives",
    users: users,
    role: "viewer",
    programs: ["LTSS"],
    color: "#2F54EB",
  },
  {
    id: "4",
    Image: TeamImage,
    name: "Support and Auxiliary",
    users: users,
    role: "editor",
    programs: ["LTSS"],
    color: "#9E9DAF",
  },
  {
    id: "5",
    Image: TeamImage,
    name: "Executive and Strategic",
    users: users,
    role: "editor",
    programs: ["CCU", "LTSS"],
    color: "#43A047",
  },
  {
    id: "6",
    Image: TeamImage,
    name: "Quality Assurance",
    users: users,
    role: "manager",
    programs: ["LTSS"],
    color: "#DA7973",
  },
  {
    id: "7",
    Image: TeamImage,
    name: "Sales and Support",
    users: users,
    role: "manager",
    programs: ["CCU", "LTSS"],
    color: "#7BA4B7",
  },
];

const TeamsTable: React.FC<Props> = (props) => {
  const [searchParams] = useSearchParams();
  const test = searchParams.get("test");

  return (
    <>
      {Boolean(test) ? (
        <EmptyPage
          Icon={NoDataImage}
          title="No teams were discovered"
          subTitle={`Feel free to create a new team by clicking the "Create Team" button below! `}
          button={{
            name: "Create Team",
            onClick: () => {},
            icon: <PlusLg />,
          }}
        />
      ) : (
        <List>
          {teams?.map((team) => {
            return <TeamItem {...team} />;
          })}
        </List>
      )}
    </>
  );
};

export default TeamsTable;
