export const selectProgramStepTH = [
  { text: "Program name", size: "367px" },
  { text: "Measures", size: "91px" },
];

export const secondStepTH = [
  { text: "Population name", size: "367px" },
  { text: "Measures", size: "91px" },
];

export const thirdStepTH = [{ text: "Measure name" }];
