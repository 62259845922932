import styled from "styled-components";

export const TextAreaContainer = styled.div<{ $disablePaddings?: boolean }>`
  padding: ${({ $disablePaddings }) => ($disablePaddings ? "" : "0 20px")};
  margin-bottom: ${({ $disablePaddings }) => ($disablePaddings ? "" : "16px")};
`;

export const Heading = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralText};
`;

export const TextAreaWrapper = styled.div<{ $size?: "default" | "base"; $disablePaddings?: boolean }>`
  display: flex;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  border-radius: 5px;
  margin-top: ${({ $disablePaddings }) => ($disablePaddings ? "" : "8px")};
  width: 100%;
  overflow: hidden;

  &:focus-within {
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`;

export const StyledTextArea = styled.textarea<{ $filled?: boolean; $size?: "default" | "base" }>`
  width: 100%;
  border: none;
  padding: 10px 14px;
  border-radius: 5px;
  outline: none;
  color: ${({ theme }) => theme.neutralText};
  background-color: ${({ $filled, theme }) => !$filled && theme.neutralBackground};
  font-size: 14px;
  resize: vertical; /* Allows the user to resize vertically but not horizontally */

  &:hover {
    background-color: ${({ theme }) => theme.neutralBackgroundHover};
  }

  &:focus {
    background-color: ${({ theme }) => theme.neutralBackgroundBase};
  }
`;
