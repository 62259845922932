import { useRef } from "react";

import { SubMenuWrapper } from "./styles";
import { useOutsideClick } from "../../hooks/useOutsideClick";

import {
  Trash,
  ClockHistory,
  BarChartLine,
  ArrowsCollapseVertical,
  FileEarmark,
} from "react-bootstrap-icons";

type SubmenuProps = {
  projectMenu?: boolean;
  datasetItemMenu?: boolean;
  menuOpen: boolean;
  pageTabMenu?: boolean;
  isReportTab?: boolean;
  isComparisonTab?: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
  onRemove?: () => void;
  onEdit?: () => void;
  onClose?: () => void;
  onDuplicate?: () => void;
  onCreateComparison?: () => void;
  onCreateReport?: () => void;
};

export const PageSubmenu = ({
  projectMenu,
  menuOpen,
  setMenuOpen,
  onRemove,
  datasetItemMenu,
  pageTabMenu,
  onCreateComparison,
  onCreateReport,
  isReportTab,
  isComparisonTab,
}: SubmenuProps) => {
  const menuRef = useRef<any>(null);
  useOutsideClick(menuRef, () => {
    setMenuOpen(false);
  });

  return (
    <SubMenuWrapper
      $pageTab={pageTabMenu}
      $projectPage={projectMenu}
      $datasetItemMenu={datasetItemMenu}
      $open={menuOpen}
      ref={menuRef}
    >
      {!isReportTab && <li
        onClick={() => {
          setMenuOpen(false);
          onCreateReport?.()
        }}
      >
        <FileEarmark />
        Create Report
      </li>}
      {!isComparisonTab && <li
        onClick={() => {
          setMenuOpen(false);
          onCreateComparison?.()
        }}
      >
        <ArrowsCollapseVertical />
        Create Comparison
      </li>}
      {!isReportTab && <li
        onClick={() => {
          setMenuOpen(false);
        }}
      >
        <BarChartLine />
        Duplicate Dashboard
      </li>}
      {projectMenu && (
        <li
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <ClockHistory />
          Version history
        </li>
      )}
      <li
        onClick={() => {
          onRemove?.();
          setMenuOpen(false);
        }}
      >
        <Trash />
        Remove
      </li>
    </SubMenuWrapper>
  );
};
