import { IFormatting } from "../../../../models/Widgets";

export const getSparkLineChartColors = (currentWidget: any): IFormatting[] => {
  const chartData = currentWidget?.data;
  const groupKey = currentWidget?.groupBy?.[0];

  if (groupKey) {
    const groupedData = chartData?.reduce((acc: any, cur: any) => {
      acc[cur[groupKey]] = [...(acc[cur[groupKey]] || []), cur];
      return acc;
    }, {});

    if (!groupedData) {
      return [{ key: "default", color: "1" }];
    }
    const chartGroupFormatting: IFormatting[] = Object.keys(groupedData).map(
      (item, index) => ({ key: item, color: String(index + 1) })
    );

    return chartGroupFormatting;
  } else {
    return [{ key: "default", color: "1" }];
  }
};
