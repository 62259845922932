import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { toPng } from "html-to-image";
import * as s from "./styled";
import { getPageSettings } from "../../../store/selectors/projects";
import { useSelector } from "react-redux";
import { ReactComponent as DragIcon } from "../../../assets/dragging.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/remove.svg";

interface ThumbnailData {
  id: string;
  image: string;
  pageNumber: number;
}

interface MiniatureNavigationProps {
  containerRef: React.RefObject<HTMLElement>;
  loading: boolean;
  scrollOffset?: number;
  thumbnailDelay?: number;
  maxRetries?: number;
  onReorder: (startIndex: number, endIndex: number) => void;
  onRemove: (id: number, lastIndex: number) => void;
}

const IMAGE_OPTIONS = {
  width: 200,
  height: 258,
  style: {
    transform: "scale(0.2)",
    transformOrigin: "top left",
    width: "1000px",
    height: "1290px",
  },
  quality: 1,
  pixelRatio: 0.8,
  backgroundColor: "#FFFFFF",
  cacheBust: true,
  fontEmbedCSS:
    '@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");',
};

const MiniatureNavigation: React.FC<MiniatureNavigationProps> = ({
  containerRef,
  loading,
  scrollOffset = 137,
  thumbnailDelay = 500,
  maxRetries = 3,
  onReorder,
  onRemove,
}) => {
  const currentPage = useSelector(getPageSettings);
  const [thumbnails, setThumbnails] = useState<ThumbnailData[]>([]);
  const [selectedPage, setSelectedPage] = useState<number | null>(null);
  const [forceRegenerate, setForceRegenerate] = useState(0);
  const elementRefs = useRef<(Element | null)[]>([]);
  const navigationRef = useRef<HTMLDivElement>(null);
  const isScrollingRef = useRef(false);
  const isMouseOverRef = useRef(false);
  const retryCountRef = useRef<{ [key: string]: number }>({});
  const imageVersionRef = useRef<number>(0);

  const delay = (ms: number): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const trackImageChanges = async (element: HTMLElement): Promise<void> => {
    const currentVersion = ++imageVersionRef.current;

    return new Promise((resolve) => {
      const observer = new MutationObserver(async (mutations) => {
        const hasImageChanges = mutations.some((mutation) =>
          Array.from(mutation.addedNodes).some(
            (node) =>
              node instanceof HTMLImageElement ||
              (node instanceof HTMLElement && node.querySelector("img"))
          )
        );

        if (hasImageChanges) {
          observer.disconnect();
          await delay(100);
          if (currentVersion === imageVersionRef.current) {
            resolve();
          }
        }
      });

      observer.observe(element, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ["src"],
      });

      setTimeout(() => {
        observer.disconnect();
        resolve();
      }, thumbnailDelay);
    });
  };

  const loadImage = async (img: HTMLImageElement): Promise<void> => {
    const imgSrc = img.src;
    retryCountRef.current[imgSrc] = retryCountRef.current[imgSrc] || 0;

    return new Promise((resolve, reject) => {
      if (img.complete && img.naturalHeight !== 0) {
        resolve();
        return;
      }

      const loadHandler = () => {
        cleanup();
        resolve();
      };

      const errorHandler = async () => {
        cleanup();
        retryCountRef.current[imgSrc]++;

        if (retryCountRef.current[imgSrc] < maxRetries) {
          try {
            const newSrc = `${imgSrc.split("?")[0]}?t=${Date.now()}&retry=${
              retryCountRef.current[imgSrc]
            }`;
            img.src = newSrc;

            await delay(
              Math.min(
                1000 * Math.pow(2, retryCountRef.current[imgSrc] - 1),
                5000
              )
            );
            await loadImage(img);
            resolve();
          } catch (error) {
            reject(error);
          }
        } else {
          console.warn(
            `Failed to load image after ${maxRetries} retries:`,
            imgSrc
          );
          resolve();
        }
      };

      const cleanup = () => {
        img.removeEventListener("load", loadHandler);
        img.removeEventListener("error", errorHandler);
      };

      img.addEventListener("load", loadHandler);
      img.addEventListener("error", errorHandler);
    });
  };

  const waitForImages = async (element: HTMLElement): Promise<void> => {
    await trackImageChanges(element);

    const images = element.getElementsByTagName("img");
    if (images.length === 0) return;

    const imageLoadPromises = Array.from(images).map((img) => loadImage(img));

    try {
      await Promise.all(imageLoadPromises);
      await delay(thumbnailDelay);
    } catch (error) {
      console.error("Error waiting for images:", error);
    }
  };

  const handleRemove = async (index: number, lastIndex: number) => {
    onRemove(index, lastIndex);
    setForceRegenerate((prev) => prev + 1);

    if (selectedPage !== null) {
      if (selectedPage === index) {
        setSelectedPage(null);
      } else if (selectedPage > index) {
        setSelectedPage(selectedPage - 1);
      }
    }
  };

  const generateThumbnail = async (
    element: Element | null,
    index: number,
    retryCount = 0
  ): Promise<ThumbnailData | null> => {
    if (!element) return null;

    try {
      await delay(100);
      await waitForImages(element as HTMLElement);

      if (!document.contains(element)) {
        throw new Error("Element no longer in document");
      }

      const dataUrl = await toPng(element as HTMLElement, {
        ...IMAGE_OPTIONS,
        cacheBust: true,
        imagePlaceholder:
          "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
      });

      return {
        id: `page-${index + 1}`,
        image: dataUrl,
        pageNumber: index + 1,
      };
    } catch (error) {
      console.error("Error generating thumbnail:", error);

      if (retryCount < maxRetries) {
        await delay(Math.min(1000 * Math.pow(2, retryCount), 5000));
        return generateThumbnail(element, index, retryCount + 1);
      }

      return null;
    }
  };

  const handleThumbnailClick = useCallback(
    (index: number) => {
      const element = elementRefs.current[index];
      setSelectedPage(index);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition =
          elementPosition + window.pageYOffset - scrollOffset;

        isScrollingRef.current = true;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });

        setTimeout(() => {
          isScrollingRef.current = false;
        }, 2000);
      }
    },
    [scrollOffset]
  );

  const getDragDisabled = (index: number) => {
    const { showHeader, tableOfContents } = currentPage;
    const lastIndex = thumbnails.length - 1;

    if (!showHeader && !tableOfContents) return false;

    return (
      (index === lastIndex && (showHeader || tableOfContents)) ||
      (index === 0 && (showHeader || tableOfContents)) ||
      (index === 1 && showHeader && tableOfContents)
    );
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination || !onReorder) return;

    const { source, destination } = result;

    if (getDragDisabled(destination.index)) {
      return;
    }

    const reorderedThumbnails = Array.from(thumbnails);

    const [draggedItem] = reorderedThumbnails.splice(source.index, 1);

    reorderedThumbnails.splice(destination.index, 0, draggedItem);

    const updatedThumbnails = reorderedThumbnails.map((thumbnail, index) => ({
      ...thumbnail,
      pageNumber: index + 1,
      id: `page-${index + 1}`,
    }));

    const convertIndex = (index: number): number => {
      const offset =
        Number(currentPage.tableOfContents) + Number(currentPage.showHeader);
      return index - offset;
    };

    const actualSourceIndex = convertIndex(source.index);
    const actualDestinationIndex = convertIndex(destination.index);

    setThumbnails(updatedThumbnails);

    onReorder(actualSourceIndex, actualDestinationIndex);

    if (selectedPage !== null) {
      if (selectedPage === source.index) {
        setSelectedPage(destination.index);
      } else if (
        selectedPage > source.index &&
        selectedPage <= destination.index
      ) {
        setSelectedPage(selectedPage - 1);
      } else if (
        selectedPage < source.index &&
        selectedPage >= destination.index
      ) {
        setSelectedPage(selectedPage + 1);
      }
    }
  };

  const generateThumbnails = async () => {
    if (!containerRef.current) return;

    imageVersionRef.current++;
    retryCountRef.current = {};

    const sections = {
      firstPage: containerRef.current.querySelector("#first-page"),
      tableOfContent: containerRef.current.querySelector("#table-of-content"),
      content: containerRef.current.querySelector("#content"),
      lastPage: containerRef.current.querySelector("#last-page"),
    };

    const newThumbnails: ThumbnailData[] = [];
    elementRefs.current = [];

    const processSection = async (element: Element | null, index: number) => {
      if (!element) return;
      const thumbnail = await generateThumbnail(element, index);
      if (thumbnail) {
        newThumbnails.push(thumbnail);
        elementRefs.current.push(element);
      }
    };

    let currentIndex = 0;

    try {
      if (sections.firstPage && currentPage.showHeader) {
        await processSection(sections.firstPage, currentIndex++);
      }

      if (sections.tableOfContent && currentPage.tableOfContents) {
        for (const section of Array.from(
          sections.tableOfContent.children || []
        )) {
          await processSection(section as Element, currentIndex++);
        }
      }

      if (sections.content) {
        for (const section of Array.from(sections.content.children || [])) {
          await processSection(section as Element, currentIndex++);
        }
      }

      if (sections.lastPage && currentPage.showHeader) {
        await processSection(sections.lastPage, currentIndex++);
      }

      const currentVersion = imageVersionRef.current;
      if (newThumbnails.length > 0) {
        setThumbnails((prevThumbnails) => {
          if (currentVersion === imageVersionRef.current) {
            return newThumbnails;
          }
          return prevThumbnails;
        });
      }
    } catch (error) {
      console.error("Error generating thumbnails:", error);
    }
  };

  useEffect(() => {
    if (loading) return;

    generateThumbnails();

    const handleScroll = () => {
      if (isScrollingRef.current || isMouseOverRef.current) return;

      if (containerRef.current && navigationRef.current) {
        const containerHeight = containerRef.current.scrollHeight;
        const navigationHeight = navigationRef.current.scrollHeight;
        const scrollPercentage =
          window.scrollY / (containerHeight - window.innerHeight);
        const scrollPosition =
          scrollPercentage *
          (navigationHeight - navigationRef.current.clientHeight);
        navigationRef.current.scrollTop = scrollPosition;

        const viewportMiddle = window.innerHeight / 2;
        let closestElement = null;
        let closestDistance = Infinity;
        elementRefs.current.forEach((el, index) => {
          if (el) {
            const rect = el.getBoundingClientRect();
            const distance = Math.abs(
              rect.top + rect.height / 2 - viewportMiddle
            );
            if (distance < closestDistance) {
              closestDistance = distance;
              closestElement = index;
            }
          }
        });
        if (closestElement !== null) {
          setSelectedPage(closestElement);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    containerRef,
    loading,
    currentPage,
    thumbnailDelay,
    maxRetries,
    forceRegenerate,
  ]);

  return (
    <s.NavigationContainer
      ref={navigationRef}
      onMouseEnter={() => (isMouseOverRef.current = true)}
      onMouseLeave={() => (isMouseOverRef.current = false)}
    >
      {!thumbnails.length ? (
        <>
          {Array.from({ length: 6 }).map((_, index) => (
            <s.SkeletonContainer key={index}>
              <s.Skeleton />
              <s.PageNumber>{index + 1}.</s.PageNumber>
            </s.SkeletonContainer>
          ))}
        </>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="thumbnails">
            {(provided) => (
              <s.DraggableArea
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {thumbnails.map((thumbnail, index) => {
                  const isDragDisabled = getDragDisabled(index);

                  return (
                    <Draggable
                      key={thumbnail.id}
                      draggableId={thumbnail.id}
                      index={index}
                      isDragDisabled={isDragDisabled}
                    >
                      {(provided, snapshot) => (
                        <s.ThumbnailContainer
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                        >
                          <s.Thumbnail
                            $isSelected={selectedPage === index}
                            $isDragDisabled={isDragDisabled}
                            $isDragging={snapshot.isDragging}
                            onClick={() => handleThumbnailClick(index)}
                          >
                            {!isDragDisabled && (
                              <s.DragIconWrapper
                                $isDragging={snapshot.isDragging}
                              >
                                <s.HoverBar />
                                <DragIcon />
                              </s.DragIconWrapper>
                            )}
                            {thumbnails.length !== 1 && <s.RemoveButton
                              onClick={() =>
                                handleRemove(index, thumbnails.length - 1)
                              }
                            >
                              <RemoveIcon />
                            </s.RemoveButton>}
                            <s.ThumbnailImage
                              src={thumbnail.image}
                              alt={`Page ${thumbnail.pageNumber}`}
                              draggable={false}
                            />
                          </s.Thumbnail>
                          <s.PageNumber $isDragDisabled={isDragDisabled}>
                            {thumbnail.pageNumber}.
                          </s.PageNumber>
                        </s.ThumbnailContainer>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </s.DraggableArea>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </s.NavigationContainer>
  );
};

export default MiniatureNavigation;
