import { Heading, TextAreaContainer, TextAreaWrapper, StyledTextArea } from "./styles";

interface CustomTextAreaInterface {
  value: string;
  heading?: string;
  name: string;
  onChange: (e: string) => void;
  disablePaddings?: boolean;
  placeholder?: string;
  size?: "default" | "base";
  rows?: number; // To control textarea rows
}

export const CustomTextArea = ({
  value,
  heading,
  name,
  onChange,
  disablePaddings = false,
  size = "default",
  placeholder = "Type text here...",
  rows = 3,
}: CustomTextAreaInterface) => {
  return (
    <TextAreaContainer $disablePaddings={disablePaddings}>
      <Heading> {heading && heading}</Heading>
      <TextAreaWrapper $disablePaddings={disablePaddings} $size={size}>
        <StyledTextArea
          $filled={!!value}
          $size={size}
          name={name}
          value={value}
          rows={rows}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      </TextAreaWrapper>
    </TextAreaContainer>
  );
};
