import React, { useState } from "react";
import {
  AddedOn,
  CheckboxWrapper,
  EmailAddress,
  LastActive,
  More,
  Name,
  Position,
  Projects,
  Team,
  Chip,
  UserDataRow,
  UserAvatar,
  Role,
  TeamsPopUp,
} from "./styled";
import { CheckBox } from "../../../Inputs/CustomCheckbox/CheckBox";
import { ThreeDots } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { selectNewUser } from "../../../../store/slices/userManagement";
import { getSelectedUsers } from "../../../../store/selectors/userManagement";
import { createPortal } from "react-dom";
import useMeasure from "react-use-measure";
import UserRowMenu from "../UserRowMenu";

type Props = {
  uuid: string;
  avatar: any;
  name: string;
  email: string;
  position: string;
  team?: string[];
  role?: string;
  projects: number;
  addedOn: string;
  lastActive: string;
};

interface IPopUp {
  coords: {
    pageX: any;
    pageY: any;
    clientX: any;
    clientY: any;
  };
}

const UsersRow = ({
  uuid,
  avatar,
  name,
  email,
  position,
  team,
  role,
  projects,
  addedOn,
  lastActive,
}: Props) => {
  const dispatch = useDispatch();
  const [ref, bounds] = useMeasure();

  const selectedUsers = useSelector(getSelectedUsers);

  const [tooltip, setTooltip] = useState<IPopUp | null>(null);

  const [menu, setMenu] = useState<IPopUp | null>(null);

  const handleSelect = () => {
    dispatch(selectNewUser(uuid));
  };

  return (
    <>
      <UserDataRow $selected={selectedUsers.includes(uuid)}>
        <CheckboxWrapper>
          <CheckBox
            onChange={handleSelect}
            checked={selectedUsers.includes(uuid)}
          />
        </CheckboxWrapper>
        <Name>
          <UserAvatar src={avatar} key={name + email + position} />
          {name}
        </Name>
        <EmailAddress>{email}</EmailAddress>
        {!!role && (
          <Role>
            <Chip $role={role}>{role}</Chip>
          </Role>
        )}

        <Position>{position}</Position>
        {!!team && (
          <Team>
            {!team.length && <Chip $disabled>No Team</Chip>}

            {team.slice(0, 1).map((item) => (
              <Chip>{item}</Chip>
            ))}

            {team.length - 1 > 0 && (
              <Chip
                onMouseMove={(event: any) => {
                  const { pageX, pageY, clientX, clientY } = event;
                  const coords = { pageX, pageY, clientX, clientY };

                  setTooltip({ coords });
                }}
                onMouseLeave={() => setTooltip(null)}
              >
                +{team.length - 1}
              </Chip>
            )}
          </Team>
        )}
        <Projects>
          <Chip>{projects}</Chip>
        </Projects>
        <AddedOn>{addedOn}</AddedOn>
        <LastActive>{lastActive}</LastActive>
        <More>
          <ThreeDots
            onClick={(event: any) => {
              const { pageX, pageY, clientX, clientY } = event;
              const coords = { pageX, pageY, clientX, clientY };

              setMenu({ coords });
            }}
          />
        </More>
      </UserDataRow>
      {tooltip &&
        createPortal(
          <TeamsPopUp
            ref={ref}
            $left={tooltip.coords.pageX - 20}
            $top={
              tooltip.coords.pageY - (bounds.height ? bounds.height + 15 : 150)
            }
            $opacity={Number(!!bounds.height)}
          >
            {team?.map((item) => (
              <Chip>{item}</Chip>
            ))}
          </TeamsPopUp>,
          document.body
        )}
      {menu &&
        createPortal(
          <UserRowMenu cords={menu.coords} setMenu={setMenu} />,
          document.body
        )}
    </>
  );
};

export default UsersRow;
