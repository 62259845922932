import React, { useRef } from "react";
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import { ActionButton, ActionsPage, DimensionSettingsWrapper } from "./styles";
import { useOnClickOutside } from "usehooks-ts";
import {
  ManagementUserList,
  SortedColumn,
} from "../../pages/UserManagment/pages/Users/data/mock";
import {
  ILastAction,
  IMenuData,
} from "../UserManagementComponents/Users/UsersList";
import { sortTableAscending, sortTableDescending } from "./utils/sorting";

type Props = {
  lastAction: ILastAction;
  setLastAction: React.Dispatch<React.SetStateAction<ILastAction>>;
  columnMenu: IMenuData;
  setColumnMenu: React.Dispatch<React.SetStateAction<IMenuData | null>>;
  usersData: ManagementUserList[];
  setUsersList: React.Dispatch<React.SetStateAction<ManagementUserList[]>>;
};

const UsersTableMenu = ({
  lastAction,
  setLastAction,
  columnMenu: { top, left, key },
  setColumnMenu,
  usersData,
  setUsersList,
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const handleLastAction = (action: "sortA" | "sortD", key: SortedColumn) => {
    if (["sortA", "sortD"].includes(action)) {
      setLastAction({
        key,
        ascending: action === "sortA",
        descending: action === "sortD",
      });
    }
  };

  useOnClickOutside([ref], () => setColumnMenu(null));

  return (
    <DimensionSettingsWrapper $top={top} $left={left} ref={ref}>
      <ActionsPage>
        <ActionButton
          $active={lastAction.key === key && lastAction.ascending}
          onClick={() => {
            sortTableAscending(key, usersData, setUsersList);
            setColumnMenu(null);
            handleLastAction("sortA", key);
          }}
        >
          <ArrowUp />
          Sort Ascending
        </ActionButton>
        <ActionButton
          $active={lastAction.key === key && lastAction.descending}
          onClick={() => {
            sortTableDescending(key, usersData, setUsersList);
            setColumnMenu(null);
            handleLastAction("sortD", key);
          }}
        >
          <ArrowDown />
          Sort Descending
        </ActionButton>
      </ActionsPage>
    </DimensionSettingsWrapper>
  );
};

export default UsersTableMenu;
