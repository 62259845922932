import { useDispatch, useSelector } from 'react-redux';
import { WidgetItem } from '../../../models/Widgets';
import { setPageWidgets } from '../../../store/slices/projectPages';
import { requestUpdateWidgets } from '../../../store/slices/widgets';
import { getPageSettings } from '../../../store/selectors/projects';

interface UpdateWidgetDTO {
  id: string;
  blockId: string;
}

interface ReorderResult {
  updatedData: WidgetItem[][];
  sourceBlock: WidgetItem[];
  destinationBlock: WidgetItem[];
}

const getRequestType = (chartType: string): string => {
  const typeMap: Record<string, string> = {
    mapChart: 'mapChart',
    areaChart: 'areaChart',
    lineChart: 'lineChart',
    sparkLineChart: 'sparkLineChart',
    sparkAreaChart: 'sparkAreaChart',
    barChart: 'barChart',
    lollipopChart: 'lollipopChart',
    sankey: 'sankeyChart',
    matrix: 'matrixChart',
    polarAreaChart: 'polarAreaChart',
    punchcardChart: 'punchcardChart',
    radar: 'radarChart',
    scatterplot: 'scatterplotChart',
    bubbleChart: 'bubbleChart',
    radialBarChart: 'radialBarChart',
  };

  return typeMap[chartType] || '';
};

const chunkArray = <T,>(array: T[], size: number): T[][] => {
  const chunks: T[][] = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

const reorderBlocks = (
  data: WidgetItem[][],
  source: number,
  destination: number
): ReorderResult => {
  const newData: WidgetItem[][] = JSON.parse(JSON.stringify(data));
  const originalBlockIds = newData.map(block => 
    block.map(item => item.blockId)
  );

  const sourceContent = JSON.parse(JSON.stringify(data[source]));
  
  if (source < destination) {
    for (let i = source; i < destination; i++) {
      newData[i] = newData[i + 1].map((item, index) => ({
        ...item,
        blockId: originalBlockIds[i][index]
      }));
    }
    
    newData[destination] = sourceContent.map((item: WidgetItem, index: number) => ({
      ...item,
      blockId: originalBlockIds[destination][index]
    }));
  } else {
    for (let i = source; i > destination; i--) {
      newData[i] = newData[i - 1].map((item, index) => ({
        ...item,
        blockId: originalBlockIds[i][index]
      }));
    }
    
    newData[destination] = sourceContent.map((item: WidgetItem, index: number) => ({
      ...item,
      blockId: originalBlockIds[destination][index]
    }));
  }

  return {
    updatedData: newData,
    sourceBlock: newData[source],
    destinationBlock: newData[destination],
  };
};

export const useChartWidgetsReorder = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector(getPageSettings);

  const handleDragEnd = (
    data: WidgetItem[][],
    source: number,
    destination: number
  ) => {
    const reorderResult = reorderBlocks(data, source, destination);

    if (source === destination) {
      return;
    }

    const chartRequests: Record<string, UpdateWidgetDTO[]> = {
      barChart: [],
      lineChart: [],
      sparkLineChart: [],
      sparkAreaChart: [],
      lollipopChart: [],
      sankeyChart: [],
      mapChart: [],
      areaChart: [],
      matrixChart: [],
      scatterplotChart: [],
      radarChart: [],
      punchcardChart: [],
      polarAreaChart: [],
      bubbleChart: [],
      radialBarChart: [],
    };

    const dataFlat = reorderResult?.updatedData.flat() || [];

    dataFlat.forEach((chart) => {
      if (!chart?.chartType) return;

      const requestType = getRequestType(chart.chartType);
      if (requestType && chartRequests[requestType]) {
        chartRequests[requestType].push({
          id: chart.id,
          blockId: String(chart.blockId),
        });
      }
    });

    const totalCharts = Object.values(chartRequests).reduce(
      (sum, arr) => sum + arr.length,
      0
    );

    if (totalCharts > 0) {
      dispatch(setPageWidgets({ items: dataFlat, count: dataFlat?.length }));
      dispatch(
        requestUpdateWidgets({
          barChart: chartRequests.barChart,
          lineChart: chartRequests.lineChart,
          sparkLineChart: chartRequests.sparkLineChart,
          sparkAreaChart: chartRequests.sparkAreaChart,
          lollipopChart: chartRequests.lollipopChart,
          sankeyChart: chartRequests.sankeyChart,
          areaChart: chartRequests.areaChart,
          matrixChart: chartRequests.matrixChart,
          mapChart: chartRequests.mapChart,
          scatterplotChart: chartRequests.scatterplotChart,
          polarAreaChart: chartRequests.polarAreaChart,
          punchcardChart: chartRequests.punchcardChart,
          radarChart: chartRequests.radarChart,
          bubbleChart: chartRequests.bubbleChart,
          radialBarChart: chartRequests.radialBarChart,
          pageId: currentPage.id!,
        }))
    }
  };

  const groupItems = (items: WidgetItem[]) => {
    return chunkArray(items, 2);
  };

  return {
    handleDragEnd,
    groupItems,
  };
};