import { Person, People } from "react-bootstrap-icons";

export const managementPaths = [
  {
    icon: <Person />,
    title: "Users",
    path: "/management/users",
  },
  {
    icon: <People />,
    title: "Teams",
    path: "/management/teams",
  },
];
