import { useDispatch, useSelector } from "react-redux";
import {
  CloseIcon,
  CloseWrapper,
  KPIWrapperBorder,
  KPIWrapperMainContainer,
  KPIWrapperRow,
} from "./styles";
import { getIsEditMode, getIsPublicMode } from "../../store/selectors/main";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  SettingsButtonWrapper,
} from "../Widgets/VerticalBarchart/styles";
import { getActiveModal } from "../../store/selectors/modals";
import { setActiveModal } from "../../store/slices/modals";
import { AiKpiDto } from "../../models/Widgets";
import { KPIComponentWrapper } from "../KPIComponentWrapper";
import { getAiKpis } from "../../store/selectors/widgets";
import {
  requestDeleteKpis,
  requestPageKpis,
} from "../../store/slices/projectPages";
import { getPageSettings } from "../../store/selectors/projects";
import { FC, useState } from "react";

export interface KPISettingsWrapperStoryProps {
  kpis: AiKpiDto[];
  isComparison?: boolean;
  recommended?: boolean;
  kpiId?: string;
}

export const KPISettingsWrapperStory: FC<KPISettingsWrapperStoryProps> = ({
  kpis,
  isComparison,
  recommended = true,
  kpiId = "kpi_id",
}) => {
  const dispatch = useDispatch();
  const currentPage = useSelector(getPageSettings);
  const isEditMode = useSelector(getIsEditMode);
  const isPublicMode = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const aiKpis = useSelector(getAiKpis);
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    for (let kpi of kpis) {
      dispatch(requestDeleteKpis({ id: kpi.id }));
    }
    setTimeout(() => {
      if (currentPage?.id) {
        dispatch(
          requestPageKpis({
            pageId: currentPage?.id,
            includeData: true,
          })
        );
      }
    }, 500);
  };

  const handleOpen = () => {
    if (isPublicMode) {
      return;
    }
    setOpen(true);
  };
  const handleClose = () => {
    if (isPublicMode) {
      return;
    }
    setOpen(false);
  };

  return (
    <KPIWrapperMainContainer
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      $isComparison={isComparison}
    >
      <KPIWrapperRow>
        {kpis?.map((kpi, key) => {
          return (
            <KPIWrapperBorder key={kpi.id}>
              <KPIComponentWrapper
                key={kpi.id}
                kpi={kpi}
                recommended={false}
                selected={false}
                size={isComparison ? "lg" : key === 0 ? "sm" : "md"}
                kpiId={kpiId}
                adaptiveWidth
                settings={
                  recommended &&
                  !isPublicMode &&
                  isEditMode &&
                  kpis.length - 1 === key
                }
              />
            </KPIWrapperBorder>
          );
        })}
      </KPIWrapperRow>
      {recommended && !isPublicMode && isEditMode ? (
        // <KPISettings>
        //   <SettingsButtonWrapper
        //     $modalOpen={!!activeModal?.length}
        //     onClick={() => {
        //       dispatch(setActiveModal({ id: "recommendedKpisModal" }));
        //     }}
        //   >
        //     <DatavizRecommendedCount>{aiKpis?.length}</DatavizRecommendedCount>
        //     <DatavizSettingsIcon />
        //   </SettingsButtonWrapper>
        // </KPISettings>

        <CloseWrapper>
          <SettingsButtonWrapper
            $modalOpen={!!activeModal?.length}
            onClick={() => {
              dispatch(setActiveModal({ id: "recommendedKpisModal" }));
            }}
          >
            <DatavizRecommendedCount>{aiKpis?.length}</DatavizRecommendedCount>
            <DatavizSettingsIcon />
          </SettingsButtonWrapper>
          {open && (
            <CloseIcon
              onClick={() => {
                handleDelete();
              }}
            />
          )}
        </CloseWrapper>
      ) : null}
    </KPIWrapperMainContainer>
  );
};
