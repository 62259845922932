import { useDispatch, useSelector } from "react-redux";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  TextAndImageHeadingContent,
  TextAndImageHeading,
  TextAndImageHighlights,
} from "../../../ReportContent/styles";
import { SectionWrapper } from "../../../ReportContent/styles";
import { getIsEditMode } from "../../../../../../store/selectors/main";
import {
  getPageBanner,
  getPageSettings,
} from "../../../../../../store/selectors/projects";
import { requestUpdatePage } from "../../../../../../store/slices/projectPages";
import {
  BlockLoader,
  BorderedSettingsBlock,
  SettingsWrapper,
} from "../../styles";
import { CenterIcon, LeftIcon, RightIcon } from "../../icons/TextPositionIcons";
import {
  requestDownloadFile,
  requestUploadSimpleFile,
} from "../../../../../../store/slices/files";
import { TextLoader } from "../../../../../LoaderText";
import { IPageHeader } from "../../../../../../store/slices/pageContentGenerator";

interface Props {
  currentPageId: string;
  contentItem?: IPageHeader;
  setContentItem: (item: IPageHeader) => void;
  file: undefined;
  setFile: Dispatch<SetStateAction<undefined>>;
}

const TextImage: React.FC<Props> = ({
  currentPageId,
  contentItem,
  setContentItem,
  file,
  setFile,
}) => {
  const currentPage = useSelector(getPageSettings);
  const isEditMode = useSelector(getIsEditMode);
  const header = useSelector(getPageBanner);

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const contentItemJson = JSON.stringify(contentItem);
    const headerJson = JSON.stringify(header);
    if (headerJson !== contentItemJson) {
      dispatch(
        requestUpdatePage({
          ...currentPage,
          header: {
            ...contentItem,
            image: contentItem?.backgroundColor
              ? undefined
              : contentItem?.image,
          },
        })
      );
      if (contentItem?.backgroundColor) {
        setFile(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentItem]);

  useEffect(() => {
    if (file && typeof file !== "string") {
      try {
        dispatch(
          requestUploadSimpleFile({
            files: [file],
            callbacks: {
              onSuccess: (resp) => {
                const uploadedFile = resp?.at(0);
                if (uploadedFile) {
                  dispatch(
                    requestUpdatePage({
                      ...currentPage,
                      header: {
                        ...header,
                        image: uploadedFile.path,
                        backgroundColor: undefined,
                      },
                    })
                  );
                }
              },
            },
          })
        );
      } catch (e) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (header?.image) {
      dispatch(
        requestDownloadFile({
          path: header?.image,
          callbacks: {
            onSuccess: (resp) => {
              setFile(resp.url);
            },
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [header?.image]);

  return (
    <div id={currentPageId}>
      <SectionWrapper
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        // $backgroundColor={contentItem?.backgroundColor}
        // $imageUrl={typeof file === "string" ? file : undefined}
        // $gradient={contentItem?.gradient}
        // $rgbaColor={contentItem?.rgbaColor}
        $isEditMode={isEditMode}
        spellCheck={false}
      >
        {isEditMode && (
          <>
            <SettingsWrapper $hover={open}>
              <BorderedSettingsBlock>
                <LeftIcon
                  type={contentItem?.align}
                  onClick={() => {
                    setContentItem({ ...contentItem, align: "left" });
                  }}
                />
                <CenterIcon
                  type={contentItem?.align}
                  onClick={() => {
                    setContentItem({ ...contentItem, align: "center" });
                  }}
                />
                <RightIcon
                  type={contentItem?.align}
                  onClick={() => {
                    setContentItem({ ...contentItem, align: "right" });
                  }}
                />
              </BorderedSettingsBlock>
            </SettingsWrapper>
          </>
        )}

        <TextAndImageHeadingContent $alignment={contentItem?.align}>
          {contentItem?.title ? (
            <TextAndImageHeading
              defaultValue={contentItem?.title}
              spellCheck={false}
              contentEditable={isEditMode}
              $alignment={contentItem?.align}
              $isEditMode={isEditMode}
              onChange={(e) => {
                setContentItem({ ...contentItem, title: e.target.value });
              }}
              maxLength={59}
            />
          ) : (
            <div style={{ marginTop: "80px", width: "400px" }}>
              <BlockLoader>
                <TextLoader opacity={0.6} />
                <TextLoader opacity={0.6} />
                <TextLoader opacity={0.6} />
              </BlockLoader>
            </div>
          )}
          {contentItem?.description ? (
            <TextAndImageHighlights
              defaultValue={contentItem?.description}
              spellCheck={false}
              contentEditable={isEditMode}
              $alignment={contentItem?.align}
              $isEditMode={isEditMode}
              maxLength={240}
              onChange={(e) =>
                setContentItem({ ...contentItem, description: e.target.value })
              }
            />
          ) : (
            <div style={{ marginTop: "24px" }}>
              <BlockLoader>
                <TextLoader opacity={0.6} />
                <TextLoader opacity={0.6} />
                <TextLoader opacity={0.6} />
              </BlockLoader>
            </div>
          )}
        </TextAndImageHeadingContent>
      </SectionWrapper>
    </div>
  );
};

export default TextImage;
