import { getQualitativePalette } from "../../../constants/colors";
import { WidgetItem } from "../../../models/Widgets";
import { IMarkers } from "../../MapSettings/types/types";

export const getCurrentMarker = (
  widget: WidgetItem,
  key: any,
  defaultMarker?: IMarkers
) => {
  const markers = widget?.markers ?? [];

  if (!markers.length) {
    return defaultMarker || "donut";
  }

  const foundMarker = markers.find((item) => item.key === key);

  return foundMarker?.shape ?? (defaultMarker || "donut");
};

export const getCurrentColorV2 = (
  widget: WidgetItem,
  key: any,
  styleId?: string
) => {
  const colors = widget?.formatting ?? [];

  if (!colors.length) {
    return "#ffffff00";
  }

  const getPalettes = getQualitativePalette(colors.length);
  
  const foundColor = Number(colors.find((item) => item.key === key)?.color ?? 1);  

  if (Array.isArray(getPalettes)) {
    const paletteID = widget.palette?.paletteId ?? "Qualitative2Colors1";
    
    return (
      getPalettes
        .find((item) => item.id === paletteID)
        ?.colors.find((item) => item.key === String(foundColor))?.hex ??
        "#0d00ff"
    );
  } else {
    return (
      getPalettes.colors.find((item) => item.key === String(foundColor))?.hex ??
      "#ff0000"
    );
  }
};

// export const getCurrentColor = (widget: WidgetItem, key: any, styleId: string) => {
//   const colors = widget?.formatting ?? [];

//   if (!colors.length) {
//     return 'blue';
//   }

//   const foundColor = Number(colors.find((item) => item.key === key)?.color) ?? 1;

//   const currentColor = colorsArray.find((item) => item.id === styleId)?.colors.find((color) => color.key === foundColor)?.hex ?? 'red'

//   return currentColor;
// }
