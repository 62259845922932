import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryGetComparison } from "../../api/comparison";
import { KpiItemsDTO, WidgetsItemsDTO } from "../../models/Widgets";

export interface ComparisonState {
  comparisonName: string;
  selectedMeasure: string | null;
  selectedPopulation: string | null;
  widgets: WidgetsItemsDTO;
  isLoading?: boolean;
  kpis: KpiItemsDTO;
  error: string | null;
}

const initialState: ComparisonState = {
  comparisonName: "Comparison 1",
  selectedMeasure: null,
  selectedPopulation: null,
  widgets: {
    items: [],
    count: 0
  },
  kpis: { items: [], count: 0 },
  isLoading: false,
  error: null,
};

const comparisonSlice = createSlice({
  name: "comparisonSlice",
  initialState,
  reducers: {
    updateComparisonName: (state, action: PayloadAction<string>) => {
      state.comparisonName = action.payload;
    },
    setSelectedMeasure: (state, action: PayloadAction<string | null>) => {
      state.selectedMeasure = action.payload;
    },
    setSelectedPopulation: (state, action: PayloadAction<string | null>) => {
      state.selectedPopulation = action.payload;
    },
    requestComparisonWidgets: (
      state,
      action: PayloadAction<QueryGetComparison>
    ) => {
      state.isLoading = true;
    },
    requestPublicComparisonWidgets: (
      state,
      action: PayloadAction<QueryGetComparison>
    ) => {
      state.isLoading = true;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setComparisonWidgets: (state, action: PayloadAction<WidgetsItemsDTO>) => {
      state.widgets = action.payload
      state.isLoading = false
    },
    setComparisonKpis: (state, action: PayloadAction<KpiItemsDTO>) => {
      state.kpis = action.payload
      state.isLoading = false
    },
  },
});

export const {
  updateComparisonName,
  setSelectedMeasure,
  requestComparisonWidgets,
  setError,
  setComparisonKpis,
  setComparisonWidgets,
  setSelectedPopulation,
  requestPublicComparisonWidgets,
} = comparisonSlice.actions;
export default comparisonSlice.reducer;
