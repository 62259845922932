import React, { useEffect, useState } from "react";
import { THElement } from "../../components/CreateProjectDataTable/components/THElement";
import {
  Container,
  TableBody,
  TableHeader,
} from "../../components/CreateProjectDataTable/style";
import { TRElement } from "../../components/CreateProjectDataTable/components/TRElement";
import { MeasuresType } from "../../../../CreateComparison/utils";
import SortingMenu from "../../../../../SortMenuTable/SortTableMenu"; // Import SortingMenu

type Props = {
  populationSelected?: string;
  search?: string;
  setPopulationsSet: any;
  allMeasures: MeasuresType[];
};

const SelectPopulationStep: React.FC<Props> = ({
  populationSelected,
  setPopulationsSet,
  search,
  allMeasures,
}: Props) => {
  const [totalPopulations, setPopulations] = useState<any[]>([]);
  const [currentPopulations, setCurrentPopulations] = useState<any[]>([]);
  const [columnMenu, setColumnMenu] = useState<{
    top: number;
    left: number;
    key: string;
  } | null>(null);
  const [activeSort, setActiveSort] = useState<{ key: string; order: "asc" | "desc" }>({
    key: "",
    order: "asc",
  });

  useEffect(() => {
    const newValue = search?.trim()?.toLowerCase();
    if (newValue) {
      const populations = totalPopulations?.filter((r) => {
        const bucket: string = r?.bucket?.toLowerCase();
        const program: string = r?.program?.toLowerCase();
        return program?.includes(newValue) || bucket?.includes(newValue);
      });
      setCurrentPopulations(populations);
    } else {
      if (totalPopulations?.length) {
        setCurrentPopulations(totalPopulations);
      } else {
        const populations = allMeasures.reduce((t: any[], p) => {
          if (!t.some((r) => r.bucket === p.bucket)) {
            return [...t, p];
          }
          return t;
        }, []);
        setCurrentPopulations(populations);
        setPopulations(populations);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const columns = [
    { key: "bucket", label: "Population name", size: "367px" },
    { key: "measures", label: "Measures", size: "91px", getValue: (item: any) => allMeasures.filter((m) => m.bucket === item.bucket).length }
  ];

  const handleColumnClick = (event: React.MouseEvent, key: string) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const top = rect.bottom;
    const left = rect.left;
    setColumnMenu({ top: top + 55, left: left - 10, key });
  };

  return (
    <Container key={"second"}>
      <TableHeader>
        {columns.map((column) => (
          <THElement
            text={column.label}
            key={column.key}
            size={column?.size}
            onClick={(e) => handleColumnClick(e, column.key)}
          />
        ))}
      </TableHeader>
      {currentPopulations?.length ? (
        <TableBody>
          {currentPopulations?.map((item: any) => {
            const measures = allMeasures?.filter((m) => m.bucket === item.bucket);
            return (
              <TRElement
                key={item.bucket}
                id={item.bucket}
                header={item.bucket}
                subHeader={item.program}
                measures={measures?.length ?? 0}
                selected={populationSelected === item.bucket}
                selectHandler={() => {
                  setPopulationsSet(item.bucket);
                }}
                fields={[]}
              />
            );
          })}
        </TableBody>
      ) : null}
      {columnMenu && (
        <SortingMenu
          top={columnMenu.top}
          left={columnMenu.left}
          data={currentPopulations}
          setData={setCurrentPopulations}
          columns={columns}
          activeSort={activeSort}
          setActiveSort={setActiveSort}
          closeMenu={() => setColumnMenu(null)}
          columnKey={columnMenu.key}
        />
      )}
    </Container>
  );
};

export default SelectPopulationStep;
