import { useState, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle } from "react-bootstrap-icons";

import { ReactComponent as DashboardIcon } from "../../assets/dashboard.svg";
import { ReactComponent as ReportIcon } from "../../assets/Report.svg";
import { ReactComponent as StorytellingIcon } from "../../assets/storytelling-color.svg";
import { ReactComponent as ComparisonIcon } from "../../assets/comparison-type.svg";

import { setActiveModal } from "../../store/slices/modals";
import { Button } from "../Button";
import * as s from "./styles";
import { setComparisonActionType, setReportActionType } from "../../store/slices/appConfig";
import { getDraftPageSettings } from "../../store/selectors/projects";
import { setDraftPageSettings } from "../../store/slices/projectPages";

const enum PageTypeEnum {
  DASHBOARD = "dashboard",
  STORYTELLING = "storytelling",
  COMPARISON = "comparison",
  REPORT = "report",
}

export const SelectPageType: FC = () => {
  const [selectedOption, setSelectedOption] = useState<PageTypeEnum>();

  const pageDraftSettings = useSelector(getDraftPageSettings);

  const dispatch = useDispatch();

  const handleConfirm = () => {
    if (selectedOption === PageTypeEnum.DASHBOARD) {
      dispatch(
        setDraftPageSettings({
          ...pageDraftSettings,
          dashType: PageTypeEnum.DASHBOARD,
        })
      );
      dispatch(
        setActiveModal({
          id: "projectSettingsModal",
          createOptions: true,
        })
      );
      dispatch(setReportActionType("unset"));
      dispatch(setComparisonActionType("unset"));
      return;
    }
    if (selectedOption === PageTypeEnum.STORYTELLING) {
      dispatch(
        setDraftPageSettings({
          ...pageDraftSettings,
          dashType: PageTypeEnum.STORYTELLING,
        })
      );
      dispatch(
        setActiveModal({
          id: "projectSettingsModal",
          createOptions: true,
        })
      );
      dispatch(setReportActionType("unset"));
      dispatch(setComparisonActionType("unset"));
      return;
    }
    if (selectedOption === PageTypeEnum.COMPARISON) {
      dispatch(setComparisonActionType("create"));
      dispatch(setReportActionType("unset"));

      return;
    }
    dispatch(setComparisonActionType("unset"));
    dispatch(setReportActionType("create"));
  };

  return (
    <s.Container>
      <s.Title>Select page type</s.Title>
      <s.OptionsContainer>
        <s.Option
          $selected={selectedOption === PageTypeEnum.DASHBOARD}
          onClick={() => setSelectedOption(PageTypeEnum.DASHBOARD)}
        >
          <DashboardIcon />
          <s.RadioButtonWrapper>
            {selectedOption === PageTypeEnum.DASHBOARD ? (
              <s.ActiveRadioButton />
            ) : (
              <>
                <s.HoveredRadioButton />
                <s.InactiveRadioButton />
              </>
            )}
          </s.RadioButtonWrapper>
          <h3>Dashboard</h3>
          <p>
            Present data from different sources in a easily understandable
            manner. Interact with dashboard elements to drill down into details.
          </p>
        </s.Option>
        <s.Option
          $selected={selectedOption === PageTypeEnum.STORYTELLING}
          onClick={() => setSelectedOption(PageTypeEnum.STORYTELLING)}
        >
          <StorytellingIcon />
          <s.RadioButtonWrapper>
            {selectedOption === PageTypeEnum.STORYTELLING ? (
              <s.ActiveRadioButton />
            ) : (
              <>
                <s.HoveredRadioButton />
                <s.InactiveRadioButton />
              </>
            )}
          </s.RadioButtonWrapper>
          <h3>Storytelling</h3>
          <p>
            A storytelling dashboard is a specialized type of dashboard designed
            to present data and information in a narrative or storytelling
            format.
          </p>
        </s.Option>
        <s.Option
          $selected={selectedOption === PageTypeEnum.COMPARISON}
          onClick={() => setSelectedOption(PageTypeEnum.COMPARISON)}
        >
          <ComparisonIcon />
          <s.RadioButtonWrapper>
            {selectedOption === PageTypeEnum.COMPARISON ? (
              <s.ActiveRadioButton />
            ) : (
              <>
                <s.HoveredRadioButton />
                <s.InactiveRadioButton />
              </>
            )}
          </s.RadioButtonWrapper>
          <h3>Comparison</h3>
          <p>
            A clear overview of multiple dimensions based on measures that helps define the key differences and similarities between them.
          </p>
        </s.Option>
        <s.Option
          $selected={selectedOption === PageTypeEnum.REPORT}
          onClick={() => setSelectedOption(PageTypeEnum.REPORT)}
        >
          <ReportIcon />
          <s.RadioButtonWrapper>
            {selectedOption === PageTypeEnum.REPORT ? (
              <s.ActiveRadioButton />
            ) : (
              <>
                <s.HoveredRadioButton />
                <s.InactiveRadioButton />
              </>
            )}
          </s.RadioButtonWrapper>
          <h3>Report</h3>
          <p>
            In-depth analysis and evaluations of data to inform any type of
            audience. Key findings of the project in a user-friendly manner.
          </p>
        </s.Option>
      </s.OptionsContainer>
      <Button
        onClick={handleConfirm}
        icon={<CheckCircle />}
        variant={selectedOption ? "secondary" : "disabled"}
        size="medium"
        name="Confirm"
        disabled={!selectedOption}
      />
    </s.Container>
  );
};
