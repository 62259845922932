import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type ReportActionType = "create" | "update" | "unset";
export type ComparisonActionType = "create" | "update" | "unset";

export interface AppConfig {
  selectedTheme: "light" | "dark";
  isPublicRoute: boolean;
  isEditMode: boolean;
  reportActionType: ReportActionType;
  isPageTypeSelect: boolean;
  pageIdSelected: string | null;
  comparisonActionType: ComparisonActionType;
  isCustomCursor: boolean;
}

// Correct initial state
const initialState: AppConfig = {
  selectedTheme: "light",
  isPublicRoute: false,
  isEditMode: false,
  reportActionType: "unset",
  isPageTypeSelect: false,
  pageIdSelected: null,
  comparisonActionType: "unset",
  isCustomCursor: false,
};

// Create the slice
const appSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<"light" | "dark">) => {
      state.selectedTheme = action.payload;
    },
    setIsPublic: (state, action: PayloadAction<boolean>) => {
      state.isPublicRoute = action.payload;
    },
    setIsEditMode: (state, action: PayloadAction<boolean>) => {
      state.isEditMode = action.payload;
    },
    setReportActionType: (state, action: PayloadAction<ReportActionType>) => {
      state.reportActionType = action.payload;
    },
    setIsPageTypeSelect: (state, action: PayloadAction<boolean>) => {
      state.isPageTypeSelect = action.payload;
    },
    setPageIdSelected: (state, action: PayloadAction<string | null>) => {
      state.pageIdSelected = action.payload;
    },
    setComparisonActionType: (
      state,
      action: PayloadAction<ReportActionType>
    ) => {
      state.comparisonActionType = action.payload;
    },

  },
});

// Export actions and reducer
export const {
  setTheme,
  setIsPublic,
  setIsEditMode,
  setReportActionType,
  setIsPageTypeSelect,
  setPageIdSelected,
  setComparisonActionType,
} = appSlice.actions;
export default appSlice.reducer;
