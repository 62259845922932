import React, { useEffect, useState } from "react";
import {
  AddedOn,
  CheckboxWrapper,
  EmailAddress,
  LastActive,
  More,
  Name,
  Position,
  Projects,
  Role,
  TableContainer,
  TableFilters,
  TableHead,
  TableHeadWrapper,
  Team,
} from "./styled";
import { CheckBox } from "../../../Inputs/CustomCheckbox/CheckBox";
import UsersRow from "../UsersRow";
import AvatarImage from "../../../../assets/profileAvatar.png";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ManagementUserList,
  SortedColumn,
} from "../../../../pages/UserManagment/pages/Users/data/mock";
import { useDispatch, useSelector } from "react-redux";
import { selectAllUsers } from "../../../../store/slices/userManagement";
import { getSelectedUsers } from "../../../../store/selectors/userManagement";
import { ThreeDots } from "react-bootstrap-icons";
import { handleColumnClick } from "../../utils/usersList";
import UsersTableMenu from "../../../UsersTableMenu";
import UserManagementFilter from "../../../UserManagementFilter";
import { applyFilters } from "../../../UserManagementFilter/utils/filters";

export interface ILastAction {
  key?: SortedColumn;
  ascending?: boolean;
  descending?: boolean;
  pined?: boolean;
}

export interface IMenuData {
  top: number;
  left: number;
  key: SortedColumn;
}
export interface IActiveFilters {
  [key: string]: {
    search?: string;
    filter?: string[];
  };
}

type Props = {
  mockDataUsersList: ManagementUserList[];
  teamTable?: boolean;
};

const UsersList = ({ mockDataUsersList, teamTable }: Props) => {
  const dispatch = useDispatch();

  const selectedUsers = useSelector(getSelectedUsers);

  const [maxData, setMaxData] = useState<number>(30);
  const [hasMore, setHasMore] = useState(true);
  const [lastAction, setLastAction] = useState<ILastAction>({});
  const [columnMenu, setColumnMenu] = useState<IMenuData | null>(null);
  const [isOpenFilter, setIsOpenFilter] = useState<SortedColumn | null>(null);
  const [usersList, setUsersList] =
    useState<ManagementUserList[]>(mockDataUsersList);
  const [activeFilters, setActiveFilters] = useState<IActiveFilters>({});

  const maxDataLength = mockDataUsersList.length;
  const end = maxData;

  const fetchData = () => {
    if (maxData + 30 < maxDataLength) {
      setMaxData((prev) => prev + 30);
    } else {
      setMaxData(maxDataLength);
      setHasMore(false);
    }
  };

  const selectAll = () => {
    dispatch(selectAllUsers(usersList.slice(0, end).map((item) => item.uuid)));
  };

  useEffect(() => {
    const newData = applyFilters({
      allUsersList: mockDataUsersList,
      activeFilters,
    });
    setUsersList(newData);
  }, [activeFilters]);

  return (
    <>
      <TableContainer style={{ marginBottom: "20px" }}>
        <TableHeadWrapper>
          <TableHead>
            <CheckboxWrapper>
              <CheckBox
                onChange={() => selectAll()}
                checked={selectedUsers.length === end}
                status={
                  !!selectedUsers.length && selectedUsers.length < end
                    ? "indeterminate"
                    : selectedUsers.length === end
                    ? "checked"
                    : "none"
                }
              />
            </CheckboxWrapper>
            <Name>
              Name
              <ThreeDots
                onClick={(event) =>
                  setColumnMenu(handleColumnClick({ event, key: "name" }))
                }
              />
            </Name>
            <EmailAddress>
              Email address
              <ThreeDots
                onClick={(event) =>
                  setColumnMenu(handleColumnClick({ event, key: "email" }))
                }
              />
            </EmailAddress>
            {teamTable && (
              <Role>
                Role
                <ThreeDots
                  onClick={(event) =>
                    setColumnMenu(handleColumnClick({ event, key: "role" }))
                  }
                />
              </Role>
            )}
            <Position>
              Position
              <ThreeDots
                onClick={(event) =>
                  setColumnMenu(handleColumnClick({ event, key: "position" }))
                }
              />
            </Position>
            {!teamTable && (
              <Team>
                Team
                <ThreeDots
                  onClick={(event) =>
                    setColumnMenu(handleColumnClick({ event, key: "team" }))
                  }
                />
              </Team>
            )}
            <Projects>
              Projects
              <ThreeDots
                onClick={(event) =>
                  setColumnMenu(handleColumnClick({ event, key: "projects" }))
                }
              />
            </Projects>
            <AddedOn>
              Added on
              <ThreeDots
                onClick={(event) =>
                  setColumnMenu(handleColumnClick({ event, key: "addedOn" }))
                }
              />
            </AddedOn>
            <LastActive>
              Last active
              <ThreeDots
                onClick={(event) =>
                  setColumnMenu(handleColumnClick({ event, key: "lastActive" }))
                }
              />
            </LastActive>
            <More></More>
          </TableHead>
          <TableFilters>
            <CheckboxWrapper $noPadding />
            <Name $noPadding>
              <UserManagementFilter
                keyName={"name"}
                filter={false}
                allUsersList={mockDataUsersList}
                setIsOpenFilter={setIsOpenFilter}
                isOpenFilter={isOpenFilter}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
              />
            </Name>
            <EmailAddress $noPadding>
              <UserManagementFilter
                keyName={"email"}
                filter={false}
                allUsersList={mockDataUsersList}
                setIsOpenFilter={setIsOpenFilter}
                isOpenFilter={isOpenFilter}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
              />
            </EmailAddress>
            {teamTable && (
              <Role $noPadding>
                <UserManagementFilter
                  keyName={"role"}
                  allUsersList={mockDataUsersList}
                  setIsOpenFilter={setIsOpenFilter}
                  isOpenFilter={isOpenFilter}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                />
              </Role>
            )}

            <Position $noPadding>
              <UserManagementFilter
                keyName={"position"}
                allUsersList={mockDataUsersList}
                setIsOpenFilter={setIsOpenFilter}
                isOpenFilter={isOpenFilter}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
              />
            </Position>
            {!teamTable && (
              <Team $noPadding>
                <UserManagementFilter
                  keyName={"team"}
                  allUsersList={mockDataUsersList}
                  setIsOpenFilter={setIsOpenFilter}
                  isOpenFilter={isOpenFilter}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                />
              </Team>
            )}
            <Projects $noPadding>
              <UserManagementFilter
                disabled
                keyName={"projects"}
                filter={false}
                allUsersList={mockDataUsersList}
                setIsOpenFilter={setIsOpenFilter}
                isOpenFilter={isOpenFilter}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
              />
            </Projects>
            <AddedOn $noPadding>
              <UserManagementFilter
                keyName={"addedOn"}
                allUsersList={mockDataUsersList}
                setIsOpenFilter={setIsOpenFilter}
                isOpenFilter={isOpenFilter}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
              />
            </AddedOn>
            <LastActive $noPadding>
              <UserManagementFilter
                keyName="lastActive"
                allUsersList={mockDataUsersList}
                setIsOpenFilter={setIsOpenFilter}
                isOpenFilter={isOpenFilter}
                activeFilters={activeFilters}
                setActiveFilters={setActiveFilters}
              />
            </LastActive>
            <More $noPadding />
          </TableFilters>
        </TableHeadWrapper>

        <InfiniteScroll
          dataLength={maxData}
          next={fetchData}
          hasMore={hasMore}
          loader={false && <h4>Loading...</h4>}
          endMessage={null}
        >
          <div
            style={{
              maxHeight: "100%",
              overflowY: "auto",
            }}
          >
            {usersList.slice(0, end).map((item, index: number) => (
              <>
                <UsersRow
                  uuid={item.uuid}
                  name={item.name}
                  email={item.email}
                  position={item.position}
                  role={teamTable ? item.role : undefined}
                  team={!teamTable ? item.team : undefined}
                  projects={item.projects}
                  addedOn={item.addedOn}
                  lastActive={item.lastActive}
                  avatar={AvatarImage}
                />
              </>
            ))}
          </div>
        </InfiniteScroll>
      </TableContainer>
      {columnMenu && (
        <UsersTableMenu
          lastAction={lastAction}
          setLastAction={setLastAction}
          columnMenu={columnMenu}
          setColumnMenu={setColumnMenu}
          usersData={usersList}
          setUsersList={setUsersList}
        />
      )}
    </>
  );
};

export default UsersList;
