import moment from "moment";

export const sortTable = <T>(
  data: T[],
  key: string,
  order: "asc" | "desc",
  getValue?: (item: T) => any
): T[] => {
  return [...data].sort((a: any, b: any) => {
    const aValue = getValue ? getValue(a) : a[key];
    const bValue = getValue ? getValue(b) : b[key];

    if (typeof aValue === "number" && typeof bValue === "number") {
      return order === "asc" ? aValue - bValue : bValue - aValue;
    }

    if (Array.isArray(aValue)) {
      return order === "asc"
        ? (aValue[0] || "").localeCompare(bValue[0] || "")
        : (bValue[0] || "").localeCompare(aValue[0] || "");
    }

    if (typeof aValue === "string" && moment(aValue, moment.ISO_8601, true).isValid()) {
      const aDate = moment(aValue).valueOf();
      const bDate = moment(bValue).valueOf();
      return order === "asc" ? aDate - bDate : bDate - aDate;
    }

    return order === "asc"
      ? String(aValue).localeCompare(String(bValue))
      : String(bValue).localeCompare(String(aValue));
  });
};
