import React from "react";
import { ProjectDetails } from "../../../../../NewProject/ProjectDetails";

type Props = {
  name: string;
  description: string;
  setName: (name: string) => void;
  setDescription: (name: string) => void;
};

const FirstStep = ({ name, description, setName, setDescription }: Props) => {
  return (
    <ProjectDetails
      name={name}
      description={description}
      setName={setName}
      setDescription={setDescription}
    />
  );
};

export default FirstStep;
