import styled from "styled-components";

export const List = styled.div`
  display: grid;
  margin: 0;
  justify-items: center;
  row-gap: 16px;
  column-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(281px, 1fr));

  @media (min-width: 1728px) {
    grid-template-columns: repeat(auto-fill, minmax(281px, 1fr));
  }
`;
