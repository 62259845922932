import React, { useState } from "react";
import * as s from "./styles";
import { useDispatch } from "react-redux";
import {
  requestReadComments,
  setOpenComments,
  toggleCommentsCursor,
} from "../../../../../../store/slices/commnets";
import { Plus, ThreeDots } from "react-bootstrap-icons";
import { useSyncedState } from "../../../../../../hooks/useSyncedState";
import { sortOptions } from "../../DiscussionMain";
import { useSelector } from "react-redux";
import { getCurrentProjectId } from "../../../../../../store/selectors/projects";
import { useClickOutside } from "../../../../../../hooks/useClickOutside";

type OptionsType = { label: string; value: string };

interface Props {
  onClose?: () => void;
  setShowAlert2: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedSort: React.Dispatch<React.SetStateAction<OptionsType>>;
  selectedSort: OptionsType;
  setActiveTab: React.Dispatch<React.SetStateAction<boolean>>;
  activeTab: boolean;
}

export const CommentHead: React.FC<Props> = ({
  onClose,
  setShowAlert2,
  selectedSort,
  setSelectedSort,
  setActiveTab,
  activeTab,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOptionDropdownOpen, setIsOptionDropdownOpen] = useState(false);
  const [isHidden, setIsHidden] = useSyncedState("isHiddenCommentIds", false);
  const [itemRef] = useClickOutside(setIsOptionDropdownOpen, false);
  const [sortRef] = useClickOutside(setIsDropdownOpen, false);


  const projectId = useSelector(getCurrentProjectId);
  const dispatch = useDispatch();

  const handleToggleCursor = () => {
    dispatch(toggleCommentsCursor(true));
    // dispatch(setIsEditMode(false));
    setShowAlert2(false);
    dispatch(setOpenComments());
  };

  const handleHideComments = () => {
    setIsHidden(!isHidden);
  };

  const handleMenu = () => {
    setIsOptionDropdownOpen(!isOptionDropdownOpen);
  };

  const handleAllAsRead = () => {
    setIsOptionDropdownOpen(false);
    dispatch(requestReadComments({ projectId, ids: [], isRead: true }))
  }

  return (
    <s.CommentHeadWrapper>
      <s.HeaderContent>
        <s.Title>Comments</s.Title>
        <s.ActionSection ref={itemRef}>
          <s.OptionButton
            $active={isOptionDropdownOpen}
            onClick={handleMenu}
          >
            <ThreeDots />
          </s.OptionButton>
          {isOptionDropdownOpen && (
            <s.OptionDropdown>
              <s.DropdownItem
                key="1"
                onClick={handleAllAsRead}
              >
                Mark all as read
              </s.DropdownItem>
              <s.DropdownItem key="2" onClick={handleHideComments}>
                {isHidden ? "Show comments" : "Hide comments"}
              </s.DropdownItem>
            </s.OptionDropdown>
          )}
          <s.AddButton onClick={handleToggleCursor}>
            <Plus />
          </s.AddButton>
        </s.ActionSection>
      </s.HeaderContent>
      <s.HeaderContent>
        <s.TabSection>
          <s.TabList>
            <s.Tab
              $active={activeTab === false}
              onClick={() => setActiveTab(false)}
            >
              Open
            </s.Tab>
            <s.Tab
              $active={activeTab === true}
              onClick={() => setActiveTab(true)}
            >
              Resolved
            </s.Tab>
          </s.TabList>

          <s.SortSection ref={sortRef}>
            <s.SortButton onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              <s.SortLabel>Sort by</s.SortLabel>
              <s.SortValue>{selectedSort.label}</s.SortValue>
              <s.SortIcon $isOpen={isDropdownOpen} />
            </s.SortButton>

            {isDropdownOpen && (
              <s.SortDropdown>
                <p>SORT BY</p>
                {sortOptions.map((option) => (
                  <s.DropdownItem
                    key={option.value}
                    onClick={() => {
                      setSelectedSort(option);
                      setIsDropdownOpen(false);
                    }}
                  >
                    {option.label}
                    {selectedSort.value === option.value && <s.DotIcon />}
                  </s.DropdownItem>
                ))}
              </s.SortDropdown>
            )}
          </s.SortSection>
        </s.TabSection>
      </s.HeaderContent>
    </s.CommentHeadWrapper>
  );
};
