import { useEffect, useState } from "react";
import { CheckCircle } from "react-bootstrap-icons";

import {
  Actions,
  CloseIcon,
  CloseIconWrap,
  ModalContainer,
  ModalContent,
  ModalFooterWrapper,
  ModalHeading,
  ModalOverlay,
  ModalSubHeading,
  ModalWrapper,
  OverviewContent,
} from "../styles";
import { Button } from "../../Button";

import { FormContent } from "./Form";
import { useDispatch, useSelector } from "react-redux";
import { ModalHeadingWrapper } from "./style";
import { getPopulations } from "./utils";
import { setSelectedMeasure } from "../../../store/slices/comparison";
import { setDraftPageSettings } from "../../../store/slices/projectPages";
import { setActiveModal } from "../../../store/slices/modals";
import { setComparisonActionType, setReportActionType } from "../../../store/slices/appConfig";
import {
  getCurrentProject,
  getDraftPageSettings,
} from "../../../store/selectors/projects";
import { getPageIdSelected } from "../../../store/selectors/main";
import { getAllDatasets } from "../../../store/selectors/datasets";
import { requestGetAllDatasets } from "../../../store/slices/datasets";

type ModalProps = {
  onClose: () => void;
};

export const CreateComparison = ({ onClose }: ModalProps) => {
  const dispatch = useDispatch();

  const pageDraftSettings = useSelector(getDraftPageSettings);
  const selectedPageId = useSelector(getPageIdSelected);
  const projectData = useSelector(getCurrentProject);
  const allDatasets = useSelector(getAllDatasets);

  const [population, setPopulation] = useState<string | null>(null);
  const [measure, setMeasure] = useState<string | null>(null);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  const handleOnClose = () => {
    setIsClosing(true);
    onClose();
    setSelectedMeasure(null);
  };

  const handleSubmit = async () => {
    onClose();
    dispatch(
      setDraftPageSettings({
        ...pageDraftSettings,
        soursePageId: selectedPageId!,
        comparisonMeasure: `${measure}=${population}`!,
        dashType: "comparison",
      })
    );
    dispatch(
      setActiveModal({
        id: "projectSettingsModal",
        createOptions: true,
      })
    );
    dispatch(setReportActionType("unset"));
    dispatch(setComparisonActionType("unset"));
    return;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!process.env.REACT_APP_DATA_SET_URL) {
          return;
        }
        if (!allDatasets.items?.length) {
          return;
        }
        const response = await fetch(process.env.REACT_APP_DATA_SET_URL);
        if (!response.ok) {
          return;
        }
        const data = await response.json();

        const datasetName = projectData.projectData.datasets.at(0)?.name;
        const datasetId = (allDatasets.items || []).find(
          (item) => item.name === datasetName
        )?.id;

        const measures = data && datasetId ? data[datasetId] : [];
        const bucket = getPopulations(measures).at(0)?.bucket ?? "";

        setPopulation(bucket);
      } finally {
      }
    };

    fetchData();
  }, [allDatasets.items, projectData.projectData.datasets]);

  return (
    <ModalOverlay
      $centred={true}
      $isClosing={isClosing}
      onClick={handleOnClose}
    >
      <ModalWrapper
        $isClosing={isClosing}
        onClick={(e) => e.stopPropagation()}
        $centred={true}
      >
        <ModalHeadingWrapper>
          <ModalContainer>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <ModalHeading>Select Additional Measure</ModalHeading>
              <ModalSubHeading>
                Select one additional measure you want to compare
                <br />
                with the main measure
              </ModalSubHeading>
            </div>
            <CloseIconWrap>
              <CloseIcon onClick={handleOnClose}></CloseIcon>
            </CloseIconWrap>
          </ModalContainer>
        </ModalHeadingWrapper>
        <OverviewContent>
          <ModalContent>
            <FormContent
              selectedOption={measure}
              setSelectedOption={setMeasure}
              selectedChip={population}
              setSelectedChip={setPopulation}
            />
            <ModalFooterWrapper>
              <Button
                name="Cancel"
                onClick={handleOnClose}
                variant="neutral"
                size="medium"
              />
              <Actions>
                <Button
                  name="Confirm"
                  onClick={handleSubmit}
                  variant="secondary"
                  size="medium"
                  icon={<CheckCircle />}
                  disabled={!measure?.length}
                />
              </Actions>
            </ModalFooterWrapper>
          </ModalContent>
        </OverviewContent>
      </ModalWrapper>
    </ModalOverlay>
  );
};
