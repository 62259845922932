import React, { ChangeEvent, useEffect, useState } from "react";

import { useFormikContext } from "formik";

import { useDebounce } from "../../../hooks/useDebounce";

import {
  CancelInput,
  ErrorMsg,
  FieldLabel,
  FieldWrapper,
  HidePassword,
  Input,
  ShowPassword,
  WrapperIcons,
} from "../styles";
import { ExclamationCircleFill } from "react-bootstrap-icons";

type FormGroupProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: React.ChangeEventHandler;
  placeholder: string;
  type: string;
  name: string;
  values: string | number;
  errors: string | undefined;
  touched: boolean | undefined;
  icon?: boolean;
  id: string;
  width?: string;
  setValues?: () => void;
};

export const FormGroup: React.FC<FormGroupProps> = ({
  handleChange,
  handleBlur,
  placeholder,
  type,
  name,
  values,
  errors,
  touched,
  id,
  width,
  setValues = () => {},
  icon = false,
}) => {
  const debouncedValue = useDebounce(values, 0);
  const { setFieldTouched, validateField } = useFormikContext();

  const [hasValue, setHasValue] = useState(!!values);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleShowPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setHasValue(!!e.target.value);

    validateField(e.target.name);
    setFieldTouched(e.target.name, false);
  };

  const handleResetValue = () => {
    setValues();
    setHasValue(false);
  };

  useEffect(() => {
    if (debouncedValue) {
      validateField(name);
    }
  }, [debouncedValue, name, validateField]);

  return (
    <FieldWrapper $width={width}>
      <FieldLabel $show={hasValue}>{placeholder}</FieldLabel>
      <Input
        $error={!!(errors && touched)}
        $hasValue={hasValue}
        id={id}
        type={type === "password" && passwordVisible ? "text" : type}
        name={name}
        onChange={handleInputChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        value={values}
      />

      {icon && (
        <WrapperIcons>
          {icon && values && <CancelInput onClick={handleResetValue} />}
          {type === "password" &&
            (passwordVisible ? (
              <HidePassword onClick={handleShowPassword} />
            ) : (
              <ShowPassword onClick={handleShowPassword} />
            ))}
        </WrapperIcons>
      )}
      {errors && touched && <ErrorMsg><ExclamationCircleFill></ExclamationCircleFill><span>{errors}</span></ErrorMsg>}
    </FieldWrapper>
  );
};
