import React from "react";

import { Button } from "../Button";
import { Description, DetailsWrapper, EmptyPageWrapper, Title } from "./styles";

interface Props {
  title: string,
  subTitle: string,
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  button: {
    name: string,
    onClick: () => void;
    icon?: React.ReactNode
  }
};

export const EmptyPage = ({ title, subTitle, Icon, button }: Props) => {
  return (
    <EmptyPageWrapper>
      <Icon />
      <DetailsWrapper>
        <Title>
          {title}{" "}
        </Title>
        <Description>
          {subTitle}
        </Description>
      </DetailsWrapper>
      <Button
        {...button}
        variant="secondary"
        size="medium"
      />
    </EmptyPageWrapper>
  );
};
