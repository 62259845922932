import React, { useEffect } from "react";

import { Heading } from "../../styles";
import { HeadingMenuContainer } from "../../components/NavBar/styles";
import { PageMenu } from "../../components/PageMenu";
import { DatasetsList } from "../../components/DatasetsList";
import { useDispatch, useSelector } from "react-redux";
import { EmptyDatasetsPage } from "../../components/EmptyDatasets";
import { requestGetAllDatasets } from "../../store/slices/datasets";
import { Loader } from "../../components/Loader";
import { getAllDatasets } from "../../store/selectors/datasets";
import { Arrow, ButtonsWrapper, LastCreated } from "./style";
import { useSortByCreatedDate } from "../../hooks/useSortByDate";

export const Datasets = () => {
  const allDatasets = useSelector(getAllDatasets);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  const { handleSortByCreatedDate, sortItemsByCreatedDate } =
    useSortByCreatedDate("desc");
  const sortedItems = sortItemsByCreatedDate(
    allDatasets.items ? allDatasets.items : []
  );

  return (
    <>
      <HeadingMenuContainer>
        <Heading>Programs Library</Heading>
        <ButtonsWrapper>
          <LastCreated onClick={handleSortByCreatedDate}>
            Last created
            <Arrow />
          </LastCreated>
          <PageMenu />
        </ButtonsWrapper>
      </HeadingMenuContainer>
      {allDatasets.isLoading ? (
        <Loader />
      ) : !allDatasets.items.length ? (
        <EmptyDatasetsPage />
      ) : (
        <DatasetsList items={sortedItems} />
      )}
    </>
  );
};
