import React, { useEffect } from "react";
import { ProjectsListHeading, ProjectsListWrapper } from "./styles";
import { ProjectsTable } from "../ProjectsTable";
import { requestAllProjects } from "../../store/slices/projects";
import { useDispatch } from "react-redux";

export const UserProfileProjects = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestAllProjects({ limit: 10, skip: 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ProjectsListWrapper>
      <ProjectsListHeading>Projects</ProjectsListHeading>
      <ProjectsTable />
    </ProjectsListWrapper>
  );
};
